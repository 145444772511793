import React from 'react';
import Manufacturers from "./Manufacturers";
import {connect} from "react-redux";
import './Manufacturers.css';
import {deleteCategoryThunk, deleteManufacturerThunk, getManufacturersThunk} from "../../store/reducers/MainReducer";
import {withLang} from "../HOC/withLang/withLang";
import {compose} from "redux";



class ManufacturersContainer extends React.Component {

    constructor(props){
        super(props);

    }


    componentDidMount() {
        this.props.getManufacturersThunk();

    }

    render() {
        return <Manufacturers manufacturers={this.props.manufacturers}
                              getTranslate={this.props.getTranslate}
                              deleteManufacturer={this.props.deleteManufacturer}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getManufacturersThunk: () => dispatch(getManufacturersThunk()),
        deleteManufacturer: (id) => dispatch(deleteManufacturerThunk(id))
    }
}

let mapStateToProps = (state) => {
    return {
        manufacturers: state.main.manufacturers
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(ManufacturersContainer);

// export default connect(mapStateToProps, mapDispatchToProps)(ProductsContainer);