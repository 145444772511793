import React from "react";
import ApiService from "../../api/api";
import {
    changeLoaderStatusACS, getAllOrdersFilterThunk,
    getAllOrdersThunk,
    getDirectoryDataThunk,
    getOrderThunk, setChangeStatusProductsFalseDataAC,
    setOrdersActiveTabAC
} from "../../store/reducers/MainReducer";
import {compose} from "redux";
import {connect} from "react-redux";
import {withLang} from "../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";

class OrderChangeStatusContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            check: true,
            products_false_count: [],
            comment: '',
            ttn: '',
        }
    }

    componentDidMount() {
        this.props.directory();
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextState.isOpen !== this.state.isOpen && Number(this.props.status_id) === 1) {
            this.apiService.checkOrderProductsCount(nextProps.order)
                .then(res => {
                    this.setState({
                        check: res.status
                    });
                    this.props.setChangeStatusProductsFalseData(res.products)
                });
        }
    }

    apiService = new ApiService();

    handleClickOutside = evt => {
        this.setState({isOpen: false});
    };

    render() {
        let status_data = this.props.statuses && this.props.statuses.filter(e => Number(this.props.status_id) === Number(e.id))[0];
        // let status_variants = this.props.statuses && this.props.statuses.filter(e => (Number(e.id) === Number(status_data.id) + 1 || Number(e.id) === Number(status_data.id) - 1 || ([7,8,9].includes(Number(e.id)) && Number(status_data.id) !== 1)));
        let status_variants = [];
        const { order_data } = this.props;
        if(status_data) {
            switch(Number(status_data.id)) {
                    // ід - 1 Новий
                case 1:
                    status_variants = this.props.statuses.filter(e => (Number(e.id) === Number(status_data.id) + 1 || [8].includes(Number(e.id) )));
                    break;
                    // ід - 2 Очікує оплати
                case 2:
                    if(order_data && (Number(order_data.type) === 3 || Number(order_data.type) === 4)) {
                        status_variants = this.props.statuses.filter(e => (Number(e.id) === Number(status_data.id) + 2 || [8].includes(Number(e.id) )));
                    } else {
                        status_variants = this.props.statuses.filter(e => (Number(e.id) === Number(status_data.id) + 1 || [8].includes(Number(e.id) )));
                    }
                    break;
                    // ід - 3 Оплачений
                case 3:
                    status_variants = this.props.statuses.filter(e => (Number(e.id) === Number(status_data.id) + 1 || [7].includes(Number(e.id) )));
                    break;
                    // ід - 4 Комплектується
                case 4:
                    if(order_data && (Number(order_data.type) === 3 || Number(order_data.type) === 4)) {
                        status_variants = this.props.statuses.filter(e => (Number(e.id) === Number(status_data.id) + 1 || [7,8].includes(Number(e.id) )));
                    } else {
                        status_variants = this.props.statuses.filter(e => (Number(e.id) === Number(status_data.id) + 1 || Number(e.id) === Number(status_data.id) - 1 || [7,8].includes(Number(e.id) )));
                    }

                    break;
                    // ід - 5 В дорозі
                case 5:
                    status_variants = this.props.statuses.filter(e => (Number(e.id) === Number(status_data.id) + 1 || [9].includes(Number(e.id) )));
                    break;
                    // ід - 6 завершений
                case 6:
                    status_variants = this.props.statuses.filter(e => [10].includes(Number(e.id) ));
                    break;
                    //ід -7 відкладений
                case 7:
                    status_variants = this.props.statuses.filter(e => [3,4].includes(Number(e.id) ));
                    break;
                    //ід - 9 повернення
                case 9:
                    status_variants = this.props.statuses.filter(e => [11].includes(Number(e.id) ));
                    break;
                default:
                    status_variants = []
            }
        }


        return <>
            <div className="order_change_status_container">
                <div className="order_change_status_active" onClick={() => this.setState({
                    isOpen: !this.state.isOpen
                })}>
                    <b>{status_data && status_data.value}</b>
                </div>
                {this.state.isOpen && <div className="order_change_status_variants">
                    <div className="change_status_inner_container">
                        <div className="form-group">
                                        <textarea className={"form-control"} cols="20"
                                                  rows="5"
                                                  placeholder={'Коментар'}
                                                  value={this.state.comment}
                                                  onChange={(event) => this.setState({comment: event.target.value})}
                                        >

                                        </textarea>
                        </div>
                        {status_data && Number(status_data.id) === 4 ?
                            <div className="form-group">
                                <input
                                    className={"form-control"}
                                    placeholder={'ТТН'}
                                    onChange={(event) => this.setState({ttn: event.target.value})}
                                />
                            </div>
                            : ""
                        }
                    </div>
                    {status_variants && status_variants
                        .filter(e => Number(e.id) !== Number(status_data.id))
                        .map(e => {
                            return <>
                                <div className="order_change_status_variant_container">
                                    <button className={'btn ' + ([7,8,9,10].includes(Number(e.id)) ? 'btn-danger' : 'btn-default' )}
                                            onClick={() => {
                                                this.props.setLoader(true);
                                                this.setState({
                                                    isOpen: false
                                                });
                                                this.apiService.updateOrder(this.props.order, {
                                                    status: Number(e.id),
                                                    comment: this.state.comment,
                                                    ttn: this.state.ttn,
                                                }).then( res => {
                                                    this.props.setLoader(false);
                                                    this.props.getOrderThunk(this.props.order);
                                                    this.props.getAllOrdersThunk();
                                                    this.setState({
                                                        comment: '',
                                                        ttn: '',
                                                    });
                                                })
                                            }}
                                            disabled={Number(e.id) === 2 && !this.state.check ? 'disabled' : ''}
                                    >{e.value}</button>
                                    {Number(e.id) === 2 && !this.state.check && <small className={'text-danger'}> Товари в недостатній кількості </small>}
                                </div>
                            </>
                        })}
                </div>}
            </div>
        </>
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getAllOrdersThunk: () => dispatch(getAllOrdersFilterThunk()),
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
        getOrderThunk: (id) => dispatch(getOrderThunk(id)),
        setLoader: (status) => dispatch(changeLoaderStatusACS(status)),
        setChangeStatusProductsFalseData: (products) => dispatch(setChangeStatusProductsFalseDataAC(products))
    }
}

let mapStateToProps = (state) => {
    return {
        orders: state.main.orders,
        statuses: state.main.directoryData.status
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(OrderChangeStatusContainer);