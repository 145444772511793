import React from "react";
import './Modal.css';
const Modal = ({isOpen, close, body, title}) => {
    return isOpen && <div className="modal fade show">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                    <button type="button" className="close" onClick={() => close()}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {body}
                </div>
            </div>
        </div>
    </div>
};

export default Modal;