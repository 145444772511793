import React from 'react';
import Warehouses from "./Warehouses";
import {connect} from "react-redux";
import './Warehouses.css';
import ApiService from "../../api/api";
import {getWarehousesThunk} from "../../store/reducers/MainReducer";
import {withLang} from "../HOC/withLang/withLang";
import {compose} from "redux";
import {checkAuthDataThunk} from "../../store/reducers/AuthReducer";



class WarehousesContainer extends React.Component {

    apiService = new ApiService();

    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.props.getWarehousesThunk()
        this.props.getAuth();
    }

    render() {
        return <Warehouses
            warehouses={this.props.warehouses}
            getTranslate={this.props.getTranslate}
            user={this.props.user}
            getAuth={this.props.getAuth}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getWarehousesThunk: () => dispatch(getWarehousesThunk()),
        getAuth: () => dispatch(checkAuthDataThunk()),
    }
}

let mapStateToProps = (state) => {
    return {
        warehouses: state.main.warehouses,
        user: state.auth.user
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(WarehousesContainer);