import React from 'react';
import PageTitle from "../../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import WarehouseHistory from "./WarehouseHistory";
import WarehouseHistoryContainer from "./WarehouseHistoryContainer";

const WarehouseHistoryPage = (props) => {

    return(
        <div className="content-wrapper">
            <PageTitle title={'warehouse_history'}/>
            <section className="content">
                <div className="container-fluid">
                    <WarehouseHistoryContainer/>
                </div>
            </section>
        </div>

    );
}

export default WarehouseHistoryPage;