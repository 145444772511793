import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const BarCustomChart = ({ name, value, data }) => {
    return (
        <ResponsiveContainer width="100%" height="100%" style={{
            minHeight: '450px'
        }}>
            <BarChart
                width={'100%'}
                height={450}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={name} />
                <YAxis/>
                <Tooltip />
                <Legend />
                <Bar dataKey={value} fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    );
}
