import React from 'react';
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import {compose} from "redux";
import {withPagination} from "../HOC/withPagination/withPaginationSQL";
import {CustomerFilters} from "./CustomerFilters";

const Customers = (props) => {

    return(
        <div className="content-wrapper">
            <PageTitle title={'customers'}/>
            <CustomerFilters/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <table className="table table-striped">
                            <thead>
                            <th>{props.getTranslate('crm_main','ID')}</th>
                            <th>{props.getTranslate('crm_main','name_person')}</th>
                            <th>{props.getTranslate('crm_main','email')}</th>
                            <th>{props.getTranslate('crm_main','phone')}</th>
                            <th>{props.getTranslate('crm_main','partner')}</th>
                            <th>{props.getTranslate('crm_main','date')}</th>
                            <th>{props.getTranslate('crm_main','action')}</th>
                            </thead>
                            <tbody>
                            {props.customers && props.customers.map(e => {
                                return <>
                                    <tr>
                                        <td>{e.id}</td>
                                        <td>{e.surname} {e.name} {e.middle_name}</td>
                                        <td>{e.email}</td>
                                        <td>{e.phone}</td>
                                        <td>{e.partner && e.partner == 1 ?
                                            <i className="fas fa-check text-green"></i> : " "}</td>
                                        <td>{e.date}</td>
                                        <td className={"action_button_td"}>
                                            <Link className={"btn btn-info"} to={'/customers/' + e.id}>
                                                <i className="far fa-eye"></i>
                                            </Link>
                                        </td>
                                    </tr>
                                </>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {props.paginationHTML}
            </section>
        </div>

    );
}
export default compose(
    withPagination
)(Customers);