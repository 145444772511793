import React, {useEffect, useState} from 'react';
import './WarehouseHistory.css';
import ApiService from "../../../api/api";
import {compose} from "redux";
import {withPagination} from "../../HOC/withPagination/withPaginationSQL";

const api = new ApiService();


const WarehouseHistory = (props) => {
    const { getExcelHandler, getData, changeValue, form, params, data, changeProductsPaginationsFormValueData } = props;
    const fetchData = (value) => {
        changeProductsPaginationsFormValueData()
        getData(value);
    };
    const getExcel = () => getExcelHandler(params, form);
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(props.params)]);

    const getTypeName = (type) => {
        return props.type_name && props.type_name.filter(e => (Number(type)) === Number(e.id) )[0] && props.type_name.filter(e => (Number(type)) === Number(e.id) )[0].value
    }

    return (
        <div>
            {!props.withoutFilters ?
                <div className="card card-primary card-outline card-tabs">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <div className="input-group mb-3 form_block_input">
                                    <label>
                                        Товар:
                                    </label>
                                    <input className={'form-control'}
                                           value={form.product_name}
                                           type={'text'}
                                           onChange={(event) => changeValue('product_name', event.target.value)}
                                           required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div className="input-group mb-3 form_block_input">
                                    <label>
                                        Дата (від):
                                    </label>
                                    <input className={'form-control'}
                                           value={form.date_from}
                                           type={'date'}
                                           onChange={(event) => changeValue('date_from', event.target.value)}
                                           required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div className="input-group mb-3 form_block_input">
                                    <label>
                                        Дата (до):
                                    </label>
                                    <input className={'form-control'}
                                           value={form.date_to}
                                           type={'date'}
                                           onChange={(event) => changeValue('date_to', event.target.value)}
                                           required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className="col-md-12">
                            <button className="btn btn-success margin-right_10" onClick={() => fetchData(1)}>Фільтрувати</button>
                            <button className="btn btn-success" onClick={getExcel}>Excel</button>
                        </div>
                    </div>
                </div>
            </div>
            : ""}
            {data && data.length > 0 ? <>
            <div className="card">
                <table className="table">
                    <thead>
                    <tr>
                        {props.withWarehouse && <th>Склад</th>}
                        <th>Товар</th>
                        <th>Кількість</th>
                        <th> </th>
                        <th>Дата</th>
                        <th>Зв'язки</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map(e => {
                        return <tr>
                            {props.withWarehouse && <td>{e.warehouse.name}</td>}
                            <td>{e.product.name} ({e.product.model})</td>
                            <td>{e.count}</td>
                            <td>{Number(e.action) ? <i className="fas fa-long-arrow-alt-right text-success"></i> : <i className="fas fa-long-arrow-alt-left text-danger"></i>}</td>
                            <td>{e.date}</td>
                            <td>
                                {e.history && e.history.data[0] && <p>{getTypeName(e.history.data[0].type)} № {e.history.data[0].id} від {e.history.data[0].date}</p>}
                                {e.order && <p>Замовлення <a href={"/orders/"+e.order.id} target={"_blank"}>№{e.order.id}</a> від {e.order.date}</p>}
                                {e.comment_tech}
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
                {!props.withoutPagination ?
                    <div className="card container_for_pagination">
                        {props.paginationHTML}
                    </div>
                : ""}
            </>
                : ""}
        </div>
    );
}

export default compose(
    withPagination
)(WarehouseHistory);