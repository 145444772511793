import React from 'react';
import Orders from "./Orders";
import {connect} from "react-redux";
import './Orders.css';
import {
    changeProductsPaginationsFormValueDataAC,
    getAllOrdersFilterThunk,
    getAllOrdersThunk, getCounterStatusOrdersThunk,
    getDirectoryDataThunk,
    setOrdersActiveTabAC,
    setOrdersActiveTabPartnerSaleAC
} from "../../store/reducers/MainReducer";
import ApiService from "../../api/api";
import {compose} from "redux";
import {withLang} from "../HOC/withLang/withLang";
import OrdersNew from "./OrdersNew";

class OrdersContainer extends React.Component {

    apiService = new ApiService();

    constructor(props){
        super(props);
        this.deleteOrder = this.deleteOrder.bind(this)
    }

    deleteOrder(id)  {
        this.apiService.deleteOrder(id)
            .then(res => {
                this.props.getAllOrdersThunk()
            })
    }

    componentDidMount() {
        this.props.directory();
        this.props.changeProductsPaginationsFormValueData()
        this.props.getAllOrdersFilter();
        this.props.getCounterStatusOrdersThunk(1);
    }

    getOrdersRole (orders, user) {
        if(user && Number(user.role_id) === 3){
            return orders.filter(e => Number(e.status) === 4 || Number(e.status) === 5)
        } else if (user && Number(user.role_id) === 4) {
            return orders.filter(e => Number(e.manager_id) === Number(user.id))
        } else {
            return orders
        }

    };

    render() {
        return <OrdersNew
            orders= {this.getOrdersRole(this.props.orders, this.props.user)}
            deleteOrder={this.deleteOrder}
            activeTab={this.props.activeTab}
            activeTabPartnerSale={this.props.activeTabPartnerSale}
            setActiveTab={this.props.setActiveTab}
            setActiveTabPartnerSale={this.props.setActiveTabPartnerSale}
            getTranslate={this.props.getTranslate}
            statuses={this.props.statuses}
            counter_status_data = {this.props.counter_status_data}
            user = {this.props.user}
            paginationHandler={this.props.getAllOrdersFilter}
            paginationName={'orders'}
            paginationDataCount={this.props.ordersCount}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getAllOrdersThunk: () => dispatch(getAllOrdersThunk()),
        setActiveTab: (tab) => dispatch(setOrdersActiveTabAC(tab)),
        setActiveTabPartnerSale: (tab) => dispatch(setOrdersActiveTabPartnerSaleAC(tab)),
        getAllOrdersFilter: () => dispatch(getAllOrdersFilterThunk()),
        getCounterStatusOrdersThunk: (status) => dispatch(getCounterStatusOrdersThunk(status)),
        changeProductsPaginationsFormValueData: () => dispatch(changeProductsPaginationsFormValueDataAC('orders', 'page', 1)),
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
    }
}

let mapStateToProps = (state) => {
    return {
        orders: state.main.orders,
        activeTab: state.main.ordersTab,
        activeTabPartnerSale: state.main.ordersTabPartnerSale,
        ordersCount: state.main.ordersCount,
        statuses: state.main.directoryData.status,
        counter_status_data: state.main.counter_status_data,
        user: state.auth.user
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(OrdersContainer);
