import React from 'react';
import {connect} from "react-redux";
import {withLang} from "../HOC/withLang/withLang";
import {compose} from "redux";
import Auth from "./Auth";
import {changeAuthFormValueAC, getAuthDataThunk} from "../../store/reducers/AuthReducer";



class AuthContainer extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return <Auth сhangeValue={this.props.changeValue}
                     form={this.props.form}
                     getAuth={this.props.getAuth}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeAuthFormValueAC(input, value)),
        getAuth: (login, pass) => dispatch(getAuthDataThunk(login, pass)),
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.auth.authForm
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(Auth);