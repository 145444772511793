import React from 'react';
import WarehouseReserved from "./WarehouseReserved";
import {connect} from "react-redux";
import {getWarehouseReservedThunk} from "../../../store/reducers/MainReducer";
import {withLang} from "../../HOC/withLang/withLang";
import {compose} from "redux";
import ApiService from "../../../api/api";




class WarehouseReservedContainer extends React.Component {

    apiService = new ApiService();

    constructor(props){
        super(props);
        this.getWarehouseReservedSearch = this.getWarehouseReservedSearch.bind(this);
        this.state = {
            search: null
        }
    }

    componentDidMount() {
        this.props.getWarehouseReservedThunk()
    }

    getWarehouseReservedSearch() {
        const new_warehouses_reserved = this.props && this.props.warehouses_reserved && this.props.warehouses_reserved ?
            (this.state.search ?
                   this.props.warehouses_reserved.filter(e => e.product_name.toLowerCase().includes(this.state.search.toLowerCase())
                || e.product_model.toLowerCase().includes(this.state.search.toLowerCase()))
            : this.props.warehouses_reserved) : [];
        debugger
        return new_warehouses_reserved;
    }

    render() {
        return <WarehouseReserved
            // warehouses_reserved={this.props.warehouses_reserved}
            warehouses_reserved={this.getWarehouseReservedSearch()}
            getTranslate={this.props.getTranslate}
            searchInput={this.state.search}
            searchInputChange={(value) => this.setState({search: value})}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getWarehouseReservedThunk: () => dispatch(getWarehouseReservedThunk()),
    }
}

let mapStateToProps = (state) => {
    return {
        warehouses_reserved: state.main.warehouses_reserved,
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(WarehouseReservedContainer);