import React from "react";
import {Select, Form} from "antd";
import {Option} from "antd/es/mentions";

export const SelectType = ({type}) => {

    const handlerSelect = (value) => {
        window.location.href = '/analytics/' + value;
    };

    return <div className="row">
        <div className="col-md-12" style={{
            marginBottom: '25px'
        }}>
            <Form.Item label={'Оберіть тип звіту'}>
                <Select defaultValue={''}
                        value={type}
                        style={{ width: 300 }}
                        onChange={(value) => handlerSelect(value)}

                >
                    <Option value="customers">Клієнти</Option>
                    <Option value="sales">Продажі</Option>
                    <Option value="top_products">ТОП-продуктів</Option>
                    <Option value="movement_goods">Рух товарів</Option>
                </Select>
            </Form.Item>

        </div>
    </div>
}