import React from 'react';

export const FilterItemSelect = ({ title, value, index, onChangeHandler, options }) => {
    return <>
        <div className="col-md-4">
            <div className="form-group">
                <div className="input-group mb-3 form_block_input">
                    <label>
                        {title}
                    </label>
                    <select className={'form-control'}
                            value={value}
                            onChange={(event) => onChangeHandler(index, event.target.value)}
                    >
                        <option key={0} value={''}> </option>
                        {options && options.map(e => <option key={e.id} value={e.id}>{e.label}</option>)}
                    </select>
                </div>
            </div>
        </div>
    </>;
}