import React from 'react';
import '../../plugins/flag-icon-css/css/flag-icon.min.css'
import './Header.css';
import {useDispatch} from "react-redux";
import {logoutAC} from "../../store/reducers/AuthReducer";
const Header = (props) => {

    const dispatch = useDispatch();
    const logout = () => dispatch(logoutAC());

    return(
        <div className="wrapper">
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button" onClick={() => props.toggleSidebar(!props.sidebarCollapse)}>
                            <i className="fas fa-bars color_company_one"></i>
                        </a>
                    </li>
                </ul>
                <button className={"btn btn-info"} onClick={() => {
                    localStorage.removeItem('medbuy_crm_translates_array_data');
                    localStorage.removeItem('medbuy_directory_data');
                    window.location.href = window.location.href;
                }}>Очистити кеш</button>
                <button className={"button_exit"} onClick={() => logout()}><i className="fas fa-sign-out-alt"></i></button>
            </nav>
        </div>
    )
}

export default Header;