import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    changeOrderEditDataValueAC, getAllCustomersThunk, getAllProductsThunk,
    getDirectoryDataThunk,
    getOrderEditDataThunk, getSellManagersThunk, sendEditOrderDataThunk
} from "../../../store/reducers/MainReducer";
import PageTitle from "../../PageTitle/PageTitle";
import InputMask from 'react-input-mask';
import Select from "react-select";
import './OrderEdit.css';
import ApiService from "../../../api/api";
import AsyncSelect from 'react-select/async';

const OrderEdit = ({uri}) => {
    const dispatch = useDispatch();
    const order_id = uri.match.params.id;
    const form = useSelector((state) => state.main.orderEditData);
    const database = useSelector((state) => state.main.orderEditDataDatabase);
    const regions = useSelector((state) => state.main.directoryData.regions);
    const cities = useSelector((state) => state.main.directoryData.cities);
    const warehouses = useSelector((state) => state.main.directoryData.np_warehouse);
    const promocodes = useSelector((state) => state.main.sell_managers);
    const customers = useSelector((state) => state.main.customers);
    const products = useSelector((state) => state.main.products);
    const [searchProducts, setSearchProducts] = useState([]);
    const [searchProductsData, setSearchProductsData] = useState([]);
    const [searchInput, changeSearchInput] = useState({
        label: '',
        value: ''
    });
    const api = new ApiService();



    const changeValue = (input, value) => dispatch(changeOrderEditDataValueAC(input, value));

    const getRegionName = (id) => {
        return id &&
               regions &&
               regions.filter(e => Number(e.id) === Number(id))[0] &&
               regions.filter(e => Number(e.id) === Number(id))[0].name
    };

    const getCityName = (id) => {
        return id &&
            cities &&
            cities.filter(e => Number(e.id) === Number(id))[0] &&
            cities.filter(e => Number(e.id) === Number(id))[0].name
    };

    const getWarehouseName = (id) => {
        return id &&
            warehouses &&
            warehouses.filter(e => Number(e.id) === Number(id))[0] &&
            warehouses.filter(e => Number(e.id) === Number(id))[0].name
    };
    const getPromocode = (promocode) => {
        return promocode &&
            promocodes &&
            promocodes.filter(e => e.promo_code === promocode)[0] ? promocodes.filter(e => e.promo_code === promocode)[0] : {}
    };
    const getCustomer = (customer_id) => {
        if(customer_id === '') {
            return {
                name: 'Гість',
                surname: '',
                id: ''
            }
        }
        return customer_id &&
        customers &&
        customers.filter(e => Number(e.id) === Number(customer_id))[0] ? customers.filter(e => Number(e.id) === Number(customer_id))[0] : {}
    };

    const changeProductsData = (id, input, value) => {
        const products = [...form.products];
        const product = products.filter(e => Number(e.id) === Number(id))[0];
        if(!product) return false;
        product[input] = value;
        changeValue('products', [
            ...products.filter(e => Number(e.id) !== Number(id)),
            {...product}
        ]);
    }

    const loadOptions = (inputValue, callback) => {
        api.getProductsSearch(inputValue)
            .then(res => {
                setSearchProductsData(res.data);
                callback(res.data.filter(e => {
                    if(Number(form.type) === 1 || Number(form.type) === 3) {
                        return Number(e.count) > 0
                    }
                    if(Number(form.type) === 2 || Number(form.type) === 4) {
                        return Number(e.count) === 0
                    }
                }).map(e => {
                    return {
                        label: e.name,
                        value: e.id
                    }
                }));
            })
    };

    const checkPartnerStatus = (id) => {
        if(!id) return false;
        const customer = customers && customers.filter(e => Number(e.id) === Number(id))[0];
        if(!customer) return false;
        return Number(customer.partner);
    };

    const checkAddedProducts = (id) => {
        const products = [...form.products];
        const product = products.filter(e => Number(e.id) === Number(id));
        return Number(product.length) === 0;
    }

    const addNewOrderProduct = () => {
        if(!searchProductsData || !searchProducts) return false;
        const product = searchProductsData.filter( e => Number(e.id) === Number(searchProducts.value))[0];
        const products = [...form.products];
        if(!product || !checkAddedProducts(product.id)) return false;
        changeValue('products', [
            ...products,
            {...product}
        ]);
    }

    const deleteOrderProduct = (id) => {
        if(!id) return false;
        const products = [...form.products];
        changeValue('products', [
            ...products.filter(e => Number(e.id) !== Number(id)),
        ]);
    };

    const sendEditData = () => {
        dispatch(sendEditOrderDataThunk(order_id))
    };

    useEffect(() => {
        dispatch(getDirectoryDataThunk());
        dispatch(getSellManagersThunk());
        dispatch(getAllCustomersThunk());
        dispatch(getAllProductsThunk());
    }, []);

    useEffect(() => {
        dispatch(getOrderEditDataThunk(order_id));
    }, [order_id]);

    return form ? <div className="content-wrapper">
        <PageTitle title={'order_data_edit'}/>
        <section className="content">
            <div className="container-fluid">
                <form onSubmit={(event) => {
                    event.preventDefault();
                    sendEditData();
                }}>
                    <div className="card">
                        <div className="card-header"><h3
                            className="card-title">Основна інформація</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4 order_edit_delivery_type">
                                    <div>
                                        <div className="input-group">
                                            <input type="radio"
                                                   className="form-control"
                                                   id={'types_n'}
                                                   name={'types'}
                                                   value={form.type}
                                                   onChange={() => {
                                                       if(form.asPartner) {
                                                           changeValue('type', 3);
                                                       } else {
                                                           changeValue('type', 1);
                                                       }
                                                   }}
                                                   checked={Number(form.type) === 1 || Number(form.type) === 3 ? 'checked' : ''}
                                            />
                                            <label htmlFor={'types_n'}>Товар в наявності</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="input-group">
                                            <input type="radio"
                                                   className="form-control"
                                                   name={'types'}
                                                   id={'types_k'}
                                                   value={form.type}
                                                   onChange={() => {
                                                       if(form.asPartner) {
                                                           changeValue('type', 4);
                                                       } else {
                                                           changeValue('type', 2);
                                                       }
                                                   }}
                                                   checked={Number(form.type) === 2 || Number(form.type) === 4 ? 'checked' : ''}
                                            />
                                            <label htmlFor={'types_k'}>Предзамовлення</label>
                                        </div>
                                    </div>
                                </div>
                                {checkPartnerStatus(form.id_customer) ? <>
                                    <div className="col-md-4">
                                        <div className="input-group col-md-12">
                                            <div className="icheck-primary">
                                                <input type="checkbox"
                                                       id="isPartnerOrders"
                                                       value={form.asPartner}
                                                       onChange={(event) => {
                                                           changeValue('asPartner', event.target.checked);
                                                           if(Number(form.type) === 1 && event.target.checked) changeValue('type', 3);
                                                           if(Number(form.type) === 1 && !event.target.checked) changeValue('type', 1);
                                                           if(Number(form.type) === 2 && event.target.checked) changeValue('type', 4);
                                                           if(Number(form.type) === 2 && !event.target.checked) changeValue('type', 2);
                                                           if(Number(form.type) === 3 && event.target.checked) changeValue('type', 3);
                                                           if(Number(form.type) === 3 && !event.target.checked) changeValue('type', 1);
                                                           if(Number(form.type) === 4 && event.target.checked) changeValue('type', 4);
                                                           if(Number(form.type) === 4 && !event.target.checked) changeValue('type', 2);

                                                       }}
                                                       checked={form.asPartner ? 'checked' : ''}
                                                />
                                                <label htmlFor="isPartnerOrders">
                                                    Партнерське замовлення
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </> : ''}
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="input-group mb-3 form_block_input">
                                            <label>
                                                Ім'я:
                                            </label>
                                            <input className={'form-control'}
                                                   value={form.name}
                                                   onChange={(event) => changeValue('name', event.target.value)}
                                                   required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="input-group mb-3 form_block_input">
                                            <label>
                                                Прізвище:
                                            </label>
                                            <input className={'form-control'}
                                                   value={form.surname}
                                                   onChange={(event) => changeValue('surname', event.target.value)}
                                                   required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="input-group mb-3 form_block_input">
                                            <label>
                                                По батькові:
                                            </label>
                                            <input className={'form-control'}
                                                   value={form.middle_name}
                                                   onChange={(event) => changeValue('middle_name', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="input-group mb-3 form_block_input">
                                            <label>
                                                Email:
                                            </label>
                                            <input className={'form-control'}
                                                   value={form.email}
                                                   onChange={(event) => changeValue('email', event.target.value)}
                                                   required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="input-group mb-3 form_block_input">
                                            <label>
                                                Телефон:
                                            </label>
                                            <InputMask mask="+380 99 999 9999"
                                                       maskChar=" "
                                                       className="form-control"
                                                       value={form.phone}
                                                       onChange={(event) => changeValue('phone', event.target.value)}
                                                       required={'required'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="input-group mb-3 form_block_input">
                                            <label>
                                                Клієнт:
                                            </label>
                                            <Select value={{
                                                value: form.id_customer,
                                                label: getCustomer(form.id_customer).name + ' ' + getCustomer(form.id_customer).surname
                                            }}
                                                    defaultValue={{
                                                        value: form.id_customer,
                                                        label: getCustomer(form.id_customer).name + ' ' + getCustomer(form.id_customer).surname
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        changeValue('id_customer', selectedOption.value);
                                                        changeValue('asPartner', false)
                                                    }}
                                                    className={'form-select-container'}
                                                    required={'required'}
                                                    options={customers && [
                                                        {
                                                            value: '',
                                                            label: 'Гість'
                                                        },
                                                        ...customers.map(e => {
                                                            return {
                                                                value: e.id,
                                                                label: e.name + ' ' + e.surname
                                                            }
                                                        })
                                                    ]
                                                    }/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header"><h3
                            className="card-title">Доставка</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 order_edit_delivery_type">
                                    <div>
                                        <div className="input-group">
                                            <input type="radio"
                                                   className="form-control"
                                                   id={'delivery_n'}
                                                   name={'delivery'}
                                                   value={form.delivery}
                                                   onChange={() => {
                                                       changeValue('delivery', 2);
                                                       changeValue('street', '');
                                                       changeValue('street_home', '')
                                                   }}
                                                   checked={form.delivery === 2 ? 'checked' : ''}
                                            />
                                            <label htmlFor={'delivery_n'}>Нова Пошта</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="input-group">
                                            <input type="radio"
                                                   className="form-control"
                                                   name={'delivery'}
                                                   id={'delivery_k'}
                                                   value={form.delivery}
                                                   onChange={() => {
                                                       changeValue('delivery', 1);
                                                       changeValue('np_warehouse', '')
                                                   }}
                                                   checked={form.delivery === 1 ? 'checked' : ''}
                                            />
                                            <label htmlFor={'delivery_k'}>Курьер</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="input-group mb-3 form_block_input">
                                            <label>
                                                Область:
                                            </label>
                                            <Select value={{
                                                        value: form.region,
                                                        label: getRegionName(form.region)
                                                    }}
                                                    defaultValue={{
                                                        value: form.region,
                                                        label: getRegionName(form.region)
                                                    }}
                                                    onChange={(selectedOption) => changeValue('region', selectedOption.value)}
                                                    className={'form-select-container'}
                                                    required={'required'}
                                                    options={regions && regions.map(e => {
                                                        return {
                                                            value: e.id,
                                                            label: e.name
                                                        }
                                                    })
                                            }/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="input-group mb-3 form_block_input">
                                            <label>
                                                Місто:
                                            </label>
                                            <Select value={{
                                                        value: form.city,
                                                        label: getCityName(form.city)
                                                    }}
                                                    defaultValue={{
                                                        value: form.city,
                                                        label: getCityName(form.city)
                                                    }}
                                                    onChange={(selectedOption) => changeValue('city', selectedOption.value)}
                                                    className={'form-select-container'}
                                                    required={'required'}
                                                    options={cities && cities.filter(e => {
                                                        return Number(e.id_region) === Number(form.region);
                                                    })
                                                        .map(e => {
                                                        return {
                                                            value: e.id,
                                                            label: e.name
                                                        }
                                                    })
                                            }/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {Number(form.delivery) === 1 && <>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label>
                                                    Вулиця:
                                                </label>
                                                <input className={'form-control'}
                                                       value={form.street}
                                                       onChange={(event) => changeValue('street', event.target.value)}
                                                       required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label>
                                                    # (Будинок):
                                                </label>
                                                <input className={'form-control'}
                                                       value={form.street_home}
                                                       onChange={(event) => changeValue('street_home', event.target.value)}
                                                       required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>}
                                {Number(form.delivery) === 2 && <>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label>
                                                    Відділення:
                                                </label>
                                                <Select value={{
                                                    value: form.np_warehouse,
                                                    label: getWarehouseName(form.np_warehouse)
                                                }}
                                                        defaultValue={{
                                                            value: form.np_warehouse,
                                                            label: getWarehouseName(form.np_warehouse)
                                                        }}
                                                        onChange={(selectedOption) => changeValue('np_warehouse', selectedOption.value)}
                                                        className={'form-select-container'}
                                                        required={'required'}
                                                        options={warehouses && warehouses.filter(e => {
                                                            return Number(e.id_city) === Number(form.city);
                                                        })
                                                            .map(e => {
                                                                return {
                                                                    value: e.id,
                                                                    label: e.name
                                                                }
                                                            })
                                                        }/>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header"><h3
                            className="card-title">Оплата</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 order_edit_payment_person_data_type">
                                    <div className="input-group col-md-12">
                                        <div className="icheck-primary">
                                            <input type="checkbox"
                                                   id="isFizPayment"
                                                   value={form.companyPayment}
                                                   onChange={(event) => changeValue('companyPayment', event.target.checked)}
                                                   checked={form.companyPayment ? 'checked' : ''}
                                            />
                                            <label htmlFor="isFizPayment">
                                                Платником є фізична особа
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {!form.companyPayment && <>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label>
                                                    Назва компанії:
                                                </label>
                                                <input className={'form-control'}
                                                       value={form.company_name}
                                                       onChange={(event) => changeValue('company_name', event.target.value)}
                                                       required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label>
                                                    Код компанії:
                                                </label>
                                                <input className={'form-control'}
                                                       value={form.company_code}
                                                       onChange={(event) => changeValue('company_code', event.target.value)}
                                                       required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label>
                                                    Адреса компанії:
                                                </label>
                                                <input className={'form-control'}
                                                       value={form.company_address}
                                                       onChange={(event) => changeValue('company_address', event.target.value)}
                                                       required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label>
                                                    Код платника ПДВ
                                                </label>
                                                <input className={'form-control'}
                                                       value={form.company_number_pdv}
                                                       onChange={(event) => changeValue('company_number_pdv', event.target.value)}
                                                       required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                            <div className="row">
                                <div className="col-md-12 order_edit_payment_person_data_type">
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label>
                                                    Промокод:
                                                </label>
                                                <Select value={{
                                                    value: form.promocode,
                                                    label: getPromocode(form.promocode).promo_code
                                                }}
                                                        defaultValue={{
                                                            value: form.promocode,
                                                            label: getPromocode(form.promocode).promo_code
                                                        }}
                                                        onChange={(selectedOption) => {
                                                            changeValue('promocode', selectedOption.value);
                                                            changeValue('promocodeDiscount', getPromocode(selectedOption.value).discount);
                                                        }}
                                                        className={'form-select-container'}
                                                        required={'required'}
                                                        options={promocodes && [
                                                            {
                                                                value: '',
                                                                label: 'Без використання промокода'
                                                            },
                                                            ...promocodes.filter(e => {
                                                                if(!Number(e.client)) {
                                                                    return true;
                                                                } else {
                                                                    if(Number(e.client) === Number(form.id_customer)) {
                                                                        return true
                                                                    } else {
                                                                        return false;
                                                                    }
                                                                }

                                                            }).map(e => {
                                                                return {
                                                                    value: e.promo_code,
                                                                    label: e.promo_code + (e.discount && ` (${Number(e.discount).toFixed(5)})`) + (e.client ? ` (${Number(e.client)})` : '')
                                                                }
                                                            })
                                                        ]
                                                        }/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header"><h3
                            className="card-title">Коментар</h3>
                        </div>
                        <div className={"card-body"}>
                            <div className="row">
                                <div className="col-md-12">
                                    <textarea
                                        className={"form-control"}
                                        rows={4}
                                        value={form.comment}
                                        onChange={(event) => changeValue('comment', event.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    {database && database.status < 3 ? <>
                        <div className="card">
                            <div className="card-header"><h3
                                className="card-title">Товари</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label>
                                                    Додати товар:
                                                </label>
                                                <AsyncSelect value={searchProducts}
                                                             defaultValue={searchProducts}
                                                             onChange={(selectedOption) => {
                                                                 setSearchProducts(selectedOption);
                                                             }}
                                                             className={'form-select-container'}
                                                             required={'required'}
                                                             loadOptions={loadOptions}
                                                             onInputChange={(value) => changeSearchInput(value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <button type={'button'} className="btn btn-success" onClick={() => addNewOrderProduct()} style={{
                                            marginTop: '31px'
                                        }}>
                                            +
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Назва</th>
                                                <th>Модель</th>
                                                <th>Кількість</th>
                                                <th>Ціна</th>
                                                <th>Сума</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {form.products && form.products.sort(function(a, b) {
                                                return a.id - b.id;
                                            }).map(e => {
                                                return <tr>
                                                    <td>{e.id}</td>
                                                    <td>{e.name}</td>
                                                    <td>{e.model}</td>
                                                    <td>
                                                        <input className={'form-control'}
                                                               value={e.count}
                                                               onChange={(event) => changeProductsData(e.id, 'count', event.target.value)}
                                                               required
                                                        />
                                                    </td>
                                                    <td>
                                                        <input className={'form-control'}
                                                               value={e.price}
                                                               onChange={(event) => {
                                                                   changeProductsData(e.id, 'price', event.target.value);
                                                                   changeProductsData(e.id, 'price_one', Number(Number(event.target.value) / Number(e.in_pack)).toFixed(2));
                                                               }}
                                                               required
                                                        />
                                                    </td>
                                                    <td>{Number(e.count) * Number(e.price)}</td>
                                                    <td>
                                                        <button className="btn btn-danger" onClick={() => deleteOrderProduct(e.id)}>
                                                            x
                                                        </button>
                                                    </td>
                                                </tr>
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button className="btn btn-success" type={'submit'}>
                                            Відправити
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : ''}
                </form>
            </div>
        </section>
    </div> : '';
}

export default OrderEdit;