import React from 'react';
import PageTitle from "../PageTitle/PageTitle";
import Chart from "react-google-charts";
import {Link} from "react-router-dom";
import {formatSum} from "../../store/reducers/MainReducer";

const Dashboard = (props) => {
    return (
        <div className="content-wrapper">
            <PageTitle title={'dashboard'}/>
            <section className="content">
                <div className="container-fluid dashboard">
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{props.dashboard.order_count}</h3>
                                    <p>Замовлень</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag"></i>
                                </div>
                                <Link to={"/orders"} className="small-box-footer">Детальніше <i
                                    className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-success">
                                <div className="inner">
                                    <h3>{formatSum(props.dashboard.total)} грн</h3>
                                    <p>Загальна сума</p>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-shopping-cart"></i>
                                </div>
                                <a href="/orders" className="small-box-footer">Детальніше <i
                                    className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3>{props.dashboard.customer_count}</h3>
                                    <p>Клієнтів</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-person-add"></i>
                                </div>
                                <a href="/customers" className="small-box-footer">Детальніше <i
                                    className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3>{props.dashboard.partner_count}</h3>
                                    <p>Партнерів</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-person-add"></i>
                                </div>
                                <a href="/customers" className="small-box-footer">Детальніше <i
                                    className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Франчезі</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{props.dashboard.order_franchisees_count}</h3>
                                    <p>Замовлень</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag"></i>
                                </div>
                                <Link to={"/orders"} className="small-box-footer">Детальніше <i
                                    className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-success">
                                <div className="inner">
                                    <h3>{formatSum(props.dashboard.total_franchisees)} грн</h3>
                                    <p>Загальна сума</p>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-shopping-cart"></i>
                                </div>
                                <a href="/orders" className="small-box-footer">Детальніше <i
                                    className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3>{props.dashboard.franchisees_count}</h3>
                                    <p>Франчезі</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-person-add"></i>
                                </div>
                                <a href="/customers" className="small-box-footer">Детальніше <i
                                    className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <div className="col-12 col-sm-6 col-md-3">*/}
                    {/*        <div className="info-box">*/}
                    {/*            <span className="info-box-icon bg-info elevation-1"><i*/}
                    {/*                className="fas fa-cog"></i></span>*/}

                    {/*            <div className="info-box-content">*/}
                    {/*                <span className="info-box-text">Навантаженість</span>*/}
                    {/*                <span className="info-box-number">10<small>%</small></span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-12 col-sm-6 col-md-3">*/}
                    {/*        <div className="info-box mb-3">*/}
                    {/*            <span className="info-box-icon bg-danger elevation-1"><i*/}
                    {/*                className="fas fa-thumbs-up"></i></span>*/}

                    {/*            <div className="info-box-content">*/}
                    {/*                <span className="info-box-text">Вподобань</span>*/}
                    {/*                <span className="info-box-number">41,410</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="clearfix hidden-md-up"></div>*/}

                    {/*    <div className="col-12 col-sm-6 col-md-3">*/}
                    {/*        <div className="info-box mb-3">*/}
                    {/*            <span className="info-box-icon bg-success elevation-1"><i*/}
                    {/*                className="fas fa-shopping-cart"></i></span>*/}

                    {/*            <div className="info-box-content">*/}
                    {/*                <span className="info-box-text">Продаж</span>*/}
                    {/*                <span className="info-box-number">760</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-12 col-sm-6 col-md-3">*/}
                    {/*        <div className="info-box mb-3">*/}
                    {/*            <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-users"></i></span>*/}

                    {/*            <div className="info-box-content">*/}
                    {/*                <span className="info-box-text">Нових клієнтів</span>*/}
                    {/*                <span className="info-box-number">2,000</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </section>
        </div>
    )
}

export default Dashboard;