import React from 'react';
import Header from "./Header.jsx";
import {connect} from "react-redux";
import './Header.css';
import {toggleSideBarTypeAC} from "../../store/reducers/MainReducer";

class HeaderContainer extends React.Component {
    componentDidMount() {
        // this.props.setNotifyData();
        // setInterval( () => {
        //     this.props.setNotifyData();
        // }, 10000)
    }

    render() {
        return <Header toggleSidebar={this.props.toggleSidebar} sidebarCollapse={this.props.sidebarCollapse} />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        toggleSidebar: (view) => dispatch(toggleSideBarTypeAC(view)),
    }
}

let mapStateToProps = (state) => {
    return {
        sidebarCollapse: state.main.sidebarCollapse,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);