import React from 'react';
import ApiService from "../../../api/api";

export const withLang = (Component) => {
    class LangData extends React.Component {

        apiService = new ApiService();

        constructor(props) {
            super(props);
            this.getTranslate = this.getTranslate.bind(this);
            this.state = {
                translates: null
            }
        }

        getTranslate(category, key) {

            if(!this.state.translates) return 'Не вказано';

            if(!this.state.translates[key]) {
                this.apiService.createTranslate({
                    key: key,
                    category: category,
                    lang: localStorage.getItem('medbuy_store_main_config_lang') ? localStorage.getItem('medbuy_store_main_config_lang') : 1,
                    value: key

                })
                    .then(res => {
                        return res;
                    })
                // return 'Не вказано';
            } else {
                return this.state.translates[key];
            }
        }

        componentDidMount() {
            const translates = JSON.parse(localStorage.getItem('medbuy_crm_translates_array_data'));
            if(translates) {
                this.setState({
                    translates: translates
                })
            } else {
                this.apiService.getTranslates()
                    .then(res => {
                        if(res) {
                            localStorage.setItem('medbuy_crm_translates_array_data', JSON.stringify(res));
                            this.setState({
                                translates: res
                            })
                        }
                    })
            }
        }

        render() {
            return <Component {...this.props} getTranslate={this.getTranslate}/>
        }
    }
    return LangData;
}