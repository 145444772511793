import React, {useState} from 'react';
import {changePartnerBonusStatus} from "../../store/reducers/MainReducer";
import {useDispatch} from "react-redux";

const CustomerChangeStatus = ({status, id}) => {

    const dispatch = useDispatch();
    const [isOpen, toggleIsOpen] = useState(false);
    const anotherStatus = Number(status) === 1 ? 0 : 1;
    const changeStatus = (status, id) => dispatch(changePartnerBonusStatus(status, id));

    return <div className="order_change_status_container">
        <div className="order_change_status_active" onClick={() => toggleIsOpen(!isOpen)}>
            <b>{status && Number(status) === 1 ? "Оплачено" : "Очікує оплати"}</b>
        </div>
        {isOpen && <div className="order_change_status_variants">
            <div className="order_change_status_variant_container">
                <button className={'btn ' + (Number(status) === 1 ? 'btn-info' : 'btn-success')}
                        onClick={() => {
                            changeStatus(anotherStatus, id);
                            toggleIsOpen(false);
                        }}
                >
                    {anotherStatus  && Number(anotherStatus) === 1 ? "Оплачено" : "Очікує оплати"}
                </button>
            </div>
        </div>}
    </div>
}

export default CustomerChangeStatus;