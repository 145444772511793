import React from 'react';
import Customers from "./Customers";
import {connect} from "react-redux";
import './Customers.css';
import {changeProductsPaginationsFormValueDataAC, getAllCustomersThunk} from "../../store/reducers/MainReducer";
import {withLang} from "../HOC/withLang/withLang";
import {compose} from "redux";
import Products from "../Products/Products";



class CustomersContainer extends React.Component {

    constructor(props){
        super(props);

    }



    componentDidMount() {
        this.props.getAllCustomersThunk()
    }

    render() {
        return <Customers customers={this.props.customers}
                          getTranslate={this.props.getTranslate}
                          changeProductsPaginationsFormValueData={this.props.changeProductsPaginationsFormValueData}
                          paginationHandler={this.props.getAllCustomersThunk}
                          paginationDataCount={this.props.customersCount}
                          paginationName={'customers'}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getAllCustomersThunk: () => dispatch(getAllCustomersThunk()),
        changeProductsPaginationsFormValueData: () => dispatch(changeProductsPaginationsFormValueDataAC('customers', 'page', 1)),
    }
}

let mapStateToProps = (state) => {
    return {
        customers: state.main.customers,
        customersCount: state.main.customersCount,
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(CustomersContainer);