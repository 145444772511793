import React from 'react';
import {Link} from "react-router-dom";


const ProductDetail = (props) => {
    let image = props.product && props.product.images && props.product.images.filter(e => Number(e.isMain) > 0)[0];
    let imageSecond = props.product && props.product.images && props.product.images.filter(e => Number(e.id) > 1);
    return (
        <div className="content-wrapper">
            <section className="content">
                <div className="container-fluid">
                    {props.product &&
                    <div className={"product_container"}>
                        <h2 className={"main_page_title"}>
                            {props.product.name}
                        </h2>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{props.getTranslate('crm_main','main_info_title')}</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-sm-4">
                                        <div className="col-12">
                                            <img width={"200px"} src={image.link}/>
                                        </div>
                                        <div className="col-12 product-image-thumbs">
                                            {imageSecond && imageSecond.map(e => {
                                                return (
                                                    <div className="product-image-thumb"><img width={"200px"}
                                                                                              src={e.link}/></div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-8">
                                        <div className="row">
                                            <div className="col-12 col-sm-3">
                                                <div className="info-box bg-light">
                                                    <div className="info-box-content">
                                                        <span className="info-box-text text-center text-muted">{props.getTranslate('crm_main','ID')}</span>
                                                        <span
                                                            className="info-box-number text-center text-muted mb-0">{props.product.id}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-3">
                                                <div className="info-box bg-light">
                                                    <div className="info-box-content">
                                                        <span
                                                            className="info-box-text text-center text-muted">{props.getTranslate('crm_main','model')}</span>
                                                        <span
                                                            className="info-box-number text-center text-muted mb-0">{props.product.model}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-3">
                                                <div className="info-box bg-light">
                                                    <div className="info-box-content">
                                                        <span className="info-box-text text-center text-muted">{props.getTranslate('crm_main','price')}</span>
                                                        <span
                                                            className="info-box-number text-center text-muted mb-0">{props.product.price}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-3">
                                                <div className="info-box bg-light">
                                                    <div className="info-box-content"><span
                                                        className="info-box-text text-center text-muted">{props.getTranslate('crm_main','price_one')}</span><span
                                                        className="info-box-number text-center text-muted mb-0">{props.product.price_one}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-3">
                                                <div className="info-box bg-light">
                                                    <div className="info-box-content">
                                                        <span
                                                            className="info-box-text text-center text-muted">{props.getTranslate('crm_main','rate')}</span>
                                                        <span
                                                            className="info-box-number text-center text-muted mb-0">{props.product.rates}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-3">
                                                <div className="info-box bg-light">
                                                    <div className="info-box-content">
                                                        <span className="info-box-text text-center text-muted">{props.getTranslate('crm_main','product_in_stock')}</span>
                                                        <span
                                                            className="info-box-number text-center text-muted mb-0">{props.product.count}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {props.product && props.product.pdv ?
                                                <div className="col-12 col-sm-3">
                                                    <div className="info-box bg-light">
                                                        <div className="info-box-content">
                                                            <span className="info-box-text text-center text-muted">{props.getTranslate('crm_form_add', 'form_add_product_pdv')}</span>
                                                            <span
                                                                className="info-box-number text-center text-muted mb-0">{props.product.pdv}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            : []}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{props.getTranslate('crm_main','detail_info_title')}</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">{props.getTranslate('crm_form_add', 'form_add_long_title')}</span>
                                                <span className="info-box-number text-center text-muted mb-0">{props.product.long_name}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">{props.getTranslate('crm_form_add', 'form_add_analyte_title')}</span>
                                                <span className="info-box-number text-center text-muted mb-0">{props.product.analyte}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">{props.getTranslate('crm_form_add', 'form_add_product_alias')}</span>
                                                <span className="info-box-number text-center text-muted mb-0"><a href={"https://medbuy.online/products/"+props.product.alias}>{props.product.alias}</a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </section>
        </div>
    );
}

export default ProductDetail;