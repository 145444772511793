import React from 'react';
import {Link} from "react-router-dom";
import ImageLogo from "../../../image/logo.png"

const AsideMenuAccessories = (props) => {
    const getRoleAccess = () => {
        switch(Number(props?.user?.role_id)) {
            case 3:
                return (
                    <>
                        <li className="nav-item menu-open">
                            <Link to={'/orders'}
                                  className={'nav-link' + (props.menu === 'orders' ? ' active' : '')}
                                  onClick={() => props.toggleMenu('orders')}>
                                <i className="nav-icon fas fa-clipboard-list"></i>
                                <p>{props.getTranslate('crm_menu','orders')}</p>
                            </Link>
                        </li>
                        <li className={'dropdown_custom_item nav-item' + (props.menu === 'warehouse_list' ? ' menu-open' : '')}>
                            <a
                                className={'nav-link' + (props.menu === 'warehouse_list' ? ' active' : '')}
                                onClick={() => props.toggleMenu('warehouse_list')}>
                                <i className="fas fa-warehouse"></i>
                                <p>
                                    {props.getTranslate('crm_menu','warehouses')}
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link to={'/warehouses'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','list_warehouses')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/warehouses/history'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','history_warehouses')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/warehouses/receiving'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','moving_warehouses')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/warehouses/write_off'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','write_off_warehouses')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/warehouses/moving'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','receiving_warehouses')}</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </>
                );
            case 4:
                return (
                    <>
                        <li className="nav-item menu-open">
                            <Link to={'/orders'}
                                  className={'nav-link' + (props.menu === 'orders' ? ' active' : '')}
                                  onClick={() => props.toggleMenu('orders')}>
                                <i className="nav-icon fas fa-clipboard-list"></i>
                                <p>{props.getTranslate('crm_menu','orders')}</p>
                            </Link>
                        </li>
                    </>
                );
            default:
                return null;
        }
    }
    return(
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="/" className="brand-link">
                <img src={ImageLogo} />
            </a>
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <span className="fas fa-user"></span>
                    </div>
                    <div className="info">
                        <a href="#" className="d-block">{props.user.name}</a>
                    </div>
                </div>
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <span className="fas fa-user-tag"></span>
                    </div>
                    <div className="info">
                        <a href="/customers" className="d-block">{props.user.role_name}</a>
                    </div>
                </div>
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        {getRoleAccess()}
                    </ul>
                </nav>
            </div>
        </aside>
    )
}

export default AsideMenuAccessories;
