import React from 'react';
import PageTitle from "../../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './ManufacturerAdd.css';

const ManufacturerAdd = (props) => {

    return (
        <div className="content-wrapper">
            <PageTitle title={'manufacturer_add'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header"><h3 className="card-title">{props.getTranslate('crm_form_add','form_add_main_info_title')}</h3>
                        </div>
                        <form onSubmit={(event) => {
                            event.preventDefault();
                            if(props.isEdit) {
                                props.editManufacturer({...props.form});
                            } else {
                                props.createManufacturer({...props.form});
                            }

                        }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <div className="input-group mb-3">
                                                <input className={'form-control'}
                                                       value={props.form && props.form.name}
                                                       onChange={(event) => props.changeValue('name', event.target.value)}
                                                       required
                                                       placeholder={props.getTranslate('crm_form_add','form_add_title')}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <button className={"btn btn-success"} type={'submit'}>{props.getTranslate('crm_form_add','form_add_button_create')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default ManufacturerAdd;