import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {changeProductsPaginationsFormValueDataAC} from "../../../store/reducers/MainReducer";

export const withPagination = (Component) => {

    const PaginationSQLf = (props) => {

        const dispatch = useDispatch();
        const { paginationName } = props;

        const page = useSelector((state) => state.main.pagination[paginationName].page);
        const pageSize = useSelector((state) => state.main.pagination[paginationName].limit);

        const renderPagination = (count) => {
            if(!count) return '';
            const pages = [];
            let countPage = Math.floor( count / pageSize);
            if(count % pageSize !== 0) {
                countPage++;
            }
            for(let i = 1; i <= Number(countPage); i++) {
                if (i === 1) {
                    pages.push(<span className={'page-link ' + (Number(page) === Number(i) ? 'active' : '')}
                                     onClick={() => {
                                         dispatch(changeProductsPaginationsFormValueDataAC(paginationName, 'page', i))
                                         props.paginationHandler()
                                     }}
                        >
                                {i}
                            </span>
                    );
                    pages.push(<span className={'page-link-separator'}>. . .</span>);
                } else if (i >= page - 3 && i <= page + 3)
                {
                    pages.push(<span className={'page-link ' + (Number(page) === Number(i) ? 'active' : '')}
                                     onClick={() => {
                                         dispatch(changeProductsPaginationsFormValueDataAC(paginationName, 'page', i))
                                         props.paginationHandler()
                                     }}
                        >
                                {i}
                            </span>
                    );
                } else if (i === countPage) {
                    pages.push(<span className={'page-link-separator'}>. . .</span>);
                    pages.push(<span className={'page-link ' + (Number(page) === Number(i) ? 'active' : '')}
                                     onClick={() => {
                                         dispatch(changeProductsPaginationsFormValueDataAC(paginationName, 'page', i))
                                         props.paginationHandler()
                                     }}
                        >
                                {i}
                            </span>
                    );
                }
            }
            return pages;
        };

        const renderPaginationBlock = (count) => {
            return <div className={'pagination_main_container'}>
                <ul className="pagination">
                    <li className="page-item">
                        {renderPagination(count)}
                    </li>
                </ul>
            </div>
        }

        return <Component {...props}
                          paginationHTML={renderPaginationBlock(props.paginationDataCount)}
                          // paginationPageStart={this.state.pageStart}
                          // paginationPages={this.getPages()}
        />

    }

    return PaginationSQLf;
}



