import ApiService from "../../api/api";

export const getData = async (type, filters = {}) => {
   const api = new ApiService();
   const body = await api._getData(
       "/analytics",
       'POST',
       {...filters},
       {"Point-Action": type},
       'json'
   );
   if(body && body.data) {
       return body.data;
   } else {
      return [];
   }
}