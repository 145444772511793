import React from 'react';
import {connect} from "react-redux";
import {
    getAllPartnersTestsThunk,
    getDirectoryDataThunk,
} from "../../store/reducers/MainReducer";
import ApiService from "../../api/api";
import {compose} from "redux";
import {withLang} from "../HOC/withLang/withLang";
import PartnersTests from "./PartnersTests";

class PartnersTestsContainer extends React.Component {

    apiService = new ApiService();

    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.props.directory();
        this.props.getAllPartnersTests();
    }

    render() {
        return <PartnersTests partnersTests={this.props.partnersTests}
                              paginationHandler={this.props.getAllPartnersTests}
                              getTranslate={this.props.getTranslate}
                              paginationName={'partners_tests'}
                              paginationDataCount={this.props.partnersTestsCount}
               />;
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getAllPartnersTests: () => dispatch(getAllPartnersTestsThunk()),
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
    }
}

let mapStateToProps = (state) => {
    return {
        partnersTests: state.main.partnersTests,
        partnersTestsCount: state.main.partnersTestsCount,
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(PartnersTestsContainer);
