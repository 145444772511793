import React from 'react';
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './Attributes.css';

const Attributes = (props) => {

    return(
        <div className="content-wrapper">
            <PageTitle title={'attributes'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-12 container_button">
                        <Link className={"btn btn-success"} to={"/attributes/add"}><i className="fas fa-plus-circle"></i></Link>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card">
                        <table className="table table-striped">
                            <thead>
                            <th>{props.getTranslate('crm_main','ID')}</th>
                            <th>{props.getTranslate('crm_main','name')}</th>
                            <th>{props.getTranslate('crm_main','date')}</th>
                            <th>{props.getTranslate('crm_main','action')}</th>
                            <th></th>
                            </thead>
                            <tbody>
                            {props.attributes && props.attributes.map(e => {
                                return <>
                                    <tr>
                                        <td>{e.id}</td>
                                        <td>{e.name}</td>
                                        <td>{e.date}</td>
                                        <td className={"action_button_td"}>
                                            <Link className={"btn btn-info"} to={'/attributes/edit/' + e.id}>
                                            <i className="fas fa-edit">
                                            </i>
                                        </Link>
                                            <button className={'btn btn-danger'} onClick={() => props.deleteAttribute(e.id)}>
                                                <i className="fas fa-minus-circle"></i>
                                            </button>
                                        </td>
                                        <td>
                                            {props.attributes && props.attributes.values &&
                                            <i className="fas fa-angle-down"
                                               onClick={() => props.toggleActive(e.id)}>
                                            </i>
                                            }
                                        </td>
                                    </tr>
                                    {Number(props.attributesOpen) === Number(e.id) ?
                                        <tr>
                                            <td colSpan={3}>
                                                <table className={'table table-bordered text-center'}>
                                                    <thead>
                                                    <tr>
                                                        <th>{props.getTranslate('crm_main', 'ID')}</th>
                                                        <th>{props.getTranslate('crm_main', 'name')}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {props.attributes && e.values.map(sb => {
                                                        return <tr>
                                                                <td>{sb.id}</td>
                                                                <td>{sb.name}</td>
                                                            </tr>
                                                    })}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        :
                                        ''
                                    }
                                </>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Attributes;