import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {ComposedChart} from "recharts";

export const BarVerticalCustomChart = ({ name, value, value_add, data }) => {
    return (
        <ResponsiveContainer width="100%" height="100%" style={{
            minHeight: '450px'
        }}>
            <ComposedChart
                layout="vertical"
                width={500}
                height={600}
                data={data}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20
                }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis type="number" />
                <YAxis dataKey={name} type="category" scale="band" />
                <Tooltip />
                <Bar dataKey={value} barSize={20} fill="#413ea0" />
                <Bar dataKey={value_add} barSize={20} fill="#8884d8" />
            </ComposedChart>
        </ResponsiveContainer>
    );
}



