import React from 'react';
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './CustomersBonus.css';
import CustomerChangeStatus from "./CustomerChangeStatus";
import ImagePDF from './../../image/pdf.png'

const CustomersBonus = (props) => {

    return(
        <div className="content-wrapper">
            <PageTitle title={'customer_bonus'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <table className="table table-striped">
                            <thead>
                            <th>{props.getTranslate('crm_main','ID')}</th>
                            <th>{props.getTranslate('crm_main','name')}</th>
                            <th>{props.getTranslate('crm_main','bonus')}</th>
                            <th>{props.getTranslate('crm_main','status')}</th>
                            <th>{props.getTranslate('crm_main','date_create')}</th>
                            <th>{props.getTranslate('crm_main','date_update')}</th>
                            <th></th>
                            </thead>
                            <tbody>
                            {props.customers_bonus && props.customers_bonus.map(e => {
                                return <>
                                    <tr>
                                        <td>{e.id}</td>
                                        <td>{e.name}</td>
                                        <td>{e.bonus}</td>
                                        <td>
                                            <CustomerChangeStatus status={e.status} id={e.id}/>
                                        </td>
                                        <td>{e.date}</td>
                                        <td>{e.date_update}</td>
                                        <td>
                                            <a target={"_blank"} href={e.link}>
                                                <img alt={""} src={ImagePDF} />
                                            </a>
                                        </td>
                                    </tr>
                                </>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>

    );
}



export default CustomersBonus;