import React from 'react';
import CustomerDetail from "./CustomerDetail";
import {connect} from "react-redux";
import './CustomerDetail.css';
import {
    getCustomerThunk,
    getDirectoryDataThunk, getManufacturersThunk,
    toggleOrderCustomerActiveDataAC
} from "../../../store/reducers/MainReducer";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";




class CustomerDetailContainer extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.props.getManufacturersThunk();
        this.props.getCustomerThunk(this.props.uri.match.params.id)
        this.props.directory();
    }

    render() {
        return <CustomerDetail
            customer={this.props.customer}
            getTranslate={this.props.getTranslate}
            toggleActive={this.props.toggleActive}
            orderPartnerOpen={this.props.orderPartnerOpen}
            directoryData={this.props.directoryData}
            manufacturers={this.props.manufacturers}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getCustomerThunk: (id) => dispatch(getCustomerThunk(id)),
        toggleActive: (id) => dispatch(toggleOrderCustomerActiveDataAC(id)),
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
        getManufacturersThunk: () => dispatch(getManufacturersThunk()),
    }
}

let mapStateToProps = (state) => {
    return {
        customer: state.main.customerDetail,
        orderPartnerOpen: state.main.orderPartnerOpen,
        directoryData: state.main.directoryData,
        manufacturers: state.main.manufacturers
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(CustomerDetailContainer);