import React from 'react';
import CustomersBonus from "./CustomersBonus";
import {connect} from "react-redux";
import './CustomersBonus.css';
import {getCustomersBonusThunk} from "../../store/reducers/MainReducer";
import {withLang} from "../HOC/withLang/withLang";
import {compose} from "redux";



class CustomerBonusContainer extends React.Component {

    constructor(props){
        super(props);

    }


    componentDidMount() {
        this.props.getCustomersBonusThunk();

    }

    render() {
        return <CustomersBonus customers_bonus={this.props.customers_bonus}
                              getTranslate={this.props.getTranslate}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getCustomersBonusThunk: () => dispatch(getCustomersBonusThunk()),
    }
}

let mapStateToProps = (state) => {
    return {
        customers_bonus: state.main.customers_bonus
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(CustomerBonusContainer);