import React from 'react';
import PageTitle from "../../PageTitle/PageTitle";
import './WarehouseActions.css';
import WarehouseAction from "./WarehouseAction/WarehouseAction";

const WarehouseActions = (props) => {


    const getTitle = (type) => {
        switch(type) {
            case 1:
                return 'warehouse_receiving';
            case 2:
                return 'warehouse_write_off';
            case 3:
                return 'warehouse_moving';
        }
    }

    return (
        <div className="content-wrapper">
            <PageTitle title={getTitle(props.type)}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                    <WarehouseAction type={props.type}/>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default WarehouseActions;