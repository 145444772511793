import React from 'react';
import {Route, Switch, Redirect  } from "react-router-dom";
import {connect} from "react-redux";
import './App.css';
import 'antd/dist/antd.css';
import ApiService from './api/api';
import Loader from "./components/Loader/Loader";
import './plugins/toastr/toastr.min.css';
import HeaderContainer from "./components/Header/HeaderContainer";
import AsideMenuContainer from "./components/AsideMenu/AsideMenuContainer";
import DashboardContainer from "./components/Dashboard/DashboardContainer";
import ProductsContainer from "./components/Products/ProductsContainer";
import ProductDetailContainer from "./components/Products/ProductDetail/ProductDetailContainer";
import OrdersContainer from "./components/Orders/OrdersContainer";
import OrderDetailContainer from "./components/Orders/OrderDetail/OrderDetailContainer";
import ProductsAddContainer from "./components/Products/ProductsAdd/ProductsAddContainer";
import {getLanguagesDataThunk} from "./store/reducers/MainReducer";
import ManufacturersContainer from "./components/Manufacturers/ManufacturersContainer";
import CustomersContainer from "./components/Customers/CustomersContainer";
import CustomerDetailContainer from "./components/Customers/CustomerDetail/CustomerDetailContainer";
import CategoriesContainer from "./components/Categories/CategoriesContainer";
import ManufacturerAddContainer from "./components/Manufacturers/ManufacturerAdd/ManufacturerAddContainer";
import CategoryFormContainer from "./components/Categories/CategoryForm/CategoryFormContainer";
import SellManagersContainer from "./components/SellManagers/SellManagersContainer";
import SellManagersFormContainer from "./components/SellManagers/SellManagersForm/SellManagersFormContainer";
import TagsContainer from "./components/Tags/TagsContainer";
import TagsFormContainer from "./components/Tags/TagsForm/TagsFormContainer";
import AttributesContainer from "./components/Attributes/AttributesContainer";
import AttributeFormContainer from "./components/Attributes/AttributeForm/AttributeFormContainer";
import AuthContainer from "./components/Auth/AuthContainer";
import {checkAuthDataThunk} from "./store/reducers/AuthReducer";
import WarehousesContainer from "./components/Warehouses/WarehousesContainer";
import WarehousesFormContainer from "./components/Warehouses/WarehousesForm/WarehousesFormContainer";
import WarehousesInvoiceFormContainer from "./components/Warehouses/WarehousesForm/WarehousesInvoiceFormContainer";
import CustomersBonusContainer from "./components/CustomersBonus/CustomersBonusContainer";
import WarehouseDetailContainer from "./components/Warehouses/WarehouseDetail/WarehouseDetailContainer";
import WarehouseHistoryContainer from "./components/Warehouses/WarehouseHistory/WarehouseHistoryContainer";
import WarehouseActionsContainer from "./components/Warehouses/WarehouseActions/WarehouseActionsContainer";
import WarehouseHistoryPage from "./components/Warehouses/WarehouseHistory/WarehouseHistoryPage";
import WarehousesWriteOffFormContainer
    from "./components/Warehouses/WarehousesForm/WarehousesWriteOff/WarehousesWriteOffFormContainer";
import WarehousesMovingFormContainer
    from "./components/Warehouses/WarehousesForm/WarehousesMoving/WarehousesMovingFormContainer";
import AsideMenuAccessoriesContainer from "./components/AsideMenu/AsideMenuAccessories/AsideMenuAccessoriesContainer";
import AlertContainer from "./components/Alert/AlertContainer";
import OrderEdit from "./components/Orders/OrderEdit/OrderEdit";
import Analytics from "./components/Analytics";
import WarehouseReservedContainer from "./components/Warehouses/WarehouseReserved/WarehouseReservedContainer";
import SlidersContainer from "./components/Sliders/SlidersContainer";
import PartnersTestsContainer from "./components/PartnersTests/PartnersTestsContainer";

class AppContainer extends React.Component {
    
  render() {
    return <App sidebarCollapse={this.props.sidebarCollapse}
                getLanguages={this.props.getLanguages}
                isAuth={this.props.isAuth}
                getAuth={this.props.getAuth}
                loading={this.props.loading}
                user={this.props.user}
    />

  }
}

class App extends React.Component {

    apiService = new ApiService();

    componentDidMount() {
        this.props.getLanguages();
        this.props.getAuth();
        if(!localStorage.getItem('medbuy_crm_main_config_lang')) {
            localStorage.setItem('medbuy_crm_main_config_lang', '1');
        }
    }

    getRoleAccess() {
        switch(Number(this.props?.user?.role_id)) {
            case 3:
                return (
                    <>
                        <AsideMenuAccessoriesContainer/>
                        <div className="wrapper">
                            <Switch>
                                <Route exact path={'/orders'}>
                                    <OrdersContainer/>
                                </Route>
                                <Route exact path={'/orders/:id'}
                                       render={(match) => <OrderDetailContainer uri={match}/>}
                                />
                                <Route exact path={'/warehouses'}>
                                    <WarehousesContainer/>
                                </Route>
                                <Route exact path={'/warehouses/add'}>
                                    <WarehousesFormContainer/>
                                </Route>
                                <Route exact path={'/warehouses/edit/:id'}
                                       render={(match) => <WarehousesFormContainer uri={match} isEdit={true}/>}/>
                                <Route exact path={'/warehouses/history'}><WarehouseHistoryPage/></Route>
                                <Route exact path={'/warehouses/invoice'}><WarehousesInvoiceFormContainer/></Route>
                                <Route exact path={'/warehouses/write_off_form'}><WarehousesWriteOffFormContainer/></Route>
                                <Route exact
                                       path={'/warehouses/moving_form'}><WarehousesMovingFormContainer/></Route>

                                {/*Нарахування*/}
                                <Route exact path={'/warehouses/receiving'}><WarehouseActionsContainer
                                    type={1}/></Route>
                                {/*Списання*/}
                                <Route exact path={'/warehouses/write_off'}><WarehouseActionsContainer
                                    type={2}/></Route>
                                {/*Переміщення*/}
                                <Route exact path={'/warehouses/moving'}><WarehouseActionsContainer
                                    type={3}/></Route>
                                <Route exact path={'/warehouses/:id'}
                                       render={(match) => <WarehouseDetailContainer uri={match}/>}/>
                            </Switch>
                        </div>
                    </>
                );
            case 4:
                return (
                    <>
                        <AsideMenuAccessoriesContainer/>
                        <div className="wrapper">
                            <Switch>
                                <Route exact path={'/orders'}>
                                    <OrdersContainer/>
                                </Route>
                                <Route exact path={'/orders/:id'}
                                       render={(match) => <OrderDetailContainer uri={match}/>}
                                />
                            </Switch>
                        </div>
                    </>
                );
            default:
                return (
                    <>
                        <AsideMenuContainer/>
                        <div className="wrapper">
                            <Switch>
                                <Route exact path={'/dashboard'}>
                                    <DashboardContainer/>
                                </Route>
                                <Route exact path={'/products'}>
                                    <ProductsContainer/>
                                </Route>
                                <Route exact path={'/products/add'}>
                                    <ProductsAddContainer/>
                                </Route>
                                <Route exact path={'/products/edit/:alias'}
                                       render={(match) => <ProductsAddContainer uri={match} isEdit={true}/>}/>
                                <Route exact path={'/products/:id'}
                                       render={(match) => <ProductDetailContainer uri={match}/>}/>
                                <Route exact path={'/orders'}>
                                    <OrdersContainer/>
                                </Route>
                                <Route exact path={'/orders/edit/:id'}
                                       render={(match) => <OrderEdit uri={match}/>}/>
                                <Route exact path={'/orders/:id'}
                                       render={(match) => <OrderDetailContainer uri={match}/>}/>
                                <Route exact path={'/manufacturers'}>
                                    <ManufacturersContainer/>
                                </Route>
                                <Route exact path={'/manufacturer/add'}>
                                    <ManufacturerAddContainer/>
                                </Route>
                                <Route exact path={'/manufacturer/edit/:id'}
                                       render={(match) => <ManufacturerAddContainer uri={match} isEdit={true}/>}/>
                                <Route exact path={'/customers'}>
                                    <CustomersContainer/>
                                </Route>
                                <Route exact path={'/customers_bonus'}>
                                    <CustomersBonusContainer/>
                                </Route>
                                <Route exact path={'/partners_tests'}>
                                    <PartnersTestsContainer/>
                                </Route>
                                <Route exact path={'/categories'}>
                                    <CategoriesContainer/>
                                </Route>
                                <Route exact path={'/categories/add'}>
                                    <CategoryFormContainer/>
                                </Route>
                                <Route exact path={'/categories/edit/:id'}
                                       render={(match) => <CategoryFormContainer uri={match} isEdit={true}/>}/>
                                <Route exact path={'/customers/:id'}
                                       render={(match) => <CustomerDetailContainer uri={match}/>}/>
                                <Route exact path={'/sell_managers'}>
                                    <SellManagersContainer/>
                                </Route>
                                <Route exact path={'/sell_managers/add'}>
                                    <SellManagersFormContainer/>
                                </Route>
                                <Route exact path={'/sell_managers/edit/:id'}
                                       render={(match) => <SellManagersFormContainer uri={match} isEdit={true}/>}/>
                                <Route exact path={'/warehouses'}>
                                    <WarehousesContainer/>
                                </Route>
                                <Route exact path={'/warehouses/add'}>
                                    <WarehousesFormContainer/>
                                </Route>
                                <Route exact path={'/warehouses/edit/:id'}
                                       render={(match) => <WarehousesFormContainer uri={match} isEdit={true}/>}/>
                                <Route exact path={'/warehouses/history'}><WarehouseHistoryPage/></Route>
                                <Route exact path={'/warehouses/invoice'}><WarehousesInvoiceFormContainer/></Route>
                                <Route exact path={'/warehouses/write_off_form'}><WarehousesWriteOffFormContainer/></Route>
                                <Route exact
                                       path={'/warehouses/moving_form'}><WarehousesMovingFormContainer/></Route>

                                {/*Резерв*/}
                                <Route exact path={'/warehouses/reserved'}>
                                    <WarehouseReservedContainer/>
                                </Route>
                                {/*Нарахування*/}
                                <Route exact path={'/warehouses/receiving/'}><WarehouseActionsContainer
                                    type={1}/></Route>
                                {/*Списання*/}
                                <Route exact path={'/warehouses/write_off'}><WarehouseActionsContainer
                                    type={2}/></Route>
                                {/*Переміщення*/}
                                <Route exact path={'/warehouses/moving'}><WarehouseActionsContainer
                                    type={3}/></Route>

                                <Route exact path={'/warehouses/:id'}
                                       render={(match) => <WarehouseDetailContainer uri={match}/>}/>
                                <Route exact path={'/tags'}>
                                    <TagsContainer/>
                                </Route>
                                <Route exact path={'/tags/add'}>
                                    <TagsFormContainer/>
                                </Route>
                                <Route exact path={'/tags/edit/:id'}
                                       render={(match) => <TagsFormContainer uri={match} isEdit={true}/>}/>
                                <Route exact path={'/attributes'}>
                                    <AttributesContainer/>
                                </Route>
                                <Route exact path={'/sliders'}>
                                    <SlidersContainer/>
                                </Route>
                                <Route exact path={'/attributes/edit/:id'}
                                       render={(match) => <AttributeFormContainer uri={match} isEdit={true}/>}/>
                                <Route exact path={'/attributes/add'}>
                                    <AttributeFormContainer/>
                                </Route>
                                <Route path={'/analytics/:type?'}
                                       render={(match) => <Analytics uri={match}/>}
                                />
                            </Switch>
                        </div>
                    </>
                );
        }
    }

    render() {
        return (
            <div className={'main_wrapper sidebar-mini' + (this.props.sidebarCollapse ? ' sidebar-collapse' : '')}>
                {this.props.isAuth ? <>
                    <HeaderContainer/>
                        {this.getRoleAccess()}
                </>
                :
                <Switch>
                    <Route path={'/'} >
                        <AuthContainer />
                    </Route>
                </Switch>}
                <AlertContainer/>
                {this.props.loading ? <Loader/> : ''}
            </div>
        )

    };
}

let mapStateToProps = (state) => {
  return {
      sidebarCollapse: state.main.sidebarCollapse,
      languages: state.main.languages,
      isAuth: state.auth.isAuth,
      user: state.auth.user,
      loading: state.main.loader.active
  }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getLanguages: () => dispatch(getLanguagesDataThunk()),
        getAuth: () => dispatch(checkAuthDataThunk()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
