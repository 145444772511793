import React from 'react';
import Products from "./Products";
import {connect} from "react-redux";
import './Products.css';
import {
    changeProductsFilterFormValueAC, changeProductsPaginationsFormValueDataAC,
    copyProductThunk,
    getAllProductsThunk,
    getProductEditThunk
} from "../../store/reducers/MainReducer";
import {withLang} from "../HOC/withLang/withLang";
import {compose} from "redux";
import {checkAuthDataThunk} from "../../store/reducers/AuthReducer";



class ProductsContainer extends React.Component {

    constructor(props){
        super(props);
    }



    componentDidMount() {
        this.props.changeProductsPaginationsFormValueData();
        this.props.getAllProductsThunk()
        this.props.getAuth();
    }


    render() {
        return <Products products={this.props.products}
                         getTranslate={this.props.getTranslate}
                         copyProduct={this.props.copyProduct}
                         languages={this.props.languages}
                         changeFilterValue={this.props.changeFilterValue}
                         formFilters={this.props.formFilters}
                         getAllProductsThunk={this.props.getAllProductsThunk}
                         user={this.props.user}
                         paginationHandler={this.props.getAllProductsThunk}
                         paginationDataCount={this.props.productsCount}
                         paginationName={'products'}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getAllProductsThunk: () => dispatch(getAllProductsThunk()),
        copyProduct: (id, languages) => dispatch(copyProductThunk(id, languages)),
        changeFilterValue: (input, value) => dispatch(changeProductsFilterFormValueAC(input, value)),
        changeProductsPaginationsFormValueData: () => dispatch(changeProductsPaginationsFormValueDataAC('products', 'page', 1)),
        getAuth: () => dispatch(checkAuthDataThunk()),
    }
}

let mapStateToProps = (state) => {
    return {
        products: state.main.products,
        languages: state.main.languages,
        formFilters: state.main.productsFilters,
        productsCount: state.main.productsCount,
        user: state.auth.user,
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(ProductsContainer);
