import React from 'react';
import {connect} from "react-redux";
import './AttributeForm.css';
import {withLang} from "../../HOC/withLang/withLang";
import {compose} from "redux";
import AttributeForm from "./AttributeForm";
import {
    changeAttributeFormValueAC,
    createAttributeThunk,
    editAttributeThunk,
    getAttributeEditThunk,
    getProductEditThunk,
    setAttributeEditDataAC
} from "../../../store/reducers/MainReducer";
import ApiService from "../../../api/api";
const apiService = new ApiService();


class AttributeFormContainer extends React.Component {

    constructor(props){
        super(props);
        this.getEditData = this.getEditData.bind(this);
    }

    async getEditData(id, languages) {
        if(!languages) return false;
        const attribute = {
            name: [],
            type: null,
            values: [],
            id: id
        };
        for(let i = 0; i <= languages.length - 1; i++) {
            const res = await apiService.getAttribute(id, languages[i].id);
            if(res && res.name) {
                attribute.name.push({
                    id: [...attribute.name].length === 0 ? 1 : Math.max.apply(null, [...attribute.name].map( (e) => languages[i].id )) + 1,
                    lang: languages[i].id,
                    name: res.name
                });
                if(Number(i) === 0) {
                    attribute.values = res.values.map(m => {
                        return {
                            id: m.id,
                            name: [{
                                lang: languages[i].id,
                                name: m.name
                            }]
                        }
                    });
                } else {
                    attribute.values.map(p => {
                        res.values.forEach(v => {
                            if(Number(p.id) === Number(v.id)) {
                                p.name.push({
                                    lang: languages[i].id,
                                    name: v.name
                                })
                            }
                        });
                    })
                }
                attribute.type = res.type
            }
        }
        this.props.getEitData(attribute);
    }

    componentDidMount() {
        let name = [];
        this.props.languages && this.props.languages.forEach(e => {
            name.push({
                lang: e.id,
                name: ''
            })
        });
        this.props.changeValue('name', name);

        if(this.props.isEdit) {
            this.getEditData(this.props.uri.match.params.id, this.props.languages);
        } else {
            // this.props.cleanProductAddForm();
            // this.props.changeValue('model', null);
        }

    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.languages && !this.props.languages) {
            let name = [];
            nextProps.languages && nextProps.languages.forEach(e => {
                name.push({
                    lang: e.id,
                    name: ''
                })
            });
            nextProps.changeValue('name', name);
            if(nextProps.isEdit) {
                this.getEditData(nextProps.uri.match.params.id, nextProps.languages);
            }
        }
    }

    render() {
        return <AttributeForm getTranslate={this.props.getTranslate}
                              changeValue={this.props.changeValue}
                              createAttribute={this.props.createAttribute}
                              editAttribute={this.props.editAttribute}
                              languages={this.props.languages}
                              isEdit={this.props.isEdit}
                              form={this.props.form}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeAttributeFormValueAC(input, value)),
        createAttribute: (attribute) => dispatch(createAttributeThunk(attribute)),
        getEitData: (id, languages) => dispatch(getAttributeEditThunk(id, languages)),
        editAttribute: (attribute) => dispatch(editAttributeThunk(attribute)),
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.main.attributeForm,
        languages: state.main.languages
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(AttributeFormContainer);
