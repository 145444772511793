import React from 'react';
import {connect} from "react-redux";
import './UploadFile.css';
import ApiService from "../../api/api";
import {withLang} from "../HOC/withLang/withLang";
import {compose} from "redux";
import UploadFile from "./UploadFile";
import {toggleUploadFileModalActiveAC} from "../../store/reducers/MainReducer";

class UploadFileContainer extends React.Component {

    apiService = new ApiService();

    constructor(props){
        super(props);
        this.getFiles = this.getFiles.bind(this);
        this.sendFileData = this.sendFileData.bind(this);
        this.state = {
            file: null
        }
    }

    getFiles(files) {
        this.setState({
            file: files[0]
        })
    }

    sendFileData() {
        switch (this.props.types) {
            case 'product_image':
                this.apiService.sendProductImage(this.state.file)
                    .then(res => {
                        if(res && res.link) {
                            this.props.uploadHandler(res.link);
                            this.setState({
                                file: null
                            })
                        }
                    });
                break;
            case 'product_document':
                this.apiService.sendProductDocument(this.state.file)
                    .then(res => {
                        if(res && res.link && res.name) {
                            this.props.uploadHandler(res.link, res.name);
                            this.setState({
                                file: null
                            })
                        }
                    });
                break;
        }

    }

    componentDidMount() {

    }

    render() {
        return <UploadFile isOpen={this.props.isOpen}
                           toggleModal={this.props.toggleModal}
                           getFiles={this.getFiles}
                           file={this.state.file}
                           sendFileData={this.sendFileData}
                           types={this.props.types}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {

    }
}

let mapStateToProps = (state) => {
    return {

    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(UploadFileContainer);