import React from 'react';
import PageTitle from "../../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './TagsForm.css';

const TagsForm = (props) => {

    return (
        <div className="content-wrapper">
            <PageTitle title={'tags_form'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header"><h3 className="card-title">{props.getTranslate('crm_form_add','form_add_main_info_title')}</h3>
                        </div>
                        <form onSubmit={(event) => {
                            event.preventDefault();
                            if(props.isEdit) {
                                props.editTag({...props.form});
                            } else {
                                props.createTags({...props.form});
                            }
                        }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-group">
                                        <label htmlFor={"TagsName"}>{props.getTranslate('crm_form_add','form_add_title')}:</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            {props.languages && props.languages.map(e => {
                                                return <div className="input-group mb-3">
                                                    <label>{e.code}:</label>
                                                    <input className={'form-control'}
                                                           value={props.form && props.form.name && props.form.name.filter(name => Number(name.lang) === Number(e.id))[0] && props.form.name.filter(name => Number(name.lang) === Number(e.id))[0].name}
                                                           onChange={(event) => {
                                                               let form_name = [...props.form.name];
                                                               form_name = form_name.map(f => {
                                                                   if(Number(e.id) === Number(f.lang)) {
                                                                       f.name = event.target.value;
                                                                   }
                                                                   return f;
                                                               })
                                                               props.changeValue('name', form_name);
                                                           }}
                                                           required
                                                           placeholder={props.getTranslate('crm_form_add', 'form_add_title')}
                                                    />
                                                </div>
                                            })}
                                            {/*<div className="input-group mb-3 form_block_input">*/}
                                            {/*    <label htmlFor={"TagsName"}>{props.getTranslate('crm_form_add','form_add_title')}:</label>*/}
                                            {/*    <input className={'form-control'}*/}
                                            {/*           id={"TagsName"}*/}
                                            {/*           value={props.form && props.form.name}*/}
                                            {/*           onChange={(event) => props.changeValue('name', event.target.value)}*/}
                                            {/*           required*/}
                                            {/*           placeholder={props.getTranslate('crm_form_add','form_add_title')}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button className={"btn btn-success"} type={'submit'}>{props.getTranslate('crm_form_add','form_add_button_create')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default TagsForm;