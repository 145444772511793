import React from 'react';
import {connect} from "react-redux";
import './WarehousesMovingForm.css';
import {withLang} from "../../../HOC/withLang/withLang";
import {compose} from "redux";
import WarehousesMovingForm from "./WarehousesMovingForm";
import {
    changeWarehousesMovingFormValueAC, createWarehousesMovingThunk, getAllProductLimitedThunk,
    getAllProductsThunk,
    getDirectoryDataThunk, getProducersThunk,
    getWarehousesThunk
} from "../../../../store/reducers/MainReducer";
import WarehousesWriteOffForm from "../WarehousesWriteOff/WarehousesWriteOffForm";
import ApiService from "../../../../api/api";



class WarehousesMovingFormContainer extends React.Component {

    apiService = new ApiService();

    constructor(props){
        super(props);
        this.state = {
            products: []
        }
    }


    componentDidMount() {
        this.props.directory();
        this.props.getAllProductsThunk()
        this.props.getWarehousesThunk()
        this.props.getProducersThunk()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(Number(prevProps.form.warehouse_from) !== Number(this.props.form.warehouse_from)) {
            this.props.changeValue('products', []);
            this.apiService.getWarehouseDetail(this.props.form.warehouse_from)
                .then(res => {
                    if(res) {
                        this.setState({
                            products: res.products.filter(e => Number(e.count) > 0 || (res.products_retail.filter(r => Number(r.id) === Number(e.id))[0] && Number(res.products_retail.filter(r => Number(r.id) === Number(e.id))[0].count) > 0)),
                            products_retail: res.products_retail.filter(e => Number(e.count) > 0),
                            products_reserved: res.products_reserved
                        })
                    }
                })
        }
    }

    render() {
        return <WarehousesMovingForm getTranslate={this.props.getTranslate}
                                     changeValue={this.props.changeValue}
                                     createWarehousesMoving={this.props.createWarehousesMoving}
                                     form={this.props.form}
                                     directoryData={this.props.directoryData}
                                     products={this.state.products}
                                     products_retail={this.state.products_retail}
                                     products_reserved={this.state.products_reserved}
                                     warehouses={this.props.warehouses}
                                     producers={this.props.producers}
                                     products_type={this.props.products_type}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeWarehousesMovingFormValueAC(input, value)),
        createWarehousesMoving: (warehouses) => dispatch(createWarehousesMovingThunk(warehouses)),
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
        getAllProductsThunk: () => dispatch(getAllProductLimitedThunk()),
        getWarehousesThunk: () => dispatch(getWarehousesThunk()),
        getProducersThunk: () => dispatch(getProducersThunk())
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.main.WarehousesMovingForm,
        directoryData: state.main.directoryData,
        products: state.main.products,
        warehouses: state.main.warehouses,
        producers: state.main.producers,
        products_type: state.main.products_type
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(WarehousesMovingFormContainer);
