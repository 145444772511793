import React from 'react';
import {alertShowAC} from "../../store/reducers/MainReducer";
import {useDispatch} from "react-redux";

const Alert = (props) => {

    const dispatch = useDispatch();

    return(
        // <div id="toast-container" className="toast-top-right">
        //     <div className={'toast ' + (props.type === 'error' ? 'toast-error' : 'toast-success')} aria-live="polite">
        //         <div className="toast-message">{props.message}</div>
        //     </div>
        // </div>

    <div className={"alert_custom_style toast fade show "+(props.type === 'error' ? 'bg-danger' : 'bg-success')}>
        <div className="toast-header"><strong className="mr-auto">Помилка!</strong><small></small>
            <button
                data-dismiss="toast"
                type="button"
                className="ml-2 mb-1 close"
                aria-label="Close"
                onClick={() => {
                 dispatch(alertShowAC("active", false))
                }}
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div className="toast-body">{props.message}</div>
    </div>
    )
}

export default Alert;