import React from 'react';
import Dashboard from "./Dashboard";
import {connect} from "react-redux";
import './Dashboard.css';
import {getDashboardDataThunk} from "../../store/reducers/MainReducer";



class DashboardContainer extends React.Component {

    constructor(props){
        super(props);

    }

    componentDidMount() {
        this.props.getDashboardDataThunk()
    }

    render() {
        return <Dashboard dashboard={this.props.dashboard}/>
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getDashboardDataThunk: () => dispatch(getDashboardDataThunk())
    }
}

let mapStateToProps = (state) => {
    return {
        dashboard: state.main.dashboard
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);