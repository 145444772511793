import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {getOrderReceiptThunk} from "../../../store/reducers/MainReducer";
import ApiService from "../../../api/api";

const api = new ApiService();

const OrderDetailReceipt = ({order_id, close}) => {
    const dispatch = useDispatch();
    const [users, setUsers] = useState([])
    const getReceiptOrder = (form) => {
        dispatch(getOrderReceiptThunk(order_id, form));
        close();
    }
    const [form, changeForm] = useState({
        receipt_type: null,
        showAddress: true,
        manager: null
    }) ;
    const changeValue = (input, value) => {
        const formData = {...form};
        formData[input] = value;
        changeForm(formData);
    }

    useEffect(() => {
        api.getUsers().then(res => {
            setUsers(res.data);
        })
    }, []);

    return <>
        <form onSubmit={(event) => {
            event.preventDefault();
            getReceiptOrder(form);
        }}>
            <div className="form-group">
                <label>Тип (назва) договору:</label>
                <input className="form-control"
                       required=""
                       value={form.receipt_type}
                       onChange={(event) => changeValue('receipt_type', event.target.value)}
                />
            </div>
            <div className="form-group">
                <label>Підписант:</label>
                <input className="form-control"
                       required=""
                       value={form.manager}
                       onChange={(event) => changeValue('manager', event.target.value)}
                />
                <select className="form-control"
                       required=""
                       value={form.manager}
                       defaultValue={form.manager}
                       onChange={(event) => changeValue('manager', event.target.value)}
                        style={{
                            marginTop: '9px'
                        }}
                >
                    <option value={''} key={0}>------</option>
                    {users.filter(e => Number(e.pidpusant) === 1).map(e => <option value={e.name} key={e.id}>{e.name}</option>)}
                </select>
            </div>
            <div className="form-group">
                <label>Показувати адресу доставки:</label>
                <input className="form-control"
                       type={'checkbox'}
                       required=""
                       checked={form.showAddress ? 'checked' : ''}
                       onChange={(event) => changeValue('showAddress', event.target.checked)}
                />
            </div>
            <div className="form-group">
                <button type={'submit'} className={'btn btn-success'}>OKAY</button>
            </div>
        </form>
    </>;
}

export default OrderDetailReceipt;