import React, {useEffect, useState} from "react";
import PageTitle from "../../PageTitle/PageTitle";
import TableAnalytics from "./Table";
import {SelectType} from "./SelectType";
import { LineChartModule } from "./Charts/Line";
import {getData} from "../actions";
import {BarCustomChart as Bar} from "./Charts/Bar";
import {BarVerticalCustomChart as BarVertical} from "./Charts/BarVertical";
import { Filters } from './Filters';
import {compose} from "redux";
import {connect} from "react-redux";
import {withLang} from "../../HOC/withLang/withLang";
import { getDirectoryDataThunk } from "../../../store/reducers/MainReducer";

class AnalyticsContainer extends React.Component {

    constructor() {
        super();
        this.filterData = this.filterData.bind(this);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.props.directory();
        const { type } = this.props.uri.match.params;
        getData(type).then(res => {
            this.setState({data:res});
        })
    }

    filterData(filters) {
        const { type } = this.props.uri.match.params;
        getData(type, filters).then(res => {
            this.setState({data:res});
        })
    }

    render() {

        const { type } = this.props.uri.match.params;
        const { data } = this.state;

        if(!this.props.directoryData || this.props.directoryData.length === 0) {
            return '';
        }

        const renderChart = () => {
            return <>
                {type === 'sales' && <LineChartModule type={type} data={data}/>}
                {type === 'top_products' && <Bar name={'model'} value={'count'} data={data}/>}
                {type === 'customers' && <BarVertical name={'full_name'} value={'orders_total'} value_add={'orders_count'} data={data.filter((e,i) => i <= 10)}/>}
            </>
        }

        return <div className="content-wrapper">
            <PageTitle title={'analytics'}/>
            <section className="content">
                <div className="container-fluid analytics_container">
                    <SelectType type={type}/>
                    {type && <>
                        <Filters type={type} onFilter={(filters) => this.filterData(filters)}/>
                        <TableAnalytics type={type} data={data}/>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <div className={"analytics_charts"}>
                                    {renderChart()}
                                </div>
                            </div>
                        </div>

                    </>}
                </div>
            </section>
        </div>;
    }

};

let mapStateToProps = (state) => {
    return {
        directoryData: state.main.directoryData,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(AnalyticsContainer);