import React from 'react';
import {connect} from "react-redux";
import './CategoryForm.css';
import {withLang} from "../../HOC/withLang/withLang";
import {compose} from "redux";
import CategoryForm from "./CategoryForm";
import {
    changeCategoryFormValueAC,
    createCategoryThunk, editCategoryThunk,
    editTagThunk,
    getCategoriesThunk,
    getCategoryEditThunk,
    getTagEditThunk
} from "../../../store/reducers/MainReducer";



class CategoryFormContainer extends React.Component {

    constructor(props){
        super(props);

    }


    componentDidMount() {
        this.props.getCategories();
        let name = [];
        this.props.languages && this.props.languages.forEach(e => {
            name.push({
                lang: e.id,
                name: ''
            })
        });
        this.props.changeValue('name', name);
        if(this.props.isEdit) {
            this.props.getEitData(this.props.uri.match.params.id, this.props.languages);
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.languages && !this.props.languages) {
            let name = [];
            nextProps.languages && nextProps.languages.forEach(e => {
                name.push({
                    lang: e.id,
                    name: ''
                })
            });
            nextProps.changeValue('name', name);
            if(nextProps.isEdit) {
                nextProps.getEitData(nextProps.uri.match.params.id, nextProps.languages);
            }
        }
    }

    render() {
        return <CategoryForm getTranslate={this.props.getTranslate}
                             changeValue={this.props.changeValue}
                             languages={this.props.languages}
                             createCategory={this.props.createCategory}
                             editCategory={this.props.editCategory}
                             categories={this.props.categories}
                             form={this.props.form}
                             isEdit={this.props.isEdit}
                             categoryId={this.props.uri && this.props.uri.match.params.id}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeCategoryFormValueAC(input, value)),
        createCategory: (category) => dispatch(createCategoryThunk(category)),
        getCategories: () => dispatch(getCategoriesThunk()),
        editCategory: (category) => dispatch(editCategoryThunk(category)),
        getEitData: (id, languages) => dispatch(getCategoryEditThunk(id, languages))
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.main.categoryForm,
        languages: state.main.languages,
        categories: state.main.categories
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(CategoryFormContainer);
