import React from 'react';
import PageTitle from "../../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './SellManagersForm.css';
import Select from "react-select";

const SellManagersForm = (props) => {

    const getCities = (region) => {
        let citi = props.form.region && props.directoryData.cities && props.directoryData.cities
            .filter(e => Number(e.id_region) === Number(props.form.region))
            .map(e => {
                const regex = /\((.*)\)/;
                return {
                    value: e.id,
                    label: e.name.replace(regex, '')
                }
            });
        return citi;
    }

    const getCustomerOption = (id) => {
        if(!props.customers) return ' ';
        const customer = props.customers.filter(e => Number(e.id) === Number(id))[0];
        if(!customer) return '';
        return customer.surname + ' ' + customer.name;
    }

    return (
        <div className="content-wrapper">
            <PageTitle title={'sell_managers_form'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header"><h3
                            className="card-title">{props.getTranslate('crm_form_add', 'form_add_main_info_title')}</h3>
                        </div>
                        <form onSubmit={(event) => {
                            event.preventDefault();
                            if(props.isEdit){
                                props.editManager()
                            } else  {
                                props.createSellManagers({...props.form});
                            }
                        }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label
                                                    htmlFor={"SellManagersName"}>{props.getTranslate('crm_form_add', 'form_add_title')}:</label>
                                                <input className={'form-control'}
                                                       id={"SellManagersName"}
                                                       value={props.form && props.form.name}
                                                       onChange={(event) => props.changeValue('name', event.target.value)}
                                                       required
                                                       placeholder={props.getTranslate('crm_form_add', 'form_add_title')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label
                                                    htmlFor={"SellManagersPhone"}>{props.getTranslate('crm_form_add', 'phone')}:</label>
                                                <input className={'form-control'}
                                                       id={"SellManagersPhone"}
                                                       value={props.form && props.form.phone}
                                                       onChange={(event) => props.changeValue('phone', event.target.value)}
                                                       required
                                                       placeholder={props.getTranslate('crm_form_add', 'phone')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                                <label
                                                    htmlFor={"SellManagersCity"}>{props.getTranslate('crm_form_add', 'form_add_region')}:</label>
                                                <Select value={props.form && {
                                                    value: props.form.region,
                                                    label: props.directoryData.regions && props.directoryData.regions.filter(e => Number(e.id) === Number(props.form.region))[0] && props.directoryData.regions.filter(e => Number(e.id) === Number(props.form.region))[0].name
                                                }}
                                                        defaultValue={props.form && {
                                                            value: props.form.region,
                                                            label: props.directoryData.regions && props.directoryData.regions.filter(e => Number(e.id) === Number(props.form.region))[0] && props.directoryData.regions.filter(e => Number(e.id) === Number(props.form.region))[0].name
                                                        }}
                                                        onChange={(selectedOption) => props.changeValue('region', selectedOption.value)}
                                                        placeholder={'Регіон'}
                                                        className={'form-select-container'}
                                                        required={'required'}
                                                        options={props.directoryData.regions && props.directoryData.regions.map(e => {
                                                            return {
                                                                value: e.id,
                                                                label: e.name
                                                            }
                                                        })
                                                        }/>
                                            </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                                <label
                                                    htmlFor={"SellManagersCity"}>{props.getTranslate('crm_form_add', 'form_add_city')}:</label>
                                                <Select
                                                        value={props.form && {
                                                            value: props.form.city,
                                                            label: props.directoryData.cities && props.directoryData.cities.filter(e => Number(e.id) === Number(props.form.city))[0] && props.directoryData.cities.filter(e => Number(e.id) === Number(props.form.city))[0].name
                                                        }}
                                                        defaultValue={props.form && {
                                                            value: props.form.city,
                                                            label: props.directoryData.cities && props.directoryData.cities.filter(e => Number(e.id) === Number(props.form.city))[0] && props.directoryData.cities.filter(e => Number(e.id) === Number(props.form.city))[0].name
                                                        }}
                                                        onChange={(selectedOption) => props.changeValue('city', selectedOption.value)}
                                                        placeholder={'Місто'}
                                                        className={'form-select-container'}
                                                        required={'required'}
                                                        options={getCities()}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label
                                                    htmlFor={"SellManagersDateActive"}>{props.getTranslate('crm_form_add', 'form_add_date_active')}:</label>
                                                <input className={'form-control'}
                                                       id={"SellManagersDateActive"}
                                                       value={props.form && props.form.date_active && props.form.date_active.substr(0,10)}
                                                       onChange={(event) => props.changeValue('date_active', event.target.value)}
                                                       required
                                                       type={"date"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label
                                                    htmlFor={"SellManagersDiscount"}>{props.getTranslate('crm_form_add', 'form_add_discount')}:</label>
                                                <input className={'form-control'}
                                                       id={"SellManagersDiscount"}
                                                       value={props.form && props.form.discount}
                                                       onChange={(event) => props.changeValue('discount', event.target.value)}
                                                       required
                                                       placeholder={"0.01"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label
                                                    htmlFor={"SellManagersDescription"}>{props.getTranslate('crm_form_add', 'form_add_description')}:</label>
                                                <textarea className={'form-control'}
                                                          id={"SellManagersDescription"}
                                                          rows={"4"}
                                                          value={props.form && props.form.description}
                                                          onChange={(event) => props.changeValue('description', event.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{props.getTranslate('crm_form_add', 'form_add_for_customer')}:</label>
                                            <Select
                                                    value={props.form && {
                                                        value: props.form.customer,
                                                        label: getCustomerOption(props.form.customer)
                                                    }}
                                                    onChange={(selectedOption) => props.changeValue('customer', selectedOption.value)}
                                                    placeholder={'Клієнт'}
                                                    className={'form-select-container'}
                                                    options={props.customers && props.customers.map(e => {
                                                        return {
                                                            value: e.id,
                                                            label: e.surname ? e.surname + " " + e.name : ""
                                                        }
                                                    })
                                                    }/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button
                                        className={"btn btn-success"}
                                        type={'submit'}
                                    >{props.getTranslate('crm_form_add', 'form_add_button_create')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default SellManagersForm;