import React from 'react';
import './Auth.css';

const Auth = (props) => {
    return (
        <div>
            <div className="login-box">
                <div className="login-logo">
                    <a href="#"><b>MEDBUY</b> CRM</a>
                </div>
                <div className="card">
                    <div className="card-body login-card-body">
                        {/*<p className="login-box-msg">---</p>*/}
                        <form onSubmit={(event) => {
                            event.preventDefault();
                            props.getAuth(props.form.login, props.form.pass)
                        }}>
                            <div className="input-group mb-3">
                                <input type="text"
                                       className="form-control"
                                       value={props.form && props.form.login}
                                       onChange={(event) => props.changeValue('login', event.target.value)}
                                       required={'required'}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password"
                                       className="form-control"
                                       value={props.form && props.form.pass}
                                       onChange={(event) => props.changeValue('pass', event.target.value)}
                                       required={'required'}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    {/*<div className="icheck-primary">*/}
                                        {/*<input type="checkbox" id="remember">*/}
                                            {/*<label htmlFor="remember">*/}
                                                {/*Remember Me*/}
                                            {/*</label>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="col-4">
                                    <button type="submit" className="btn btn-primary btn-block">Ввійти</button>
                                </div>
                            </div>
                        </form>
                        {/*<p className="mb-1">*/}
                            {/*<a href="forgot-password.html">I forgot my password</a>*/}
                        {/*</p>*/}
                        {/*<p className="mb-0">*/}
                            {/*<a href="register.html" className="text-center">Register a new membership</a>*/}
                        {/*</p>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Auth;