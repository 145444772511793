import React, {useState} from 'react';
import {Link} from "react-router-dom";
import OrderChangeStatusContainer from "../OrderChangeStatusContainer";
import {useDispatch, useSelector} from 'react-redux';
import ImagePDF from './../../../image/pdf.png'
import {
    checkNPStatusThunk,
    checkPortmoneStatus,
    getOrderReceiptThunk,
    getOrderThunk
} from "../../../store/reducers/MainReducer";
import OrderDetailReceipt from "./OrderDetailReceipt";
import Modal from "../../Modal/Modal";
import WarehouseHistoryContainer from "../../Warehouses/WarehouseHistory/WarehouseHistoryContainer";



const OrderDetail = (props) => {
    const dispatch = useDispatch();
    const [openReceiptModal, toggleReceiptModal] = useState(false);
    const falseProducts = useSelector((state) => state.main.changeStatusProductsFalse);
    const user  = useSelector((state) => state.auth.user)
    const checkProductCountFree = (id) => {
        return falseProducts && falseProducts.filter(e => Number(e.id) === Number(id))[0] && falseProducts.filter(e => Number(e.id) === Number(id))[0].diff
    }

    const getNPLocation = (id) => {
        return props.directoryData && props.directoryData.np_warehouse && props.directoryData.np_warehouse.filter(e => Number(e.id) === Number(id))[0] && props.directoryData.np_warehouse.filter(e => Number(e.id) === Number(id))[0].name
    }
    return (
        <div className="content-wrapper">
            <section className="content">
                <div className="container-fluid">
                    {props.order &&
                    <div className={"product_container"}>
                        <h2 className={"main_page_title"}>
                            {props.getTranslate('crm_order_detail','order_number')}{props.order.id}
                        </h2>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{props.getTranslate('crm_main','main_info_title')}</h3>
                                <div className={"orders_detail_documents_container"}>
                                    {props.order && props.order.documents && props.order.documents.map(doc => {
                                        return <>

                                            <a target={"_blank"} href={doc.link}>
                                                <img alt={""} src={ImagePDF} /> {doc.type === "receipt" ? "Рахунок" : doc.type === "sales_invoice" ? "Накладна" : ""}
                                            </a>
                                        </>
                                    })}
                                    {user && Number(user.role_id) !== 3 && Number(user.role_id) !== 4 ? <button className={'btn btn-info'} onClick={() => toggleReceiptModal(true)}>Створити/змінити накладну</button> : '' }
                                    {user && Number(user.role_id) !== 3 && Number(user.role_id) !== 4  && props.order.status < 3 ?
                                        <Link className={"btn btn-info"} to={'/orders/edit/' + props.order.id} style={{ marginLeft: '15px'}}>
                                            <i className="far fa-edit"></i>
                                        </Link>
                                        : "" }
                                </div>
                                {/*Кнопка "Оновити статус ПН*/}
                                {props.order && props.order.ttn && props.order.ttn.length > 0 ?
                                    <div className={"orders_detail_button_container"}>
                                        <button className={"btn btn-warning"}
                                        onClick={() => {dispatch(checkNPStatusThunk(props.order.ttn))}}
                                        >
                                            {props.getTranslate('crm_order_detail', 'button_check_status_np')}
                                        </button>
                                    </div> : ""
                                }
                                {/*Кнопка "Оновити статус Portmone*/}
                                {props.order && props.order?.card_type && props.order?.card_type === 'PORTMONE' && props.order?.status < 3 ?
                                    <div className={"orders_detail_button_container"}>
                                        <button className={"btn btn-warning"}
                                                onClick={() => {dispatch(checkPortmoneStatus(props.order.id))}}
                                        >
                                            {props.getTranslate('crm_order_detail', 'check_portmone')}
                                        </button>
                                    </div> : ""
                                }

                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">ID</span>
                                                <span
                                                    className="info-box-number text-center text-muted mb-0">{props.order.id}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span
                                                    className="info-box-text text-center text-muted">{props.getTranslate('crm_order_detail','order_date_create')}</span>
                                                <span
                                                    className="info-box-number text-center text-muted mb-0">{props.order.date}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">{props.getTranslate('crm_order_detail','order_payment_title')}</span>
                                                <span className="info-box-number text-center text-muted mb-0">
                                                    {props.order.payment && Number(props.order.payment) === 1 && props.getTranslate('crm_order_detail','order_payment_invoice')}
                                                    {props.order.payment && Number(props.order.payment) === 2 && props.getTranslate('crm_order_detail','order_payment_card')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">{props.getTranslate('crm_order_detail','order_sum')}</span>
                                                <span
                                                    className="info-box-number text-center text-muted mb-0">{props.order.total}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">{props.getTranslate('crm_main','status')}</span>
                                                <span className={"status_center"}><OrderChangeStatusContainer status_id={props.order.status} order={props.order.id} order_data={props.order}/></span>
                                            </div>
                                        </div>
                                    </div>
                                    {props.order && props.order.ttn && props.order.ttn.length > 0 ?
                                        <div className="col-12 col-sm-3">
                                            <div className="info-box bg-light">
                                                <div className="info-box-content">
                                                    <span className="info-box-text text-center text-muted">{props.getTranslate('crm_main','ttn')}</span>
                                                    <span className={"status_center"}>{props.order.ttn}</span>
                                                </div>
                                            </div>
                                        </div>
                                    : ""}
                                    {props.ttn && props.ttn.statusName ?
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">{props.getTranslate('crm_main','ttn')}</span>
                                                <span className={"status_center"}>{props.ttn.statusName}</span>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                                </div>
                            </div>
                            <div className="card-header">
                                <h3 className="card-title">{props.getTranslate('crm_main','order_detail_customer_info_title')}</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span
                                                    className="info-box-text text-center text-muted">{props.getTranslate('crm_order_detail','order_client_id')} (id {props.order.id_customer})</span>
                                                <span
                                                    className="info-box-number text-center text-muted mb-0"><a href={"customer/"+props.order.id_customer}> {props.order.customer_surname} {props.order.customer_name} {props.order.customer_middle_name}</a></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">{props.getTranslate('crm_main','email')}</span>
                                                <span className={"status_center"}>{props.order.customer_email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">{props.getTranslate('crm_main','phone')}</span>
                                                <span className={"status_center"}>{props.order.customer_phone}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {props.order.customer && props.order.customer[0] && props.order.customer[0]['discount'] ?
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">{props.getTranslate('crm_main','discount')}</span>
                                                <span className={"status_center"}>{props.order.customer && props.order.customer[0] && props.order.customer[0]['discount']*100}%</span>
                                            </div>
                                        </div>
                                    </div>
                                        : ""}
                                </div>
                                <div className="row">
                                    {props.order && props.order.comment.length > 1 ?
                                    <div className="col-12 col-sm-12">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">{props.getTranslate('crm_order_detail','order_comment')}</span>
                                                <span className={"status_center"}>{props.order.comment}</span>
                                            </div>
                                        </div>
                                    </div>
                                        : ""}
                                    <div className="col-12 col-sm-12">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">{props.getTranslate('crm_order_detail','order_address')}</span>
                                                <span className={"status_center"}>
                                                    {props.directoryData && props.directoryData.regions && props.directoryData.regions.filter(c => {
                                                    return Number(c.id) === Number(props.order.region)
                                                    })[0].name } обл.,
                                                    м. {props.directoryData && props.directoryData.cities && props.directoryData.cities.filter(c => {
                                                        return Number(c.id) === Number(props.order.city)
                                                    })[0] && props.directoryData.cities.filter(c => {
                                                        return Number(c.id) === Number(props.order.city)
                                                    })[0].name + " "}
                                                    {
                                                        props.order.np_warehouse > 0 ? getNPLocation(props.order.np_warehouse)
                                                            : ", вул. "+props.order.street+", № "+props.order.street_house}
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{props.getTranslate('crm_order_detail','order_detail_products')}</h3>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                <th>ID</th>
                                <th>{props.getTranslate('crm_main','name')}</th>
                                <th>{props.getTranslate('crm_main','model')}</th>
                                <th>{props.getTranslate('crm_main','price')}</th>
                                <th>{props.getTranslate('crm_main','price_one')}</th>
                                <th>{props.getTranslate('crm_main','count')}</th>
                                </thead>
                                <tbody>
                                {props.order.products && props.order.products.map(e => {
                                    return <>
                                        <tr>
                                            <td>{e.id}</td>
                                            <td>{e.name}</td>
                                            <td>{e.model}</td>
                                            <td>{e.price}</td>
                                            <td>{e.price_one}</td>
                                            <td>{e.count}</td>
                                        </tr>
                                        {checkProductCountFree(e.id) ?
                                        <tr>
                                            <td colSpan={6}>
                                                <b className={'text-danger'}>На складі недостатньо товару "{e.name} ({e.model})" в кількості: {checkProductCountFree(e.id)}</b>
                                            </td>
                                        </tr> : ""
                                        }
                                    </>
                                })}
                                </tbody>
                            </table>
                        </div>
                        <WarehouseHistoryContainer withWarehouse={true}
                                                   withoutPagination = {true}
                                                   withoutFilters = {true}
                                                   params={{
                                                    order: props.order.id
                                                   }}
                        />
                    </div>
                    }
                </div>
            </section>
            <Modal title={'Створити/змінити накладну'} isOpen={openReceiptModal} close={() => toggleReceiptModal(false)} body={<OrderDetailReceipt order_id={props.order && props.order.id} close={() => toggleReceiptModal(false)}/>} />
        </div>
    );
}

export default OrderDetail;
