import React from 'react';
import PageTitle from "../../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './WarehousesForm.css';
import Select from "react-select";

const WarehousesForm = (props) => {

    return (
        <div className="content-wrapper">
            <PageTitle title={'warehouses_form'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header"><h3
                            className="card-title">{props.getTranslate('crm_form_add', 'form_add_main_info_title')}</h3>
                        </div>
                        <form onSubmit={(event) => {
                            event.preventDefault();
                            if(props.isEdit) {
                                props.editWarehouse({...props.form})
                            } else {
                                props.createWarehouses({...props.form});
                            }
                        }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label
                                                    htmlFor={"WarehousesName"}>{props.getTranslate('crm_form_add', 'form_add_title')}:</label>
                                                <input className={'form-control'}
                                                       id={"WarehousesName"}
                                                       value={props.form && props.form.name}
                                                       onChange={(event) => props.changeValue('name', event.target.value)}
                                                       required
                                                       placeholder={props.getTranslate('crm_form_add', 'form_add_title')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label
                                                    htmlFor={"WarehousesPhone"}>{props.getTranslate('crm_form_add', 'phone')}:</label>
                                                <input className={'form-control'}
                                                       id={"WarehousesPhone"}
                                                       value={props.form && props.form.phone}
                                                       onChange={(event) => props.changeValue('phone', event.target.value)}
                                                       required
                                                       placeholder={props.getTranslate('crm_form_add', 'phone')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label
                                                    htmlFor={"WarehousesAddress"}>{props.getTranslate('crm_form_add', 'form_add_address')}:</label>
                                                <input className={'form-control'}
                                                          id={"WarehousesAddress"}
                                                          value={props.form && props.form.address}
                                                          onChange={(event) => props.changeValue('address', event.target.value)}
                                                          required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label
                                                    htmlFor={"WarehousesPerson"}>{props.getTranslate('crm_form_add', 'form_add_person')}:</label>
                                                <input className={'form-control'}
                                                       id={"WarehousesPerson"}
                                                       value={props.form && props.form.person}
                                                       onChange={(event) => props.changeValue('person', event.target.value)}
                                                       required
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-group mb-3 form_block_input">
                                                <label
                                                    htmlFor={"WarehousesSelfPickup"}>{props.getTranslate('crm_form_add', 'form_add_self_pickup')}:</label>
                                                <input className={'form-control'}
                                                       id={"WarehousesSelfPickup"}
                                                       type={"checkbox"}
                                                       value={props.form && props.form.self_pickup}
                                                       onChange={(event) => props.changeValue('self_pickup', event.target.checked)}
                                                       checked={props.form && Number(props.form.self_pickup) ? 'checked' : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button
                                        className={"btn btn-success"}
                                        type={'submit'}
                                    >{props.getTranslate('crm_form_add', 'form_add_button_create')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default WarehousesForm;