import React from 'react';
import SellManagers from "./SellManagers";
import {connect} from "react-redux";
import './SellManagers.css';
import ApiService from "../../api/api";
import {getSellManagersThunk} from "../../store/reducers/MainReducer";
import {withLang} from "../HOC/withLang/withLang";
import {compose} from "redux";



class SellManagersContainer extends React.Component {

    apiService = new ApiService();

    constructor(props){
        super(props);
        this.deleteSellManager = this.deleteSellManager.bind(this)
    }

    deleteSellManager(id)  {
        this.apiService.deleteSellManager(id)
            .then(res => {
                this.props.getSellManagersThunk()
            })
    }

    componentDidMount() {
        this.props.getSellManagersThunk()
    }

    render() {
        return <SellManagers
            sell_managers={this.props.sell_managers}
            getTranslate={this.props.getTranslate}
            deleteSellManager={this.deleteSellManager}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getSellManagersThunk: () => dispatch(getSellManagersThunk())
    }
}

let mapStateToProps = (state) => {
    return {
        sell_managers: state.main.sell_managers
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(SellManagersContainer);