import React from 'react';
import {connect} from "react-redux";
import './TagsForm.css';
import {withLang} from "../../HOC/withLang/withLang";
import {compose} from "redux";
import TagsForm from "./TagsForm";
import {
    changeTagsFormValueAC,
    createTagsThunk, editTagThunk,
    getAttributeEditThunk,
    getTagEditThunk
} from "../../../store/reducers/MainReducer";



class SellManagersFormContainer extends React.Component {

    constructor(props){
        super(props);

    }

    componentDidMount() {
        let name = [];
        this.props.languages && this.props.languages.forEach(e => {
            name.push({
                lang: e.id,
                name: ''
            })
        });
        this.props.changeValue('name', name);
        if(this.props.isEdit) {
            this.props.getEitData(this.props.uri.match.params.id, this.props.languages);
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.languages && !this.props.languages) {
            let name = [];
            nextProps.languages && nextProps.languages.forEach(e => {
                name.push({
                    lang: e.id,
                    name: ''
                })
            });
            nextProps.changeValue('name', name);
            if(nextProps.isEdit) {
                nextProps.getEitData(nextProps.uri.match.params.id, nextProps.languages);
            }
        }
    }

    render() {
        return <TagsForm getTranslate={this.props.getTranslate}
                                 changeValue={this.props.changeValue}
                                 createSellManagers={this.props.createSellManagers}
                                 form={this.props.form}
                         languages={this.props.languages}
                         createTags={this.props.createTags}
                         editTag={this.props.editTag}
                         isEdit={this.props.isEdit}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeTagsFormValueAC(input, value)),
        createTags: (tags) => dispatch(createTagsThunk(tags)),
        editTag: (tags) => dispatch(editTagThunk(tags)),
        getEitData: (id, languages) => dispatch(getTagEditThunk(id, languages))
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.main.tagsForm,
        languages: state.main.languages
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(SellManagersFormContainer);
