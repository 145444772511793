import React from 'react';
import {Link} from "react-router-dom";

const PageTitle = (props) => {

    const getTree = (page, isTitle = false) => {
        const tree = {
            dashboard: {
                name: 'Головна',
                link: '/dashboard'
            },
            products: {
                name: 'Товари',
                link: '/products'
            },
            orders: {
                name: 'Замовлення',
                link: '/orders'
            },
            products_add: {
                name: 'Додавання товару',
                link: '/products/add'
            },
            products_edit: {
                name: 'Редагування товару',
                link: '/products/edit'
            },
            manufacturers: {
                name: 'Виробники',
                link: '/manufacturers'
            },
            customers: {
                name: 'Клієнти',
                link: '/customers'
            },
            categories: {
                name: 'Категорії',
                link: '/categories'
            },
            manufacturer_add: {
                name: 'Додавання Виробника',
                link: '/manufacturer/add'
            },
            partners_tests: {
                name: 'Тестування партнерів',
                link: '/partners_tests'
            },
            sliders: {
                name: 'Слайдер на головній',
                link: '/sliders'
            },
            category_form: {
                name: 'Додавання категорії',
                link: '/categories/add'
            }
            ,sell_managers: {
                name: 'Менеджери з продажів',
                link: '/sell_managers'
            },
            sell_managers_form: {
                name: 'Менеджери з продажів',
                link: '/sell_managers/add'
            },
            order_data_edit: {
                name: 'Редагування замовлення',
                link: '/orders'
            },
            tags: {
                name: 'Теги',
                link: '/tags'
            },
            tags_form: {
                name: 'Теги',
                link: '/tags/add'
            },
            attributes: {
                name: 'Атрибути',
                link: '/attributes'
            },
            warehouses: {
                name: 'Склади',
                link: '/warehouses'
            },
            warehouses_reserved: {
                name: 'Зарезервовані товари',
                link: '/warehouses_reserved'
            },
            warehouse_history: {
                name: 'Рух товарів',
                link: '/warehouses/history'
            },
            warehouse_receiving: {
                name: 'Надходження',
                link: '/warehouses/receiving'
            },
            warehouse_write_off: {
                name: 'Списання',
                link: '/warehouses/write_off'
            },
            warehouse_moving: {
                name: 'Переміщення',
                link: '/warehouses/moving'
            },
            warehouses_form: {
                name: 'Дані Складу',
                link: '/warehouses/add'
            },
            warehouses_form_invoice: {
                name: 'Надходження на склад',
                link: '/warehouses/invoice'
            },
            analytics: {
                name: 'Аналітика',
                link: '/analytics'
            },
            attribute_form: {
                name: 'Атрибути',
                link: '/attributes/add'
            },
            customer_bonus: {
                name: 'Виплата бонусів',
                link: '/customer_bonus'
            },

        }

        if(isTitle) {
            return tree[page].name;
        }
        let breadcrumps = tree[page] && tree[page].parent ? tree[page].parent.map(e => {
            return <li className="breadcrumb-item"><Link to={tree[e].link}>{tree[e].name}</Link></li>
        }) : [];

        return [
            ...breadcrumps,
            <li className="breadcrumb-item active">{tree[page].name}</li>
        ]
    }

    return(
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">{getTree(props.title, true)}</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to={'/dashboard'}>Головна</Link></li>
                            {getTree(props.title)}
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageTitle;
