import React from 'react';
export const OrdersManagersToggler = ({ selected, onChange }) => {

    return (
        <div className={'row'}>
            <div className="col-md-4 offset-md-8 orders_managers_toggler_container">
                <div className="form-group custom_form_checkbox orders_managers_toggler">
                    <label
                        htmlFor="FormName">Режим встановлення менеджера</label>
                    <input className={'form-control'}
                           value={selected}
                           checked={selected ? 'checked' : ''}
                           onChange={(event) => onChange(event.target.checked ? 1 : 0)}
                           type={'checkbox'}
                    />
                </div>
            </div>
        </div>
    );
}
