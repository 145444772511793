import React from 'react';
import Attributes from "./Attributes";
import {connect} from "react-redux";
import './Attributes.css';
import {
    deleteAttributeThunk,
    getAttributesThunk,
    toggleAttributesActiveDataAC,
} from "../../store/reducers/MainReducer";
import {withLang} from "../HOC/withLang/withLang";
import {compose} from "redux";



class AttributesContainer extends React.Component {

    constructor(props){
        super(props);

    }


    componentDidMount() {
        this.props.getAttributesThunk()
    }

    render() {
        return <Attributes attributes={this.props.attributes}
                           getTranslate={this.props.getTranslate}
                           attributesOpen={this.props.attributesOpen}
                           toggleActive={this.props.toggleActive}
                           deleteAttribute={this.props.deleteAttribute}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        toggleActive: (id) =>  dispatch(toggleAttributesActiveDataAC(id)),
        getAttributesThunk: () => dispatch(getAttributesThunk()),
        deleteAttribute: (id) =>  dispatch(deleteAttributeThunk(id))
    }
}

let mapStateToProps = (state) => {
    return {
        attributes: state.main.attributes,
        attributesOpen: state.main.attributesOpen
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(AttributesContainer);