import React, {useState} from 'react';
import {Link} from "react-router-dom";
import WarehouseHistoryContainer from "../WarehouseHistory/WarehouseHistoryContainer";
import { excelProductsInStock } from "../../../store/reducers/MainReducer";
import {useDispatch} from "react-redux";

const WarehouseDetail = (props) => {
    const dispatch = useDispatch();
    return (
        <div className="content-wrapper">
            <section className="content">
                <div className="container-fluid">
                    {props.warehouse ?
                        <div className={"product_container"}>
                            <h2 className={"main_page_title"}>
                                Назва складу: {props.warehouse.name}
                            </h2>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-sm-3">
                                            <div className="info-box bg-light">
                                                <div className="info-box-content">
                                                    <span className="info-box-text text-center text-muted">ID</span>
                                                    <span
                                                        className="info-box-number text-center text-muted mb-0">{props.warehouse.id}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <div className="info-box bg-light">
                                                <div className="info-box-content">
                                                <span
                                                    className="info-box-text text-center text-muted">{props.getTranslate('crm_main','name_person')}</span>
                                                    <span
                                                        className="info-box-number text-center text-muted mb-0">{props.warehouse.person}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <div className="info-box bg-light">
                                                <div className="info-box-content">
                                                    <span
                                                        className="info-box-text text-center text-muted">{props.getTranslate('crm_main', 'phone')}</span>
                                                    <span className="info-box-number text-center text-muted mb-0">{props.warehouse.phone}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <div className="info-box bg-light">
                                                <div className="info-box-content">
                                                    <span
                                                        className="info-box-text text-center text-muted">{props.getTranslate('crm_main', 'address')}</span>
                                                    <span className="info-box-number text-center text-muted mb-0">{props.warehouse.address}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <button className="btn btn-success excel_button_in_analytics"
                                                    onClick={() => dispatch(excelProductsInStock())}>
                                                Excel (В наявності)
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-primary card-outline card-tabs">
                                <div className="card-header p-0 pt-1 border-bottom-0 main_style_tabs_container">
                                    <ul className="nav nav-tabs main_style_tabs" role="tablist">
                                        <li className="nav-item" onClick={() => props.setActiveTab(1)}>
                                            <a className={'nav-link' + (props.activeTab === 1 ? ' active' : '')}>{props.getTranslate('crm_warehouse', 'warehouse_balance')}</a>
                                        </li>
                                        <li className="nav-item" onClick={() => props.setActiveTab(2)}>
                                            <a className={'nav-link' + (props.activeTab === 2 ? ' active' : '')}>{props.getTranslate('crm_warehouse','warehouse_history')}</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content" id="custom-tabs-three-tabContent">
                                        <div className={'tab-pane fade' + (props.activeTab === 1 ? ' show active' : '')} >
                                            <div className="card-body p-0">
                                                {props.warehouse.products ?
                                                    <>
                                                        <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>Пошук</label>
                                                                    <input type="text"
                                                                           className={'form-control'}
                                                                           value={props.searchInput}
                                                                           onChange={(event) => props.searchInputChange(event.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        <div className="card container_for_scroll">
                                                            <table className="table table-striped">
                                                                <thead>
                                                                <th>ID</th>
                                                                <th>{props.getTranslate('crm_main', 'name')}</th>
                                                                <th>{props.getTranslate('crm_main', 'model')}</th>
                                                                <th>{props.getTranslate('crm_main', 'count')}</th>
                                                                <th>{props.getTranslate('crm_main', 'count_retail')}</th>
                                                                <th>{props.getTranslate('crm_main', 'count_reserved')}</th>
                                                                <th>{props.getTranslate('crm_main', 'count_free')}</th>
                                                                </thead>
                                                                <tbody>
                                                                {props.warehouse && props.warehouse.products && props.warehouse.products.map(p => {
                                                                    const retail = props.warehouse.products_retail && props.warehouse.products_retail.filter(c => {
                                                                        return Number(c.id) === Number(p.id)
                                                                    })[0];
                                                                    const reserved = props.warehouse.products_reserved && props.warehouse.products_reserved.filter(c => {
                                                                        return Number(c.id) === Number(p.id)
                                                                    })[0];
                                                                    return <>
                                                                        {Number(p.count) > 0 || reserved && reserved.count > 0 ?
                                                                            <tr>
                                                                                <td>{p.id}</td>
                                                                                <td><a href={p.alias}>{p.name}</a></td>
                                                                                <td>{p.model}</td>
                                                                                <td>{p.count}</td>
                                                                                <td>{retail && retail.count}</td>
                                                                                <td>{reserved && reserved.count}</td>
                                                                                <td>{reserved && p.count && p.count - reserved.count}</td>
                                                                            </tr> : ""
                                                                        }
                                                                    </>
                                                                })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className={'tab-pane fade' + (props.activeTab === 2 ? ' show active' : '')} >
                                            <div className="card-body p-0">
                                                <WarehouseHistoryContainer params={{
                                                    warehouse: props.warehouse.id
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    : ""}
                </div>
            </section>
        </div>
    );
}

export default WarehouseDetail;