import React from 'react';
import {connect} from "react-redux";
import './ManufacturerAdd.css';
import {withLang} from "../../HOC/withLang/withLang";
import {compose} from "redux";
import ManufacturerAdd from "./ManufacturerAdd";
import {
    changeManufacturerFormValueAC,
    createManufacturerThunk,
    editCategoryThunk, editManufacturerThunk, getCategoryEditThunk, getManufacturerEditThunk
} from "../../../store/reducers/MainReducer";



class ManufacturerAddContainer extends React.Component {

    constructor(props){
        super(props);

    }


    componentDidMount() {
        if(this.props.isEdit) {
            this.props.getEitData(this.props.uri.match.params.id, this.props.languages);
        }
    }

    render() {
        return <ManufacturerAdd getTranslate={this.props.getTranslate}
                                changeValue={this.props.changeValue}
                                createManufacturer={this.props.createManufacturer}
                                editManufacturer={this.props.editManufacturer}
                                form={this.props.form}
                                isEdit={this.props.isEdit}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeManufacturerFormValueAC(input, value)),
        createManufacturer: (manufacturer) => dispatch(createManufacturerThunk(manufacturer)),
        editManufacturer: (category) => dispatch(editManufacturerThunk(category)),
        getEitData: (id, languages) => dispatch(getManufacturerEditThunk(id, languages))
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.main.manufacturerForm
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(ManufacturerAddContainer);
