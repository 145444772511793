import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {connect, useDispatch, useSelector} from "react-redux";
import {withLang} from "../../../HOC/withLang/withLang";
import {getWaherousesActionDataThunk} from "../../../../store/reducers/MainReducer";
import WarehouseHistoryContainer from "../../WarehouseHistory/WarehouseHistoryContainer";
import {Pagination, PaginationContainer} from "../../../Pagination/Pagination";

const WarehouseAction = ({getTranslate, type}) => {

    const dispatch = useDispatch();
    const [page, setPage] = useState(1)

    const actions = useSelector((state) => state.main.warehousesAction);
    const actionsCount = useSelector((state) => state.main.warehousesActionCount);
    const changePage = (newPage, pageSize) => {
        setPage(newPage);
        dispatch(getWaherousesActionDataThunk(type, newPage, pageSize))
    }

    useEffect(() => {
        dispatch(getWaherousesActionDataThunk(type, 1 , 10))
    }, [type]);

    const [isOpen, toggleOpen] = useState(null);

    return <>
        <div className="warehouse_action_table_container">
        <table className={'table'}>
            <thead>
                <th>ID</th>
                <th>{getTranslate('crm_main','title_th_person')}</th>
                <th>{getTranslate('crm_main','title_th_comment')}</th>
                <th>{getTranslate('crm_main','date')}</th>
                <th></th>
            </thead>
            <tbody>
                {actions.map(e => {
                    return <>
                        <tr>
                            <td>{e.id}</td>
                            <td>{e.user && e.user.name}</td>
                            <td>{e.comment}</td>
                            <td>{e.date}</td>
                            <td><
                                i className="fas fa-angle-down"
                                  onClick={() => toggleOpen(Number(isOpen) === Number(e.id) ? null : e.id)}>
                                </i>
                            </td>
                        </tr>
                        {Number(isOpen) === Number(e.id)  ?
                            <tr>
                                <td colSpan={5}>
                                    <WarehouseHistoryContainer withWarehouse={true}
                                                               params={{
                                                                   history_type: type,
                                                                   history_id: e.id
                                                               }}
                                    />
                                </td>
                            </tr>
                        : ""}
                    </>
                })}

            </tbody>
        </table>
    </div>
        <div className={"ant_pagination_warehouse_action"}>
            <PaginationContainer total={actionsCount} onChange={changePage} page={page} />
        </div>
    </>;
}

export default compose(
    withLang
)(WarehouseAction);