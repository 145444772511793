import ApiService from '../../api/api.js';

const apiService = new ApiService();

const TOGGLE_SIDBAR_VIEW = 'TOGGLE_SIDBAR_VIEW';
const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
const GET_PRODUCT = 'GET_PRODUCT';
const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
const GET_MANUFACTURERS = 'GET_MANUFACTURERS';
const GET_PRODUCERS = 'GET_PRODUCERS';
const GET_CUSTOMERS_BONUS = 'GET_CUSTOMERS_BONUS';
const GET_ATTRIBUTES = 'GET_ATTRIBUTES';
const GET_ALL_PARTNERS_TESTS = 'GET_ALL_PARTNERS_TESTS';
const GET_TAGS = 'GET_TAGS';
const GET_SELL_MANAGERS = 'GET_SELL_MANAGERS';
const GET_RELATED_PRODUCTS = 'GET_RELATED_PRODUCTS';
const GET_WAREHOUSES = 'GET_WAREHOUSES';
const GET_WAREHOUSES_RESERVED = 'GET_WAREHOUSES_RESERVED';
const GET_CATEGORIES = 'GET_CATEGORIES';
const GET_ORDER = 'GET_ORDER';
const GET_WAREHOUSE = 'GET_WAREHOUSE';
const SET_PRODUCTS_ADD_ACTIVE_TAB = 'SET_PRODUCTS_ADD_ACTIVE_TAB';
const SET_ORDERS_ACTIVE_TAB = 'SET_ORDERS_ACTIVE_TAB';
const SET_WAREHOUSE_ACTIVE_TAB = 'SET_WAREHOUSE_ACTIVE_TAB';
const SET_ORDERS_ACTIVE_TAB_PARTNER_SALE = 'SET_ORDERS_ACTIVE_TAB_PARTNER_SALE';
const TOGGLE_MENU_ITEM_OPEN = 'TOGGLE_MENU_ITEM_OPEN';
const GET_LANGUAGES_DATA = 'GET_LANGUAGES_DATA';
const GET_DIRECTORY_DATA = 'GET_DIRECTORY_DATA';
const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS';
const GET_CUSTOMER = 'GET_CUSTOMER';
const TOGGLE_CATEGORY_ACTIVE = 'TOGGLE_CATEGORY_ACTIVE';
const TOGGLE_ATTRIBUTES_ACTIVE = 'TOGGLE_ATTRIBUTES_ACTIVE';
const TOGGLE_ACTIVE_LANG_TAB = 'TOGGLE_ACTIVE_LANG_TAB';
const CHANGE_MANUFACTURER_ADD_FORM = 'CHANGE_MANUFACTURER_ADD_FORM';
const CHANGE_CATEGORY_ADD_FORM = 'CHANGE_CATEGORY_ADD_FORM';
const ALERT_SHOW_IN_PROJECT = 'ALERT_SHOW_IN_PROJECT';
const CHANGE_ATTRIBUTE_ADD_FORM = 'CHANGE_ATTRIBUTE_ADD_FORM';
const CHANGE_SELL_MANAGERS_ADD_FORM = 'CHANGE_SELL_MANAGERS_ADD_FORM';
const CHANGE_ORDER_FILTERS_FORM_DATA = 'CHANGE_ORDER_FILTERS_FORM_DATA';
const CHANGE_PARTNERS_TESTS_FILTERS_FORM_DATA = 'CHANGE_PARTNERS_TESTS_FILTERS_FORM_DATA';
const CHANGE_WAREHOUSE_HISTORY_FILTERS_FORM_DATA = 'CHANGE_WAREHOUSE_HISTORY_FILTERS_FORM_DATA';
const CHANGE_WAREHOUSES_ADD_FORM = 'CHANGE_WAREHOUSES_ADD_FORM';
const CHANGE_RELATED_PRODUCTS = 'CHANGE_RELATED_PRODUCTS';
const CHANGE_WAREHOUSES_INVOICE_ADD_FORM = 'CHANGE_WAREHOUSES_INVOICE_ADD_FORM';
const CHANGE_WAREHOUSES_MOVING_ADD_FORM = 'CHANGE_WAREHOUSES_MOVING_ADD_FORM';
const CHANGE_WAREHOUSES_WRITE_OFF_ADD_FORM = 'CHANGE_WAREHOUSES_WRITE_OFF_ADD_FORM';
const CHANGE_TAGS_ADD_FORM = 'CHANGE_TAGS_ADD_FORM';
const CHANGE_PRODUCTS_ADD_FORM = 'CHANGE_PRODUCTS_ADD_FORM';
const CHANGE_CUSTOMERS_FILTERS_VALUE = 'CHANGE_CUSTOMERS_FILTERS_VALUE';
const CLEAN_PRODUCTS_ADD_FORM = 'CLEAN_PRODUCTS_ADD_FORM';
const CLEAN_SELL_MANAGER_ADD_FORM = 'CLEAN_SELL_MANAGER_ADD_FORM';
const TOGGLE_MODAL_UPLOAD_FILE_ACTIVE = 'TOGGLE_MODAL_UPLOAD_FILE_ACTIVE';
const TOGGLE_MODAL_UPLOAD_IMAGE_ACTIVE = 'TOGGLE_MODAL_UPLOAD_IMAGE_ACTIVE';
const SET_PRODUCT_EDIT_DATA = 'SET_PRODUCT_EDIT_DATA';
const SET_ATTRIBUTE_EDIT_DATA = 'SET_ATTRIBUTE_EDIT_DATA';
const SET_TAG_EDIT_DATA = 'SET_TAG_EDIT_DATA';
const SET_CATEGORY_EDIT_DATA = 'SET_CATEGORY_EDIT_DATA';
const SET_MANUFACTURER_EDIT_DATA = 'SET_MANUFACTURER_EDIT_DATA';
const TOGGLE_ORDER_CUSTOMER_ACTIVE = 'TOGGLE_ORDER_CUSTOMER_ACTIVE';
const SET_EDIT_WAREHOUSE_FORM_DATA = 'SET_EDIT_WAREHOUSE_FORM_DATA';
const CHANGE_LOADER_STATUS = 'CHANGE_LOADER_STATUS';
const CHANGE_LOADER_MESSAGE = 'CHANGE_LOADER_MESSAGE';
const CHANGE_PRODUCTS_FILTERS_VALUE = 'CHANGE_PRODUCTS_FILTERS_VALUE';
const CHANGE_PRODUCTS_PAGINATION_FORM_VALUE = 'CHANGE_PRODUCTS_PAGINATION_FORM_VALUE';
const SET_WAREHOUSES_ACTIONS_DATA = 'SET_WAREHOUSES_ACTIONS_DATA';
const SET_ORDER_EDIT_DATA = 'SET_ORDER_EDIT_DATA';
const SET_ORDER_EDIT_DATA_DATABASE = 'SET_ORDER_EDIT_DATA_DATABASE';
const CHANGE_ORDER_DETAIL_EDIT_DATA_VALUE = 'CHANGE_ORDER_DETAIL_EDIT_DATA_VALUE';
const SET_CHANGE_PRODUCTS_FALSE_STATUS_DATA = 'SET_CHANGE_PRODUCTS_FALSE_STATUS_DATA';
const CHECK_STATUS_NP = 'CHECK_STATUS_NP';
const SET_SELL_MANAGER_EDIT_DATA = 'SET_SELL_MANAGER_EDIT_DATA';
const DASHBOARD_DATA = 'DASHBOARD_DATA';
const GET_COUNTER_STATUS_ORDERS = 'GET_COUNTER_STATUS_ORDERS';

let initialState = {
    products_type: [
        {
            id: 1,
            value: "Упаковка"
        },
        {
            id: 2,
            value: "Роздріб"
        }
    ],
    warehouse_action_type: [
        {
            id: 1,
            value: "Зарахування"
        },
        {
            id: 2,
            value: "Списання"
        },
        {
            id: 3,
            value: "Переміщення"
        }
    ],
    alert: {
        active: false,
        message: null,
        type_message: null
    },
    sidebarCollapse: false,
    dashboard: [],
    products: [],
    productCount: null,
    productDetail: null,
    orders: [],
    partnersTests: [],
    partnersTestsCount: null,
    manufacturers: [],
    customers_bonus: [],
    sell_managers: [],
    related_products: [],
    counter_status_data: [],
    tags: [],
    categories: [],
    directoryData: [],
    orderEditData: {
        name: null,
        surname: null,
        middle_name: null,
        email: null,
        phone: null,
        city: null,
        street: null,
        street_home: null,
        region: null,
        district: null,
        comment: null,
        registerMe: null,
        accept: null,
        password: null,
        password_check: null,
        payment: 1,
        delivery: 2,
        companyPayment: true,
        company_name: null,
        company_code: null,
        company_address: null,
        company_number_pdv: null,
        asPartner: false,
        promocode: null,
        promocodeDiscount: null
    },
    orderEditDataDatabase: {},
    categoryOpen: 0,
    attributeOpen: 0,
    orderDetail: null,
    orderDetailTTN: {
        statusName: null,
        statusCode: null
    },
    menuActive: null,
    activeLang: 'UA',
    productionTaskTab: 1,
    productionTaskTabLang: 'UA',
    productsFilters: {
        sort: null,
        search: ''
    },
    customersFilters: {
        franchisees: false,
        partner: false,
        name: ''
    },
    pagination: {
        products: {
            page: 1,
            limit: 15
        },
        orders: {
            page: 1,
            limit: 15
        },
        warehouse_history: {
            page: 1,
            limit: 15
        },
        customers: {
            page: 1,
            limit: 15
        },
        partners_tests: {
            page: 1,
            limit: 15
        },
    },
    manufacturerForm: {
        name: ''
    },
    categoryForm: {
        name: '',
        parent: 0
    },
    attributeForm: {
        name: [],
        values: [],
        type: 1
    },
    SellManagersForm: {
        name: '',
        phone: '',
        promo_code: '',
        description: '',
        city: null,
        region: null,
        customer: null,
        date_active: '',
        discount: ''
    },
    WarehousesForm: {
        name: null,
        phone: null,
        address: null,
        person: null,
        self_pickup: null,
    },
    WarehousesInvoiceForm: {
        products: [],
        warehouse: null,
        comment: null,
    },
    WarehousesWriteOffForm: {
        products: [],
        warehouse: null,
        comment: null,
    },
    WarehousesMovingForm: {
        products: [],
        warehouse_to: null,
        warehouse_from: null,
        comment: null,
    },
    warehousesAction: [],
    changeStatusProductsFalse: [],
    tagsForm: {
        name: '',
        alias: '',
    },
    loader: {
        active: false,
        message: null,
        count: 0
    },
    productsAddForm: {
        name: [],
        long_name: [],
        analyte: [],
        description: [],
        seo_name: [],
        seo_description: [],
        seo_keywords: [],
        model: null,
        alias: null,
        manufacturer: null,
        price: null,
        price_one: null,
        currency: 1,
        preorder: 0,
        new_product: 0,
        in_pack: 0,
        pdv: null,
        discount: null,
        partner_access: 0,
        active: 0,
        firm: 1,
        attribute: [],
        category: [],
        tags: [],
        recommend_products: [],
        image: [],
        documents: [],
        categorySelector: null,
        tagSelector: null,
        attributeSelector: null,
        attributeValue: null,
    },
    modalUploadOpen: false,
    modalUploadDocumentOpen: false,
    ordersTab: 1,
    orderFiltersForm: {
        status: '',
        payment_object: '',
        date_from: '',
        date_to: '',
        id_customer: '',
        manager_id: '',
        id: '',
        sort: '',
    },
    partnersTestsFiltersForm: {
        date_from: '',
        date_to: '',
        id_customer: '',
    },
    warehouseHistoryFiltersForm: {
        date_from: '',
        date_to: '',
        product_name: '',
    },
    warehouses_reserved: [],
    warehouseTab: 1,
    ordersTabPartnerSale: 1,
    customers: [],
    customersCount: 0,
    customerDetail: [],
    orderPartnerOpen: 0,
};

const AccountReducer = (state = initialState, action) => {
    switch(action.type) {
        case TOGGLE_SIDBAR_VIEW:
            return {
                ...state,
                sidebarCollapse: action.view
            }
        case GET_ALL_PRODUCTS:
            return {
                ...state,
                products: action.products.data,
                productsCount: action.products.count
            }
        case GET_ALL_ORDERS:
            return {
                ...state,
                orders: action.orders,
                ordersCount: action.count
            }
        case GET_ALL_PARTNERS_TESTS:
            return {
                ...state,
                partnersTests: action.tests,
                partnersTestsCount: action.count
            }
        case SET_CHANGE_PRODUCTS_FALSE_STATUS_DATA:
            return {
                ...state,
                changeStatusProductsFalse: action.products
            }
        case CHANGE_MANUFACTURER_ADD_FORM:
            let manufacturerForm = {...state.manufacturerForm};
            manufacturerForm[action.input] = action.value;
            return {
                ...state,
                manufacturerForm: manufacturerForm
            }
        case CHANGE_PRODUCTS_FILTERS_VALUE:
            let productsFilters = {...state.productsFilters};
            productsFilters[action.input] = action.value;
            return {
                ...state,
                productsFilters: productsFilters,
                pagination: {
                    ...state.pagination,
                    products: {
                        page: 1,
                        limit: state.pagination.products.limit
                    }
                }
            }
        case CHANGE_CUSTOMERS_FILTERS_VALUE:
            let customersFilters = {...state.customersFilters};
            customersFilters[action.input] = action.value;
            return {
                ...state,
                customersFilters: customersFilters,
                pagination: {
                    ...state.pagination,
                    customers: {
                        page: 1,
                        limit: state.pagination.customers.limit
                    }
                }
            }
        case CHANGE_ORDER_FILTERS_FORM_DATA:
            let orderFiltersForm = {...state.orderFiltersForm};
            orderFiltersForm[action.input] = action.value;
            return {
                ...state,
                orderFiltersForm: orderFiltersForm
            }
        case CHANGE_PARTNERS_TESTS_FILTERS_FORM_DATA:
            let partnersTestsFiltersForm = {...state.partnersTestsFiltersForm};
            partnersTestsFiltersForm[action.input] = action.value;
            return {
                ...state,
                partnersTestsFiltersForm: partnersTestsFiltersForm
            }
        case CHANGE_WAREHOUSE_HISTORY_FILTERS_FORM_DATA:
            let warehouseHistoryFiltersForm = {...state.warehouseHistoryFiltersForm};
            warehouseHistoryFiltersForm[action.input] = action.value;
            return {
                ...state,
                warehouseHistoryFiltersForm: warehouseHistoryFiltersForm,
            }
        case TOGGLE_MODAL_UPLOAD_FILE_ACTIVE:
            return {
                ...state,
                modalUploadOpen: action.status
            }
        case SET_WAREHOUSES_ACTIONS_DATA:
            return {
                ...state,
                warehousesAction: action.data.data,
                warehousesActionCount: action.data.count
            }
        case TOGGLE_MODAL_UPLOAD_IMAGE_ACTIVE:
            return {
                ...state,
                modalUploadDocumentOpen: action.status
            }
        case SET_PRODUCT_EDIT_DATA:
            return {
                ...state,
                productsAddForm: action.product
            }
        case SET_ATTRIBUTE_EDIT_DATA:
            return {
                ...state,
                attributeForm: action.attribute
            }
        case SET_SELL_MANAGER_EDIT_DATA:
            return {
                ...state,
                SellManagersForm: action.sell_manager
            }
        case DASHBOARD_DATA:
            return {
                ...state,
                dashboard: action.dashboard
            }
        case SET_TAG_EDIT_DATA:
            return {
                ...state,
                tagsForm: action.tag
            }
        case SET_CATEGORY_EDIT_DATA:
            return {
                ...state,
                categoryForm: action.category
            }
        case SET_ORDER_EDIT_DATA:
            return {
                ...state,
                orderEditData: Object.assign(state.orderEditData, action.data)
            }
        case SET_ORDER_EDIT_DATA_DATABASE:
            return {
                ...state,
                orderEditDataDatabase: action.data
            }
        case CHANGE_LOADER_STATUS:
            let count = action.status ? state.loader.count + 1 : state.loader.count - 1;
            let status = count > 0;
            let message = status ? state.loader.message : null;
            return {
                ...state,
                loader: {
                    ...state.loader,
                    count: count,
                    active: status,
                    message: message
                }
            };
        case CHANGE_LOADER_MESSAGE:
            return {
                ...state,
                loader: {
                    ...state.loader,
                    message: action.message
                }
            };
        case SET_MANUFACTURER_EDIT_DATA:
            return {
                ...state,
                manufacturerForm: action.manufacturer
            }
        case CHANGE_CATEGORY_ADD_FORM:
            let categoryForm = {...state.categoryForm};
            categoryForm[action.input] = action.value;
            return {
                ...state,
                categoryForm: categoryForm
            }
        case ALERT_SHOW_IN_PROJECT:
            let alert = {...state.alert};
            alert[action.input] = action.value;
            return {
                ...state,
                alert: alert
            }
        case CHANGE_PRODUCTS_PAGINATION_FORM_VALUE:
            let pagination = {...state.pagination};
            pagination[action.name][action.input] = action.value;
            return {
                ...state,
                pagination: pagination
            }
        case CHANGE_ATTRIBUTE_ADD_FORM:
            let attributeForm = {...state.attributeForm};
            // switch(action.input) {
            //     case 'name':
            //         attributeForm.name = attributeForm.name.map(e => {
            //            if(Number(action.id) === Number(e.id)) {
            //                e.name = action.value;
            //            }
            //            return e;
            //         });
            //     case 'values':
            //         attributeForm.name = attributeForm.name.map(e => {
            //             if(Number(action.id) === Number(e.id)) {
            //                 e.name = action.value;
            //             }
            //             return e;
            //         });
            // }
            attributeForm[action.input] = action.value;
            return {
                ...state,
                attributeForm: attributeForm
            }
        case CHANGE_PRODUCTS_ADD_FORM:
            let productForm = {...state.productsAddForm};
            productForm[action.input] = action.value;
            return {
                ...state,
                productsAddForm: productForm
            }
        case CLEAN_PRODUCTS_ADD_FORM:
            return {
                ...state,
                productsAddForm: initialState.productsAddForm
            }
        case CLEAN_SELL_MANAGER_ADD_FORM:
            return {
                ...state,
                SellManagersForm: initialState.SellManagersForm
            }
        case CHANGE_SELL_MANAGERS_ADD_FORM:
            let SellManagersForm = {...state.SellManagersForm};
            SellManagersForm[action.input] = action.value;
            return {
                ...state,
                SellManagersForm: SellManagersForm
            }
        case CHANGE_WAREHOUSES_ADD_FORM:
            let WarehousesForm = {...state.WarehousesForm};
            WarehousesForm[action.input] = action.value;
            return {
                ...state,
                WarehousesForm: WarehousesForm
            }
        case CHANGE_RELATED_PRODUCTS:
            return {
                ...state,
                related_products: action.value
            }
        case SET_EDIT_WAREHOUSE_FORM_DATA:
            return {
                ...state,
                WarehousesForm: action.form
            }
        case CHANGE_WAREHOUSES_INVOICE_ADD_FORM:
            let WarehousesInvoiceForm = {...state.WarehousesInvoiceForm};
            WarehousesInvoiceForm[action.input] = action.value;
            return {
                ...state,
                WarehousesInvoiceForm: WarehousesInvoiceForm
            }
        case CHANGE_ORDER_DETAIL_EDIT_DATA_VALUE:
            let changeOrderEdit = {...state.orderEditData};
            changeOrderEdit[action.input] = action.value;
            return {
                ...state,
                orderEditData: changeOrderEdit
            }
        case CHANGE_WAREHOUSES_MOVING_ADD_FORM:
            let WarehousesMovingForm = {...state.WarehousesMovingForm};
            WarehousesMovingForm[action.input] = action.value;
            return {
                ...state,
                WarehousesMovingForm: WarehousesMovingForm
            }
        case CHANGE_WAREHOUSES_WRITE_OFF_ADD_FORM:
            let WarehousesWriteOffForm = {...state.WarehousesWriteOffForm};
            WarehousesWriteOffForm[action.input] = action.value;
            return {
                ...state,
                WarehousesWriteOffForm: WarehousesWriteOffForm
            }
        case CHANGE_TAGS_ADD_FORM:
            let tagsForm = {...state.tagsForm};
            tagsForm[action.input] = action.value;
            return {
                ...state,
                tagsForm: tagsForm
            }
        case GET_ORDER:
            return {
                ...state,
                orderDetail: action.order
            }
        case CHECK_STATUS_NP:
            return {
                ...state,
                orderDetailTTN: action.ttn
            }
        case GET_WAREHOUSE:
            return {
                ...state,
                warehouse: action.warehouse
            }
        case GET_MANUFACTURERS:
            return {
                ...state,
                manufacturers: action.manufacturers
            }
        case GET_PRODUCERS:
            return {
                ...state,
                producers: action.producers
            }
        case GET_CUSTOMERS_BONUS:
            return {
                ...state,
                customers_bonus: action.customers_bonus
            }
        case GET_ATTRIBUTES:
            return {
                ...state,
                attributes: action.attributes
            }
        case GET_TAGS:
            return {
                ...state,
                tags: action.tags
            }
        case GET_SELL_MANAGERS:
            return {
                ...state,
                sell_managers: action.sell_managers
            }
        case GET_RELATED_PRODUCTS:
            return {
                ...state,
                related_products: action.related_products
            }
        case GET_WAREHOUSES:
            return {
                ...state,
                warehouses: action.warehouses
            }
        case GET_WAREHOUSES_RESERVED:
            return {
                ...state,
                warehouses_reserved: action.warehouses_reserved
            }
        case TOGGLE_MENU_ITEM_OPEN:
            return {
                ...state,
                menuActive: action.active
            }
        case GET_PRODUCT:
            return {
                ...state,
                productDetail: action.product
            }
        case SET_PRODUCTS_ADD_ACTIVE_TAB:
            return {
                ...state,
                productionTaskTab: action.tab
            }
        case TOGGLE_ACTIVE_LANG_TAB:
            return {
                ...state,
                activeLang: action.tab
            }
        case SET_ORDERS_ACTIVE_TAB:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    orders: {
                        page: 1,
                        limit: state.pagination.orders.limit
                    }
                },
                ordersTab: action.tab
            }
        case SET_WAREHOUSE_ACTIVE_TAB:
            return {
                ...state,
                warehouseTab: action.tab
            }
        case SET_ORDERS_ACTIVE_TAB_PARTNER_SALE:
            return {
                ...state,
                ordersTabPartnerSale: action.tab
            }
        case GET_LANGUAGES_DATA:
            return {
                ...state,
                languages: action.languages,
            }
        case GET_DIRECTORY_DATA:
            localStorage.setItem('medbuy_directory_data', JSON.stringify(action.directory));
            return {
                ...state,
                directoryData: action.directory,
            }
        case GET_ALL_CUSTOMERS:
            return {
                ...state,
                customers: action.customers.data,
                customersCount: action.customers.count,
            }
        case GET_CUSTOMER:
            return {
                ...state,
                customerDetail: action.customer
            }
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.categories
            }
        case TOGGLE_CATEGORY_ACTIVE:
            let category_id = Number(state.categoryOpen) === Number(action.id) ? 0 : action.id;
            return {
                ...state,
                categoryOpen: category_id
                }
        case TOGGLE_ATTRIBUTES_ACTIVE:
            let attribute_id = Number(state.attributesOpen) === Number(action.id) ? 0 : action.id;
            return {
                ...state,
                attributesOpen: attribute_id
                }
        case TOGGLE_ORDER_CUSTOMER_ACTIVE:
            let order_id = Number(state.orderPartnerOpen) === Number(action.id) ? 0 : action.id;
            return {
                ...state,
                orderPartnerOpen: order_id
            }
        case GET_COUNTER_STATUS_ORDERS:
            return {
                ...state,
                counter_status_data: action.data_counter_status
            }
        default:
            return state
    }
}

export const toggleActicveMenuAC = (active) => {
    return {
        type: TOGGLE_MENU_ITEM_OPEN,
        active: active,
    }
}
export const getAllProductsAC = (products) => {
    return {
        type: GET_ALL_PRODUCTS,
        products: products
    }
}
export const getProductAC = (product) => {
    return {
        type: GET_PRODUCT,
        product: product
    }
}
export const getManufacturersAC = (manufacturers) => {
    return {
        type: GET_MANUFACTURERS,
        manufacturers: manufacturers
    }
}
export const getProducersAC = (producers) => {
    return {
        type: GET_PRODUCERS,
        producers: producers
    }
}
export const getCustomersBonusAC = (customers_bonus) => {
    return {
        type: GET_CUSTOMERS_BONUS,
        customers_bonus: customers_bonus
    }
}
export const setChangeStatusProductsFalseDataAC = (products) => {
    return {
        type: SET_CHANGE_PRODUCTS_FALSE_STATUS_DATA,
        products: products
    }
}
export const setWarehousesActionsDataAC = (data) => {
    return {
        type: SET_WAREHOUSES_ACTIONS_DATA,
        data: data
    }
}
export const getAttributesAC = (attributes) => {
    return {
        type: GET_ATTRIBUTES,
        attributes: attributes
    }
}
export const changeLoaderStatusACS = (status) => {
    return {
        type: CHANGE_LOADER_STATUS,
        status: status
    }
}
export const changeLoaderStatusAC = (status) => (dispatch) => {
    if(status) {
        dispatch(changeLoaderStatusACS(status));
    } else {
        setTimeout(function () {
            dispatch(changeLoaderStatusACS(status));
        }, 750);
    }

}

export const changeLoaderMessageAC = (message) => {
    return {
        type: CHANGE_LOADER_MESSAGE,
        message: message
    }
}
export const getTagsAC = (tags) => {
    return {
        type: GET_TAGS,
        tags: tags
    }
}
export const getSellManagersAC = (sell_managers) => {
    return {
        type: GET_SELL_MANAGERS,
        sell_managers: sell_managers
    }
}
export const getRelatedProductsAC = (related_products) => {
    return {
        type: GET_RELATED_PRODUCTS,
        related_products: related_products
    }
}
export const getWarehousesAC = (warehouses) => {
    return {
        type: GET_WAREHOUSES,
        warehouses: warehouses
    }
}
export const getWarehouseReservedAC = (warehouses_reserved) => {
    return {
        type: GET_WAREHOUSES_RESERVED,
        warehouses_reserved: warehouses_reserved
    }
}
export const cleanProductAddForm = () => {
    return {
        type: CLEAN_PRODUCTS_ADD_FORM
    }
}
export const cleanSellManagerAddForm = () => {
    return {
        type: CLEAN_SELL_MANAGER_ADD_FORM
    }
}
export const toggleUploadFileModalActiveAC = (status) => {
    return {
        type: TOGGLE_MODAL_UPLOAD_FILE_ACTIVE,
        status: status
    }
}
export const toggleUploadFileDocumentModalActiveAC = (status) => {
    return {
        type: TOGGLE_MODAL_UPLOAD_IMAGE_ACTIVE,
        status: status
    }
}
export const getCategoriesAC = (categories) => {
    return {
        type: GET_CATEGORIES,
        categories: categories
    }
}
export const getAllOrdersAC = (orders, count = 0) => {
    return {
        type: GET_ALL_ORDERS,
        orders: orders,
        count: count
    }
}

export const getAllPartnersTestsAC = (tests = 0) => {
    return {
        type: GET_ALL_PARTNERS_TESTS,
        tests: tests.data,
        count: tests.count
    }
}
export const getCounterOrderAC = (data) => {
    return {
        type: GET_COUNTER_STATUS_ORDERS,
        data_counter_status: data,
    }
}
export const getOrderAC = (order) => {
    return {
        type: GET_ORDER,
        order: order
    }
}
export const getWarehouseAC = (warehouse) => {
    return {
        type: GET_WAREHOUSE,
        warehouse: warehouse
    }
}
export const getCustomersAC = (customers) => {
    return {
        type: GET_ALL_CUSTOMERS,
        customers: customers
    }
}
export const setOrderEditDataAC = (data) => {
    return {
        type: SET_ORDER_EDIT_DATA,
        data: data
    }
}
export const setOrderEditDataDatabaseAC = (data) => {
    return {
        type: SET_ORDER_EDIT_DATA_DATABASE,
        data: data
    }
}
export const getCustomerAC = (customer) => {
    return {
        type: GET_CUSTOMER,
        customer: customer
    }
}
export const getLanguagesDataAC = (languages) => {
    return {
        type: GET_LANGUAGES_DATA,
        languages: languages
    }
}
export const getDirectoryDataAC = (directory) => {
    return {
        type: GET_DIRECTORY_DATA,
        directory: directory
    }
}
export const changeManufacturerFormValueAC = (input, value) => {
    return {
        type: CHANGE_MANUFACTURER_ADD_FORM,
        input: input,
        value: value
    }
}
export const changeProductsPaginationsFormValueDataAC = (name, input, value) => {
    return {
        type: CHANGE_PRODUCTS_PAGINATION_FORM_VALUE,
        input: input,
        name: name,
        value: value
    }
}
export const changeOrderEditDataValueAC = (input, value) => {
    return {
        type: CHANGE_ORDER_DETAIL_EDIT_DATA_VALUE,
        input: input,
        value: value
    }
}
export const changeProductsFilterFormValueAC = (input, value) => {
    return {
        type: CHANGE_PRODUCTS_FILTERS_VALUE,
        input: input,
        value: value
    }
}
export const changeCustomersFilterFormValueAC = (input, value) => {
    return {
        type: CHANGE_CUSTOMERS_FILTERS_VALUE,
        input: input,
        value: value
    }
}
export const changeOrdersFilterFormValueAC = (input, value) => {
    return {
        type: CHANGE_ORDER_FILTERS_FORM_DATA,
        input: input,
        value: value
    }
}
export const changePartnersTestsFilterFormValueAC = (input, value) => {
    return {
        type: CHANGE_PARTNERS_TESTS_FILTERS_FORM_DATA,
        input: input,
        value: value
    }
}
export const changeWarehouseHistoryFilterFormValueAC = (input, value) => {
    return {
        type: CHANGE_WAREHOUSE_HISTORY_FILTERS_FORM_DATA,
        input: input,
        value: value
    }
}
export const changeProductAddFormValueAC = (input, value) => {
    return {
        type: CHANGE_PRODUCTS_ADD_FORM,
        input: input,
        value: value
    }
}
export const changeCategoryFormValueAC = (input, value) => {
    return {
        type: CHANGE_CATEGORY_ADD_FORM,
        input: input,
        value: value
    }
}
export const alertShowAC = (input, value) => {
    return {
        type: ALERT_SHOW_IN_PROJECT,
        input: input,
        value: value
    }
}
export const changeAttributeFormValueAC = (input, value, lang) => {
    return {
        type: CHANGE_ATTRIBUTE_ADD_FORM,
        input: input,
        value: value,
        lang: lang
    }
}
export const changeSellManagersFormValueAC = (input, value) => {
    return {
        type: CHANGE_SELL_MANAGERS_ADD_FORM,
        input: input,
        value: value
    }
}
export const changeWarehousesFormValueAC = (input, value) => {
    return {
        type: CHANGE_WAREHOUSES_ADD_FORM,
        input: input,
        value: value
    }
}
export const changeRelatedProductsValueAC = (value) => {
    return {
        type: CHANGE_RELATED_PRODUCTS,
        value: value
    }
}
export const setWarehouseFormEditAC = (form) => {
    return {
        type: SET_EDIT_WAREHOUSE_FORM_DATA,
        form: form
    }
}
export const changeWarehousesInvoiceFormValueAC = (input, value) => {
    return {
        type: CHANGE_WAREHOUSES_INVOICE_ADD_FORM,
        input: input,
        value: value
    }
}
export const changeWarehousesWriteOffFormValueAC = (input, value) => {
    return {
        type: CHANGE_WAREHOUSES_WRITE_OFF_ADD_FORM,
        input: input,
        value: value
    }
}
export const changeWarehousesMovingFormValueAC = (input, value) => {
    return {
        type: CHANGE_WAREHOUSES_MOVING_ADD_FORM,
        input: input,
        value: value
    }
}
export const changeTagsFormValueAC = (input, value) => {
    return {
        type: CHANGE_TAGS_ADD_FORM,
        input: input,
        value: value
    }
}
export const setProductEditDataAC = (product) => {
    return {
        type: SET_PRODUCT_EDIT_DATA,
        product: product
    }
}
export const setAttributeEditDataAC = (attribute) => {
    debugger
    return {
        type: SET_ATTRIBUTE_EDIT_DATA,
        attribute: attribute
    }
}
export const setSellManagerEditDataAC = (sell_manager) => {
    debugger
    return {
        type: SET_SELL_MANAGER_EDIT_DATA,
        sell_manager: sell_manager
    }
}
export const setTagEditDataAC = (tag) => {
    debugger
    return {
        type: SET_TAG_EDIT_DATA,
        tag: tag
    }
}
export const getStatusNPAC = (ttn) => {
    debugger
    return {
        type: CHECK_STATUS_NP,
        ttn: ttn
    }
}
export const setCategoryEditDataAC = (category) => {
    debugger
    return {
        type: SET_CATEGORY_EDIT_DATA,
        category: category
    }
}
export const setManufacturerEditDataAC = (manufacturer) => {
    debugger
    return {
        type: SET_MANUFACTURER_EDIT_DATA,
        manufacturer: manufacturer
    }
}
export const getAllProductsThunk = () => (dispatch, getState) => {
    const filters = getState().main.productsFilters;
    const pagination = getState().main.pagination.products;
    dispatch(changeLoaderStatusAC(true));
    apiService.getProducts(filters, pagination)
        .then(res => {
            dispatch(getAllProductsAC(res));
            dispatch(changeLoaderStatusAC(false));
        })
}
export const getAllProductLimitedThunk = () => (dispatch, getState) => {
    const filters = getState().main.productsFilters;
    const pagination = {
        page: 1,
        limit: 999
    };
    dispatch(changeLoaderStatusAC(true));
    apiService.getProducts(filters, pagination)
        .then(res => {
            dispatch(getAllProductsAC(res));
            dispatch(changeLoaderStatusAC(false));
        })
}

export const getProductThunk = (id) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getProduct(id)
        .then(res => {
            dispatch(getProductAC(res));
            dispatch(changeLoaderStatusAC(false));
        })
}

export const getAttributeEditThunk = (attribute) => (dispatch) => {
    dispatch(setAttributeEditDataAC(attribute));
}
export const getSellManagerEditThunk = (sell_manager) => (dispatch) => {
    dispatch(setSellManagerEditDataAC(sell_manager));
}
export const getWaherousesActionDataThunk = (type, page, pageSize) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getWarehouseHistory(type, page, pageSize)
        .then(res => {
            dispatch(setWarehousesActionsDataAC(res));
            dispatch(changeLoaderStatusAC(false));
        });
}
export const getTagEditThunk = (id, languages) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    const tag = {
        name: [],
        id: id
    };
    languages && languages.forEach((e,i) => {
        apiService.getTag(id, e.id)
            .then(res => {
                if(res && res.name) {
                    tag.name.push({
                        id: [...tag.name].length === 0 ? 1 : Math.max.apply(null, [...tag.name].map( (e) => e.id )) + 1,
                        lang: e.id,
                        name: res.name
                    });
                    tag.alias = res.alias
                }
                if(languages.length === Number(i + 1) && i !== 0) {
                    dispatch(changeLoaderStatusAC(false));
                    dispatch(setTagEditDataAC(tag));
                }
            });
    });


}
export const getCategoryEditThunk = (id, languages) => (dispatch) => {
    const category = {
        name: [],
        parent: null,
        id: id
    };
    dispatch(changeLoaderStatusAC(true));
    languages && languages.forEach((e,i) => {
        apiService.getCategory(id, e.id)
            .then(res => {
                if(res && res.name) {
                    category.name.push({
                        id: [...category.name].length === 0 ? 1 : Math.max.apply(null, [...category.name].map( (e) => e.id )) + 1,
                        lang: e.id,
                        name: res.name
                    });
                    category.parent = res.parent
                }
                if(languages.length === Number(i + 1) && i !== 0) {
                    dispatch(changeLoaderStatusAC(false));
                    dispatch(setCategoryEditDataAC(category))
                };
            });
    });


}
export const getManufacturerEditThunk = (id, languages) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    const manufacturer = {
        name: null,
        alias: null,
        id: id
    };
    apiService.getManufacturer(id)
        .then(res => {
            debugger
            if(res && res.name) {
                manufacturer.name = res.name;
                manufacturer.alias = res.alias;
            }
            dispatch(setManufacturerEditDataAC(manufacturer));
            dispatch(changeLoaderStatusAC(false));
        });
}
export const getProductEditThunk = (product) => (dispatch) => {
    dispatch(setProductEditDataAC(product));
}
export const copyProductThunk = (id, languages) => (dispatch) => {
    debugger
    dispatch(changeLoaderStatusAC(true));
    const product = {
        name: [],
        long_name: [],
        analyte: [],
        description: [],
        seo_name: [],
        seo_description: [],
        seo_keywords: [],
        model: null,
        manufacturer: null,
        price: null,
        price_one: null,
        currency: 1,
        preorder: 0,
        pdv: null,
        discount: null,
        partner_access: 0,
        new_product: 0,
        active: 0,
        firm: 1,
        attribute: [],
        category: [],
        image: [],
        documents: [],
        categorySelector: null,
        attributeSelector: null,
        attributeValue: null,
        id: id
    };
    debugger
    languages && languages.forEach((e,i) => {
        apiService.getProduct(id, e.id)
            .then(res => {
                if(res && res.name) {
                    product.name.push({
                        id: [...product.name].length === 0 ? 1 : Math.max.apply(null, [...product.name].map( (e) => e.id )) + 1,
                        lang: e.id,
                        name: res.name
                    });
                    product.long_name.push({
                        id: [...product.long_name].length === 0 ? 1 : Math.max.apply(null, [...product.long_name].map( (e) => e.id )) + 1,
                        lang: e.id,
                        name: res.long_name
                    });
                    product.analyte.push({
                        id: [...product.analyte].length === 0 ? 1 : Math.max.apply(null, [...product.analyte].map( (e) => e.id )) + 1,
                        lang: e.id,
                        name: res.analyte
                    });
                    product.description.push({
                        id: [...product.description].length === 0 ? 1 : Math.max.apply(null, [...product.description].map( (e) => e.id )) + 1,
                        lang: e.id,
                        name: res.description
                    });
                    product.seo_name && product.seo_name.push({
                        id: [...product.seo_name].length === 0 ? 1 : Math.max.apply(null, [...product.seo_name].map( (e) => e.id )) + 1,
                        lang: e.id,
                        name: res.seo ? res.seo.title : ''
                    });
                    product.seo_description && product.seo_description.push({
                        id: [...product.seo_description].length === 0 ? 1 : Math.max.apply(null, [...product.seo_description].map( (e) => e.id )) + 1,
                        lang: e.id,
                        name: res.seo ? res.seo.description : ''
                    });
                    product.seo_keywords && product.seo_keywords.push({
                        id: [...product.seo_keywords].length === 0 ? 1 : Math.max.apply(null, [...product.seo_keywords].map( (e) => e.id )) + 1,
                        lang: e.id,
                        name: res.seo ? res.seo.keywords : ''
                    });
                    product.model = res.model
                    product.manufacturer = res.manufacturer;
                    product.price = res.price;
                    product.in_pack = res.in_pack;
                    product.documents = res.documents.map(a => {
                        return {
                            id: a.id,
                            link: a.link,
                            name: a.name,
                        }
                    });
                    product.price_one = res.price_one;
                    product.category = res.category;
                    product.attribute = res.options.map(a => {
                        return {
                            id: a.id,
                            value: a.value_id
                        }
                    });
                    product.currency = res.currency;
                    product.preorder = res.preorder;
                    product.pdv = res.pdv;
                    product.discount = res.discount;
                    product.image = res.images.map(a => {
                        return {
                            id: a.id,
                            link: a.link
                        }
                    });
                    product.tags = res.tags.map(a => {
                        return a.id
                    });
                    product.partner_access = res.partner_access;
                    product.new_product = res.new_product;
                    product.active = res.active;
                    product.firm = res.firm;
                }
                if(languages.length === (Number(i) + 1) && i !== 0) {
                    setTimeout(function () {
                        console.log(product);
                        apiService.copyProduct(product).then(res => {
                            dispatch(changeLoaderStatusAC(false));
                            if(res && res.name) {
                                // window.location.href = '/products/edit/' + res.alias;
                                 window.location.href = '/products';
                            }
                        })
                    }, 1600);
                }
            });

    });

}
export const createManufacturerThunk = (manufacturer) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.createManufacturer(manufacturer)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/manufacturers';
            }
        })
}
export const checkNPStatusThunk = (ttn) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.checkNPTTN(ttn)
        .then(res => {
            if(res) {
                dispatch(getStatusNPAC(res))
                dispatch(changeLoaderStatusAC(false));
            }
        })
};
export const checkPortmoneStatus = (order_id) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.checkPortmone(order_id)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.reload();
            }
        })
};
export const createCategoryThunk = (category) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.createCategory(category)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/categories';
            }
        })
}
export const excelForAnalyticsThunk = (filters) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.createExcelAnalytics(filters)
        .then(res => {
            if(res && res.link) {
                dispatch(changeLoaderStatusAC(false));
                window.open(res.link);
            }
        })
}
export const excelProductsInStock = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.createExcelProductsInStock()
        .then(res => {
            if(res && res.link) {
                dispatch(changeLoaderStatusAC(false));
                window.open(res.link);
            }
        })
}
export const createAttributeThunk = (attribute) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.createAttribute(attribute)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/attributes';
            }
        })
}
export const createProductThunk = (product) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.createProduct(product)
        .then(res => {
            if(res && res.data) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/products';
            }
            if(res && res.error) {
                dispatch(alertShowAC("message", res.error));
                dispatch(alertShowAC("type_message", "error"));
                dispatch(alertShowAC("active", true));
            }
            dispatch(changeLoaderStatusAC(false));
        })
}
export const editProductThunk = (product) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.editProduct(product)
        .then(res => {
            if(res && res.data) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/products';
            }
            if(res && res.error) {
                dispatch(alertShowAC("message", res.error));
                dispatch(alertShowAC("type_message", "error"));
                dispatch(alertShowAC("active", true));
            }
            dispatch(changeLoaderStatusAC(false));
        })
}
export const editSellManagerThunk = (sell_manager) => (dispatch, getState) => {
    const form = getState().main.SellManagersForm
    dispatch(changeLoaderStatusAC(true));
    apiService.editSellManagers(form)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/sell_managers';
            }
            dispatch(changeLoaderStatusAC(false));
        })
}
export const editAttributeThunk = (attribute) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.editAttribute(attribute)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/attributes';
            }
        })
}
export const editTagThunk = (attribute) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.editTag(attribute)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/tags';
            }
        })
}
export const editCategoryThunk = (category) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.editCategory(category)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/categories';
            }
        })
}
export const editManufacturerThunk = (category) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.editManufacturer(category)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/manufacturers';
            }
        })
}

export const createSellManagersThunk = (sell_managers) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.createSellManagers(sell_managers)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/sell_managers';
            }
        })
}
export const createWarehousesThunk = (warehouses) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.createWarehouses(warehouses)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/warehouses';
            }
        })
}
export const editWarehousesThunk = (warehouses) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.editWarehouses(warehouses)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/warehouses';
            }
        })
}
export const createWarehousesInvoiceThunk = (warehouses) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.createWarehousesInvoice(warehouses)
        .then(res => {
            debugger
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/warehouses/receiving';
            }
        })
}
export const createWarehousesWriteOffThunk = (warehouses) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.createWarehousesWriteOff(warehouses)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/warehouses/write_off';
            }
        })
}
export const createWarehousesMovingThunk = (warehouses) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.createWarehousesMoving(warehouses)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/warehouses/moving';
            }
        })
}
export const createTagsThunk = (tags) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.createTags(tags)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                window.location.href = '/tags';
            }
        })
}
export const getManufacturersThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getManufacturers()
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getManufacturersAC(res));
        })
}
export const getProducersThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getProducers()
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getProducersAC(res));
        })
}
export const getCustomersBonusThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getCustomerBonus()
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getCustomersBonusAC(res));
        })
}
export const getAttributesThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getAttributes()
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getAttributesAC(res));
        })
}
export const getTagsThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getTags()
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getTagsAC(res));
        })
}
export const getSellManagersThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getSellManagers()
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getSellManagersAC(res));
        })
}
export const getRelatedProductsThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getRelatedProducts()
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getRelatedProductsAC(res));
        })
}
export const getWarehousesThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getWarehouses()
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getWarehousesAC(res));
        })
}
export const getWarehouseReservedThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getWarehouseReserved()
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getWarehouseReservedAC(res));
        })
}
export const getWarehouseEditDataThunk = (id) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getWarehouse(id)
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(setWarehouseFormEditAC(res));
        })
}
export const deleteTagThunk = (id) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.deleteTag(id)
        .then(res => {
            if(res) {
                apiService.getTags()
                    .then(res => {
                        dispatch(changeLoaderStatusAC(false));
                        dispatch(getTagsAC(res));
                    })
            }
        })
}
export const deleteCategoryThunk = (id) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.deleteCategory(id)
        .then(res => {
            if(res) {
                apiService.getCategories()
                    .then(res => {
                        dispatch(changeLoaderStatusAC(false));
                        dispatch(getCategoriesAC(res));
                    })
            }
        })
}
export const deleteManufacturerThunk = (id) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.deleteManufacturer(id)
        .then(res => {
            if(res) {
                apiService.getManufacturers()
                    .then(res => {
                        dispatch(changeLoaderStatusAC(false));
                        dispatch(getManufacturersAC(res));
                    })
            }
        })
}
export const deleteAttributeThunk = (id) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.deleteAttribute(id)
        .then(res => {
            if(res) {
                apiService.getAttributes()
                    .then(res => {
                        dispatch(changeLoaderStatusAC(false));
                        dispatch(getAttributesAC(res));
                    })
            }
        })
}
export const getCategoriesThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getCategories()
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getCategoriesAC(res));
        })
}
export const getAllOrdersThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getOrders()
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getAllOrdersAC(res));
        })
}

export const getAllPartnersTestsThunk = () => (dispatch, getState) => {

    const filters = getState().main.partnersTestsFiltersForm;
    const pagination = getState().main.pagination.partners_tests;

    dispatch(changeLoaderStatusAC(true));
    apiService.getPartnersTests(filters, pagination)
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getAllPartnersTestsAC(res));
        })
}
export const getCounterStatusOrdersThunk = (status) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getOrderCounterForStatus(status)
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getCounterOrderAC(res));
        })
}
export const getAllOrdersFilterThunk = () => (dispatch, getState) => {

    const filters = getState().main.orderFiltersForm;
    const pagination = getState().main.pagination.orders;
    const type = getState().main.ordersTab;

   ///--->>> отримати роль юзера і якщо це 4 - додати у фільтр manager_id

    dispatch(changeLoaderStatusAC(true));
    apiService.getFilterOrders(type, filters, pagination)
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getAllOrdersAC(res.data, res.count));
        })
}
export const getOrderThunk = (id) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getOrder(id)
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getOrderAC(res));
        })
}
export const getWarehouseThunk = (id) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getWarehouseDetail(id)
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getWarehouseAC(res));
        })
}
export const getAllCustomersThunk = (params = false) => (dispatch, getState) => {
    const filters = getState().main.customersFilters;
    const pagination = getState().main.pagination.customers;
    dispatch(changeLoaderStatusAC(true));
    apiService.getFilteredCustomers({ ...filters, ...(params || pagination) })
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getCustomersAC(res));
        })
}
export const getCustomerThunk = (id) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getCustomer(id)
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getCustomerAC(res));
        })
}
export const getLanguagesDataThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getLanguages()
        .then(res => {
            dispatch(changeLoaderStatusAC(false));
            dispatch(getLanguagesDataAC(res));
        })
}
export const getDirectoryDataThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    let directory = localStorage.getItem('medbuy_directory_data');
    if(directory && directory.length > 0) {
        dispatch(changeLoaderStatusAC(false));
        dispatch(getDirectoryDataAC(JSON.parse(directory)));
    } else {
        apiService.getDirectory()
            .then(res => {
                dispatch(changeLoaderStatusAC(false));
                dispatch(getDirectoryDataAC(res));
            })
    }
}

export const toggleSideBarTypeAC = (view) => {
    return {
        type: TOGGLE_SIDBAR_VIEW,
        view: view
    }
}
export const setProductsAddActiveTabAC = (tab) => {
    return {
        type: SET_PRODUCTS_ADD_ACTIVE_TAB,
        tab: tab
    }
}
export const setProductsAddActiveTabLangAC = (tab) => {
    return {
        type: TOGGLE_ACTIVE_LANG_TAB,
        tab: tab
    }
}
export const setOrdersActiveTabAC = (tab) => {
    return {
        type: SET_ORDERS_ACTIVE_TAB,
        tab: tab
    }
}
export const setWarehouseActiveTabAC = (tab) => {
    return {
        type: SET_WAREHOUSE_ACTIVE_TAB,
        tab: tab
    }
}
export const setOrdersActiveTabPartnerSaleAC = (tab) => {
    return {
        type: SET_ORDERS_ACTIVE_TAB_PARTNER_SALE,
        tab: tab
    }
}

export const toggleCategoryActiveDataAC = (id) => {
    return {
        type: TOGGLE_CATEGORY_ACTIVE,
        id: id,
    }
}
export const toggleAttributesActiveDataAC = (id) => {
    return {
        type: TOGGLE_ATTRIBUTES_ACTIVE,
        id: id,
    }
}
export const toggleOrderCustomerActiveDataAC = (id) => {
    return {
        type: TOGGLE_ORDER_CUSTOMER_ACTIVE,
        id: id,
    }
}
export const formatSum = (sum) => {
    let format = (new Intl.NumberFormat('ru-RU').format(Number(sum).toFixed(2))).replace(',', '.');
    return format && sum > 0 ? format : '0';
}
export const changePartnerBonusStatus = (status, id) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getChangePartnerBonus(status, id)
        .then(res => {
            if(res) {
                dispatch(getCustomersBonusThunk())
                dispatch(changeLoaderStatusAC(false));
            }
        })
}
export const sendEditOrderDataThunk = (id) => (dispatch, getState) => {
    const data = getState().main.orderEditData;
    dispatch(changeLoaderStatusAC(true));
    apiService.sendEditOrderData(id, data)
        .then(res => {
            if(res) {
                if(res.error) {
                    dispatch(changeLoaderStatusAC(false));
                    dispatch(alertShowAC("message", res.error));
                    dispatch(alertShowAC("type_message", "error"));
                    dispatch(alertShowAC("active", true));
                }
                if(res.data) {
                    dispatch(changeLoaderStatusAC(false));
                    window.location.href = '/orders/' + id;
                }
            }
        })
}
export const getOrderEditDataThunk = (id) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getEditOrderData(id)
        .then(res => {
            if(res) {
                dispatch(setOrderEditDataAC(res.request));
                dispatch(setOrderEditDataDatabaseAC(res.database));
                dispatch(changeLoaderStatusAC(false));
            }
        })
}
export const getOrderReceiptThunk = (id, form) => (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getOrderReceipt(id, form)
        .then(res => {
            if(res) {
                dispatch(changeLoaderStatusAC(false));
                // dispatch(getOrderThunk(id));
                window.location.href = '/orders/' + id;
            }
        })
}
export const getDashboardDataThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getDashboardData()
        .then(res => {
            // debugger
            if(res) {
                dispatch(getDashboardDataAC(res));
                dispatch(changeLoaderStatusAC(false));
            }
        })
}
export const getDashboardDataAC = (dashboard) => {
    return {
        type: DASHBOARD_DATA,
        dashboard: dashboard
    }
}



export default AccountReducer;
