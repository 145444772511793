import React from 'react';
import PageTitle from "../../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './WarehousesInvoiceForm.css';
import Select from "react-select";

const WarehousesInvoiceForm = (props) => {

    return (
        <div className="content-wrapper">
            <PageTitle title={'warehouses_form_invoice'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header"><h3
                            className="card-title">{props.getTranslate('crm_form_add', 'form_add_main_info_title')}</h3>
                        </div>
                        <form onSubmit={(event) => {
                            event.preventDefault();
                            props.createWarehousesInvoice({...props.form});
                        }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{props.getTranslate('crm_form_add', 'form_add_warehouses_invoice_producers')}:</label>
                                            <Select value={props.form && props.form.producers && props.producers ? props.producers
                                                    .filter(e => Number(e.id) === Number(props.form.producers))
                                                    .map(e => {
                                                        return {
                                                            value: e.id,
                                                            label: e.name
                                                        }
                                                    })[0]
                                                : ''
                                            }
                                                    onChange={(event) => props.changeValue('producers', event.value)}
                                                    placeholder={props.getTranslate('crm_form_add', 'form_add_warehouses_invoice_producers')}
                                                    defaultValue={props.form && props.form.producers ? props.form.producers.label : ''}
                                                    className={'form-select-container'}
                                                    required={'required'}
                                                    options={props.producers && props.producers.map(e => {
                                                        return {
                                                            value: e.id,
                                                            label: e.name
                                                        }
                                                    })
                                                    }/>
                                        </div>
                                        <div className="form-group">
                                            <label>{props.getTranslate('crm_main', 'warehouse')}:</label>
                                            <Select value={props.form && props.form.warehouse && props.warehouses ? props.warehouses
                                                    .filter(e => Number(e.id) === Number(props.form.warehouse))
                                                    .map(e => {
                                                        return {
                                                            value: e.id,
                                                            label: e.name
                                                        }
                                                    })[0]
                                                : ''
                                            }
                                                    onChange={(event) => props.changeValue('warehouse', event.value)}
                                                    placeholder={props.getTranslate('crm_main', 'warehouse')}
                                                    defaultValue={props.form && props.form.warehouse ? props.form.warehouse.label : ''}
                                                    className={'form-select-container'}
                                                    required={'required'}
                                                    options={props.warehouses && props.warehouses.map(e => {
                                                        return {
                                                            value: e.id,
                                                            label: e.name
                                                        }
                                                    })
                                                    }/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{props.getTranslate('crm_main', 'comments')}:</label>
                                            <textarea rows={6}
                                                      className="form-control"
                                                      value={props.form && props.form.comment ? props.form.comment : ''}
                                                      onChange={(event) => props.changeValue('comment', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header card_header_form_save">
                                        <h3 className="card-title">{props.getTranslate('crm_form_add', 'form_add_warehouses_invoice_products')}</h3>
                                        <button type={'button'}
                                                className={"btn btn-success"}
                                                onClick={() => {
                                                    let form_values_add = [...props.form.products];
                                                    let new_form_values = {
                                                        id: form_values_add.length === 0 ? 1 : Math.max.apply(null, [...form_values_add].map( (value) => value.id )) + 1,
                                                        id_product: null,
                                                        count: null,
                                                        product_type: 1
                                                    };
                                                    form_values_add.push(new_form_values);
                                                    props.changeValue('products', form_values_add);
                                                }}
                                        >+</button>
                                    </div>
                                    <div className="card-body">
                                        {props.form && props.form.products && props.form.products.map(main_value => {
                                            return <>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{props.getTranslate('crm_form_add', 'form_add_warehouses_invoice_product')}:</label>
                                                            <Select value={props.form && props.form.products ? props.products
                                                                                .filter(e => Number(e.id) === Number(main_value.id_product))
                                                                                .map(e => {
                                                                                    return {
                                                                                        value: e.id,
                                                                                        label: e.name + ' (' + e.model + ')'
                                                                                    }
                                                                                })[0]
                                                                                : ''
                                                                            }
                                                                    defaultValue={props.form && props.form.products ? props.products
                                                                            .filter(e => Number(e.id) === Number(main_value.id_product))
                                                                            .map(e => {
                                                                                return {
                                                                                    value: e.id,
                                                                                    label: e.name + ' (' + e.model + ')'
                                                                                }
                                                                            })[0]
                                                                        : ''
                                                                    }
                                                                    onChange={(event) => {
                                                                        let form_values = [...props.form.products].map(e => {
                                                                            if(Number(e.id) === Number(main_value.id)) {
                                                                                e.id_product = event.value;
                                                                            }
                                                                            return e;
                                                                        });

                                                                        props.changeValue('products', form_values);
                                                                    }}
                                                                    placeholder={props.getTranslate('crm_form_add', 'form_add_warehouses_invoice_product')}
                                                                    className={'form-select-container'}
                                                                    required={'required'}
                                                                    options={props.products && props.products
                                                                        .filter(e => {
                                                                            const added = props.form.products && props.form.products.map(e => Number(e.id_product));
                                                                            return !added.includes(Number(e.id))
                                                                        })
                                                                        .map(e => {
                                                                        return {
                                                                            value: e.id,
                                                                            label: e.name + ' (' + e.model + ')'
                                                                        }
                                                                    })
                                                                    }/>
                                                        </div>
                                                    </div>
                                                    <div className={"col-md-3"}>
                                                        <div className="form-group">
                                                            <label>{props.getTranslate('crm_form_add', 'form_product_type')}:</label>
                                                            <Select value={props.form &&
                                                            props.form.products &&
                                                            props.form.products.filter(e => Number(e.id) === Number(main_value.id))[0] ?
                                                                {
                                                                    value: props.products_type.filter(e => Number(e.id) === Number(props.form.products.filter(e => Number(e.id) === Number(main_value.id))[0].product_type))[0].id,
                                                                    label: props.products_type.filter(e => Number(e.id) === Number(props.form.products.filter(e => Number(e.id) === Number(main_value.id))[0].product_type))[0].value
                                                                }
                                                                : ''}
                                                                    defaultValue={props.form &&
                                                                    props.form.products &&
                                                                    props.form.products.filter(e => Number(e.id) === Number(main_value.id))[0] ?
                                                                        props.form.products.filter(e => Number(e.id) === Number(main_value.id))[0].product_type
                                                                        : ''}
                                                                    onChange={(event) => {
                                                                        let form_values = [...props.form.products].map(e => {
                                                                            if (Number(e.id) === Number(main_value.id)) {
                                                                                e.product_type = event.value;
                                                                            }
                                                                            return e;
                                                                        });
                                                                        props.changeValue('products', form_values);;
                                                                    }}
                                                                    placeholder={props.getTranslate('crm_form_add', 'form_add_warehouses_invoice_product')}
                                                                    className={'form-select-container'}
                                                                    required={'required'}
                                                                    options={props.products_type && props.products_type
                                                                        .map(e => {
                                                                            return {
                                                                                value: e.id,
                                                                                label: e.value
                                                                            }
                                                                        })
                                                                    }/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>{props.getTranslate('crm_form_add', 'form_add_count')}:</label>
                                                            <input className={'form-control'}
                                                                   type={"number"}
                                                                   value={props.form &&
                                                                          props.form.products &&
                                                                          props.form.products.filter(e => Number(e.id) === Number(main_value.id))[0] ?
                                                                          props.form.products.filter(e => Number(e.id) === Number(main_value.id))[0].count
                                                                          : ''}
                                                                   onChange={(event) => {
                                                                       let form_values = [...props.form.products].map(e => {
                                                                           if(Number(e.id) === Number(main_value.id)) {
                                                                               e.count = event.target.value;
                                                                           }
                                                                           return e;
                                                                       });
                                                                       props.changeValue('products', form_values);
                                                                   }}
                                                                   required
                                                                   placeholder={props.getTranslate('crm_form_add', 'form_add_count')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 warehouse_form_button_delete">
                                                        <div className="form-group">
                                                            <button className={"btn btn-danger form_minus_button-attribute"}
                                                                    type={'button'}
                                                                    onClick={() => {
                                                                        let form_values_del = [...props.form.products];
                                                                        form_values_del = form_values_del.filter(d_val => Number(d_val.id) !== Number(main_value.id));
                                                                        props.changeValue('products', form_values_del);
                                                                    }}
                                                            >-</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        })}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button
                                        className={"btn btn-success"}
                                        type={'submit'}
                                    >{props.getTranslate('crm_form_add', 'form_add_button_create')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default WarehousesInvoiceForm;