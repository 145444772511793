import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    changeOrdersFilterFormValueAC,
    changeProductsPaginationsFormValueDataAC, getAllCustomersThunk,
    getAllOrdersFilterThunk, getAllProductsThunk, getDirectoryDataThunk, getSellManagersThunk
} from "../../store/reducers/MainReducer";
import Select from "react-select";

const OrderFilters = ({ users }) => {

    const dispatch = useDispatch();
    const form = useSelector((state) => state.main.orderFiltersForm);
    const statuses = useSelector((state) => state.main.directoryData.status);
    const customers = useSelector((state) => state.main.customers);
    const changeValue = (input, value) => dispatch(changeOrdersFilterFormValueAC(input, value));
    const getOrdersFilter = () => {
        dispatch(changeProductsPaginationsFormValueDataAC('orders', 'page', 1))
        dispatch(getAllOrdersFilterThunk())
    };

    const getCustomer = (customer_id) => {
        if(customer_id === '') {
            return {
                name: 'Гість',
                surname: '',
                id: ''
            }
        }
        return customer_id &&
        customers &&
        customers.filter(e => Number(e.id) === Number(customer_id))[0] ? customers.filter(e => Number(e.id) === Number(customer_id))[0] : {}
    };

    useEffect(() => {
        dispatch(getAllCustomersThunk());
    }, []);

    return <div className="card card-primary card-outline card-tabs">
        <div className="card-body">
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <div className="input-group mb-3 form_block_input">
                            <label>
                                ID:
                            </label>
                            <input className={'form-control'}
                                   value={form.id}
                                   type={'text'}
                                   onBlur={() => getOrdersFilter()}
                                   onChange={(event) => changeValue('id', event.target.value)}
                                   required
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <div className="input-group mb-3 form_block_input">
                            <label>
                                Платник:
                            </label>
                            <input className={'form-control'}
                                   value={form.payment_object}
                                   type={'text'}
                                   onBlur={() => getOrdersFilter()}
                                   onChange={(event) => changeValue('payment_object', event.target.value)}
                                   required
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <div className="input-group mb-3 form_block_input">
                            <label>
                                Статус:
                            </label>
                            <select className={'form-control'}
                                   value={form.status}
                                   onBlur={() => getOrdersFilter()}
                                   onChange={(event) => changeValue('status', event.target.value)}
                                   required
                            >
                                <option key={0} value={''}> </option>
                                {statuses && statuses.map(e => <option key={e.id} value={e.id}>{e.value}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <div className="input-group mb-3 form_block_input">
                            <label>
                                Клієнт:
                            </label>
                            <Select value={{
                                value: form.id_customer,
                                label: getCustomer(form.id_customer).name + ' ' + getCustomer(form.id_customer).surname
                            }}
                                    defaultValue={{
                                        value: form.id_customer,
                                        label: getCustomer(form.id_customer).name + ' ' + getCustomer(form.id_customer).surname
                                    }}
                                    onChange={(selectedOption) => {
                                        changeValue('id_customer', selectedOption.value);
                                        changeValue('asPartner', false)
                                    }}
                                    className={'form-select-container'}
                                    onBlur={() => getOrdersFilter()}
                                    required={'required'}
                                    options={customers && [
                                        {
                                            value: '',
                                            label: 'Гість'
                                        },
                                        ...customers.map(e => {
                                            return {
                                                value: e.id,
                                                label: e.name + ' ' + e.surname
                                            }
                                        })
                                    ]
                                    }/>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <div className="input-group mb-3 form_block_input">
                            <label>
                                Дата (від):
                            </label>
                            <input className={'form-control'}
                                   value={form.date_from}
                                   type={'date'}
                                   onBlur={() => getOrdersFilter()}
                                   onChange={(event) => changeValue('date_from', event.target.value)}
                                   required
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <div className="input-group mb-3 form_block_input">
                            <label>
                                Дата (до):
                            </label>
                            <input className={'form-control'}
                                   value={form.date_to}
                                   type={'date'}
                                   onBlur={() => getOrdersFilter()}
                                   onChange={(event) => changeValue('date_to', event.target.value)}
                                   required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <div className="input-group mb-3 form_block_input">
                            <label>
                                Менеджер:
                            </label>
                            <select className={'form-control'}
                                    value={form.manager_id}
                                    onBlur={() => getOrdersFilter()}
                                    onChange={(event) => changeValue('manager_id', event.target.value)}
                                    required
                            >
                                <option key={0} value={0}> </option>
                                {users && users.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default OrderFilters;
