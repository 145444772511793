import React from 'react';
import OrderDetail from "./OrderDetail";
import {connect} from "react-redux";
import './OrderDetail.css';
import {getDirectoryDataThunk, getOrderThunk} from "../../../store/reducers/MainReducer";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";




class OrderDetailContainer extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.props.directory();
        this.props.getOrderThunk(this.props.uri.match.params.id)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.orderTTN && prevProps.orderTTN && Number(this.props.orderTTN.statusCode) !== Number(prevProps.orderTTN.statusCode)) {
            this.props.getOrderThunk(this.props.uri.match.params.id);
        }
    }

    render() {
        return <OrderDetail order={this.props.order} getTranslate={this.props.getTranslate} directoryData={this.props.directoryData} ttn={this.props.orderTTN}/>
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
        getOrderThunk: (id) => dispatch(getOrderThunk(id))
    }
}

let mapStateToProps = (state) => {
    return {
        directoryData: state.main.directoryData,
        order: state.main.orderDetail,
        orderTTN: state.main.orderDetailTTN
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(OrderDetailContainer);