
import React from 'react';
import ProductsAdd from "./ProductsAdd";
import {connect} from "react-redux";
import './ProductsAdd.css';
import {
    changeLoaderStatusAC,
    changeProductAddFormValueAC, cleanProductAddForm,
    createProductThunk, editProductThunk, getAllProductLimitedThunk,
    getAttributesThunk, getCategoriesThunk,
    getDirectoryDataThunk,
    getManufacturersThunk,
    getProductEditThunk, getTagsThunk,
    setProductsAddActiveTabAC,
    setProductsAddActiveTabLangAC,
    toggleUploadFileDocumentModalActiveAC,
    toggleUploadFileModalActiveAC
} from "../../../store/reducers/MainReducer";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import ApiService from "../../../api/api";


const apiService = new ApiService();

class ProductsAddContainer extends React.Component {

    constructor(props){
        super(props);
        this.setDefaultValue = this.setDefaultValue.bind(this);
        this.addImage = this.addImage.bind(this);
        this.addDocument = this.addDocument.bind(this);
        this.getEitData = this.getEitData.bind(this);
    }

    setDefaultValue(input, lang = this.props.languages) {
        let data = [];
        lang && lang.forEach(e => {
            data.push({
                lang: e.id,
                name: ''
            })
        });
        this.props.changeValue(input, data);
    }

    addImage(link) {
        let id = null;
        if([...this.props.form.image].length === 0) {
            id = 1;
        } else {
            id = Math.max.apply(null, [...this.props.form.image].map( (e) => e.id )) + 1;
        }
        let images = [
            ...this.props.form.image,
            {
                id: id,
                link: link
            }
        ];
        this.props.changeValue('image', images);
    }
    addDocument(link, name) {
        let id = null;
        if([...this.props.form.documents].length === 0) {
            id = 1;
        } else {
            id = Math.max.apply(null, [...this.props.form.documents].map( (e) => e.id )) + 1;
        }
        let documents = [
            ...this.props.form.documents,
            {
                id: id,
                link: link,
                name: name
            }
        ];
        this.props.changeValue('documents', documents);
    }

    async getEitData(id, languages) {
        this.props.setLoaderActive(true);
        if(!languages || !id) return false;
        const product = {
            name: [],
            long_name: [],
            analyte: [],
            description: [],
            seo_name: [],
            seo_description: [],
            seo_keywords: [],
            model: null,
            manufacturer: null,
            price: null,
            price_one: null,
            currency: 1,
            preorder: 0,
            alias: '',
            pdv: null,
            discount: null,
            partner_access: 0,
            new_product: 0,
            active: 0,
            firm: 1,
            attribute: [],
            category: [],
            tags: [],
            recommend_products: [],
            image: [],
            in_pack: null,
            documents: [],
            categorySelector: null,
            attributeSelector: null,
            attributeValue: null,
            id: id
        };
        for(let i = 0; i <= languages.length - 1; i++) {
            const res = await apiService.getProduct(id, languages[i].id);
            if(res && res.name) {
                product.name.push({
                    id: [...product.name].length === 0 ? 1 : Math.max.apply(null, [...product.name].map( (e) => languages[i].id )) + 1,
                    lang: languages[i].id,
                    name: res.name
                });
                product.long_name.push({
                    id: [...product.long_name].length === 0 ? 1 : Math.max.apply(null, [...product.long_name].map( (e) => languages[i].id )) + 1,
                    lang: languages[i].id,
                    name: res.long_name
                });
                product.analyte.push({
                    id: [...product.analyte].length === 0 ? 1 : Math.max.apply(null, [...product.analyte].map( (e) => languages[i].id )) + 1,
                    lang: languages[i].id,
                    name: res.analyte
                });
                product.description.push({
                    id: [...product.description].length === 0 ? 1 : Math.max.apply(null, [...product.description].map( (e) => languages[i].id )) + 1,
                    lang: languages[i].id,
                    name: res.description
                });
                product.seo_name && product.seo_name.push({
                    id: [...product.seo_name].length === 0 ? 1 : Math.max.apply(null, [...product.seo_name].map( (e) => languages[i].id )) + 1,
                    lang: languages[i].id,
                    name: res.seo ? res.seo.title : ''
                });
                product.seo_description && product.seo_description.push({
                    id: [...product.seo_description].length === 0 ? 1 : Math.max.apply(null, [...product.seo_description].map( (e) => languages[i].id )) + 1,
                    lang: languages[i].id,
                    name: res.seo ? res.seo.description : ''
                });
                product.seo_keywords && product.seo_keywords.push({
                    id: [...product.seo_keywords].length === 0 ? 1 : Math.max.apply(null, [...product.seo_keywords].map( (e) => languages[i].id )) + 1,
                    lang: languages[i].id,
                    name: res.seo ? res.seo.keywords : ''
                });
                product.model = res.model;
                product.alias = res.alias;
                product.manufacturer = res.manufacturer;
                product.price = res.price;
                product.in_pack = res.in_pack;
                product.documents = res.documents.map(a => {
                    return {
                        id: a.id,
                        link: a.link,
                        name: a.name,
                    }
                });
                product.price_one = res.price_one;
                product.category = res.category;
                product.attribute = res.options.map(a => {
                    if(a) {
                        return {
                        id: a.id,
                        value: a.value_id ? a.value_id : a.value
                    }
                    }
                });
                product.tags = res.tags.map(a => {
                    return a.id
                });
                product.recommend_products = res.recommend_products?.map(a => {
                    return a.id
                });
                product.currency = res.currency;
                product.preorder = res.preorder;
                product.pdv = res.pdv;
                product.discount = res.discount;
                product.image = res.images.map(a => {
                    return {
                        id: a.id,
                        link: a.link
                    }
                });
                product.partner_access = res.partner_access;
                product.new_product = res.new_product;
                product.active = res.active;
                product.firm = res.firm;
            }
        }
        this.props.setLoaderActive(false);
        this.props.getEitData(product);
    }

    componentDidMount() {
        this.props.directory();
        this.props.getAttributesThunk();
        this.props.getManufacturersThunk();
        this.props.getTagsThunk();
        this.props.getCategoriesThunk();
        this.props.getAllProductsThunk();

        this.setDefaultValue('name');
        this.setDefaultValue('long_name');
        this.setDefaultValue('analyte');
        this.setDefaultValue('description');
        this.setDefaultValue('seo_name');
        this.setDefaultValue('seo_description');
        this.setDefaultValue('seo_keywords');

        if(this.props.isEdit) {
            this.getEitData(this.props.uri.match.params.alias, this.props.languages);
        } else {
            // this.props.cleanProductAddForm();
            // this.props.changeValue('model', null);
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {

        if(nextProps.languages && !this.props.languages) {
            this.setDefaultValue('name', nextProps.languages);
            this.setDefaultValue('long_name', nextProps.languages);
            this.setDefaultValue('analyte', nextProps.languages);
            this.setDefaultValue('description', nextProps.languages);
            this.setDefaultValue('seo_name', nextProps.languages);
            this.setDefaultValue('seo_description', nextProps.languages);
            this.setDefaultValue('seo_keywords', nextProps.languages);

            if(nextProps.isEdit) {
                this.getEitData(nextProps.uri.match.params.alias, nextProps.languages);
            } else {
                nextProps.cleanProductAddForm();
                nextProps.changeValue('model', null);
            }

        }

    }

    render() {
        return <ProductsAdd setActiveTab={this.props.setActiveTab}
                            activeTab={this.props.activeTab}
                            setActiveTabLang={this.props.setActiveTabLang}
                            activeTabLang={this.props.activeLang}
                            changeValue={this.props.changeValue}
                            form={this.props.form}
                            createProduct={this.props.createProduct}
                            editProduct={this.props.editProduct}
                            getTranslate={this.props.getTranslate}
                            tags={this.props.tags}
                            products={this.props.products}
                            addImage={this.addImage}
                            addDocument={this.addDocument}
                            languages={this.props.languages}
                            attributes={this.props.attributes}
                            modalImageIsOpen={this.props.modalImageIsOpen}
                            directoryData={this.props.directoryData}
                            modalDocumentIsOpen={this.props.modalDocumentIsOpen}
                            toggleModalUpload={this.props.toggleModalUpload}
                            toggleModalDocumentUpload={this.props.toggleModalDocumentUpload}
                            manufacturers={this.props.manufacturers}
                            isEdit={this.props.isEdit}
                            categories={this.props.categories}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setActiveTab: (tab) => dispatch(setProductsAddActiveTabAC(tab)),
        setActiveTabLang: (tab) => dispatch(setProductsAddActiveTabLangAC(tab)),
        changeValue: (input, value) => dispatch(changeProductAddFormValueAC(input, value)),
        createProduct: (product) => dispatch(createProductThunk(product)),
        editProduct: (product) => dispatch(editProductThunk(product)),
        toggleModalUpload: (status) => dispatch(toggleUploadFileModalActiveAC(status)),
        toggleModalDocumentUpload: (status) => dispatch(toggleUploadFileDocumentModalActiveAC(status)),
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
        getAttributesThunk: () => dispatch(getAttributesThunk()),
        getAllProductsThunk: () => dispatch(getAllProductLimitedThunk()),
        getManufacturersThunk: () => dispatch(getManufacturersThunk()),
        getEitData: (id, languages) => dispatch(getProductEditThunk(id, languages)),
        cleanProductAddForm: () => dispatch(cleanProductAddForm()),
        getTagsThunk: () => dispatch(getTagsThunk()),
        getCategoriesThunk: () => dispatch(getCategoriesThunk()),
        setLoaderActive: (status) => dispatch(changeLoaderStatusAC(status))
    }
}

let mapStateToProps = (state) => {
    return {
        activeTab: state.main.productionTaskTab,
        activeLang: state.main.activeLang,
        languages: state.main.languages,
        form: state.main.productsAddForm,
        modalImageIsOpen: state.main.modalUploadOpen,
        modalDocumentIsOpen: state.main.modalUploadDocumentOpen,
        directoryData: state.main.directoryData,
        attributes: state.main.attributes,
        products: state.main.products,
        manufacturers: state.main.manufacturers,
        tags: state.main.tags,
        categories: state.main.categories
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(ProductsAddContainer);