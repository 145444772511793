import React from 'react';
import {connect} from "react-redux";
import './WarehouseHistory.css';
import {withLang} from "../../HOC/withLang/withLang";
import {compose} from "redux";
import WarehouseHistory from "./WarehouseHistory";
import {
    changeLoaderStatusAC,
    changeProductsPaginationsFormValueDataAC,
    changeWarehouseHistoryFilterFormValueAC
} from "../../../store/reducers/MainReducer";
import ApiService from "../../../api/api";
import Products from "../../Products/Products";

const api = new ApiService();

class WarehouseHistoryContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            count: 0,
            page: 1,
        };
        this.fetchData = this.fetchData.bind(this);
        this.getExcel = this.getExcel.bind(this);
        this.getFetchFiltersData = this.getFetchFiltersData.bind(this);
    }

    getFetchFiltersData() {
        return {
            ...this.props.params,
            ...this.props.form,
            page: this.props.page,
            limit: 10
        };
    }

    fetchData(page = false) {
        const filters = this.getFetchFiltersData();
        if(page) {
            filters.page = page;
        }
        this.props.changeLoaderStatus(true)
        api.getProductsHistory(filters)
            .then(res => {
                if (res) {
                    this.props.changeLoaderStatus(false)
                    this.setState({
                        data: res.data,
                        count: res.count
                    });
                }
            })
    }

    getExcel() {
        this.props.changeLoaderStatus(true)
        const filters = this.getFetchFiltersData();
        api.getWarehouseHistoryExcel(filters)
            .then(res => {
                if (res) {
                    this.props.changeLoaderStatus(false)
                    window.open(res.link);
                }
            })
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(Number(nextProps.page) !== Number(this.props.page)) {
            this.fetchData(nextProps.page);
        }
    }

    componentDidMount() {

    }

    render() {
        return <WarehouseHistory
            getTranslate={this.props.getTranslate}
            params={this.props.params}
            withWarehouse={this.props.withWarehouse}
            withoutPagination={this.props.withoutPagination}
            withoutFilters={this.props.withoutFilters}
            getExcelHandler={this.getExcel}
            changeValue={this.props.changeValue}
            getData={(value) => this.fetchData(value)}
            form={this.props.form}
            data={this.state.data}
            changeProductsPaginationsFormValueData={this.props.changeProductsPaginationsFormValueData}
            paginationHandler={() => null}
            paginationDataCount={this.state.count}
            paginationName={'warehouse_history'}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeWarehouseHistoryFilterFormValueAC(input, value)),
        changeLoaderStatus: (status) => dispatch(changeLoaderStatusAC(status)),
        changeProductsPaginationsFormValueData: () => dispatch(changeProductsPaginationsFormValueDataAC('warehouse_history', 'page', 1)),
    }
}

let mapStateToProps = (state) => {
    return {
        type_name: state.main.warehouse_action_type,
        form: state.main.warehouseHistoryFiltersForm,
        page: state.main.pagination.warehouse_history.page,
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(WarehouseHistoryContainer);