import React from 'react';
import PageTitle from "../../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './AttributeForm.css';

const AttributeForm = (props) => {
    return (
        <div className="content-wrapper">
            <PageTitle title={'attribute_form'}/>
            <section className="content">
                <form>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header card_header_form_save">
                                <h3 className="card-title">{props.getTranslate('crm_form_add', 'form_add_main_info_title')}</h3>
                                <button className={"btn btn-success"}
                                        type={'button'}
                                        onClick={() => {
                                            if(props.isEdit) {
                                                props.editAttribute(props.form);
                                            } else {
                                                props.createAttribute(props.form);
                                            }
                                        }}
                                >{props.getTranslate('crm_form_add', 'form_add_button_create')}</button>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-group">
                                        <label>Назва атрибуту:</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group">
                                        {props.languages && props.languages.map(e => {
                                            return <div className="input-group mb-3">
                                                <label>{e.code}:</label>
                                                <input className={'form-control'}
                                                       value={props.form && props.form.name && props.form.name.filter(name => Number(name.lang) === Number(e.id))[0] && props.form.name.filter(name => Number(name.lang) === Number(e.id))[0].name}
                                                       onChange={(event) => {
                                                           let form_name = [...props.form.name];
                                                           form_name = form_name.map(f => {
                                                               if(Number(e.id) === Number(f.lang)) {
                                                                   f.name = event.target.value;
                                                               }
                                                               return f;
                                                           })
                                                           props.changeValue('name', form_name);
                                                       }}
                                                       required
                                                       placeholder={props.getTranslate('crm_form_add', 'form_add_title')}
                                                />
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <label>Тип:</label>
                                            <select className={'form-control'}
                                                    value={props.form && props.form.type}
                                                    defaultValue={props.form && props.form.type}
                                                    onChange={(event) => props.changeValue('type', event.target.value)}
                                                    required>
                                                <option value="1">SELECT</option>
                                                <option value="2">INPUT</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {Number(props.form.type) === 1 && <div className="card">
                            <div className="card-header card_header_form_save">
                                {/*<h3 className="card-title">{props.getTranslate('crm_form_add', 'form_add_main_info_title')}</h3>*/}
                                <h3 className="card-title">{props.getTranslate('crm_form_add', 'form_add_attribute_value')}</h3>
                                <button type={'button'}
                                        className={"btn btn-success"}
                                        onClick={() => {
                                            let form_values_add = [...props.form.values];
                                            let id = null;
                                            if(form_values_add.length === 0) {
                                                id = 1;
                                            } else {
                                                id = Math.max.apply(null, [...form_values_add].map( (value) => value.id )) + 1;
                                            }
                                            let new_form_values = {
                                                id: id,
                                                name: []
                                            };
                                            props.languages && props.languages.forEach(lang => {
                                                new_form_values.name.push({
                                                    lang: lang.id,
                                                    name: ''
                                                })
                                            })
                                            form_values_add.push(new_form_values);
                                            props.changeValue('values', form_values_add);
                                        }}
                                >+</button>
                            </div>
                            <div className="card-body">
                                {props.form && props.form.values && props.form.values.map(main_value => {
                                    return <>
                                        {/*<div className="row">*/}
                                        {/*    <div className="form-group">*/}
                                        {/*        <button className={"btn btn-danger form_minus_button-attribute"}*/}
                                        {/*                type={'button'}*/}
                                        {/*                onClick={() => {*/}
                                        {/*                    let form_values_del = [...props.form.values];*/}
                                        {/*                    debugger*/}
                                        {/*                    form_values_del = form_values_del.filter(d_val => Number(d_val.id) !== Number(main_value.id));*/}
                                        {/*                    debugger*/}
                                        {/*                    props.changeValue('values', form_values_del);*/}
                                        {/*                }}*/}
                                        {/*        >-</button>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="row attribute_item_value">
                                            <div className="col-md-6"><div className="form-group">
                                                {props.languages && props.languages.map(e => {
                                                    return <div className="input-group mb-3">
                                                        <label>{e.code}:</label>
                                                        <input className={'form-control'}
                                                               value={main_value.name.filter(name => Number(name.lang) === Number(e.id))[0] &&main_value.name.filter(name => Number(name.lang) === Number(e.id))[0].name}
                                                               onChange={(event) => {
                                                                   let form_values = [...props.form.values];
                                                                   form_values = form_values
                                                                       .map(value => {
                                                                           if(Number(value.id) === Number(main_value.id)) {
                                                                               value.name && value.name.map(v_name => {
                                                                                   if(Number(v_name.lang) === Number(e.id)) {
                                                                                       v_name.name = event.target.value;
                                                                                   }
                                                                                   return v_name;
                                                                               })
                                                                           }
                                                                           return value;
                                                                       })
                                                                   props.changeValue('values', form_values);
                                                               }}
                                                               required
                                                        />
                                                    </div>
                                                })}
                                            </div></div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <button className={"btn btn-danger form_minus_button-attribute"}
                                                            type={'button'}
                                                            onClick={() => {
                                                                let form_values_del = [...props.form.values];
                                                                form_values_del = form_values_del.filter(d_val => Number(d_val.id) !== Number(main_value.id));
                                                                props.changeValue('values', form_values_del);
                                                            }}
                                                    >-</button>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                }).reverse()}


                            </div>
                        </div>}

                    </div>
                </form>
            </section>
        </div>

    );
}

export default AttributeForm;


// let asdf = <div className="content-wrapper">
//     <PageTitle title={'attribute_form'}/>
//     <section className="content">
//         <div className="container-fluid">
//             <div className="card">
//                 <div className="card-header">
//                     <h3 className="card-title">{props.getTranslate('crm_form_add','form_add_main_info_title')}</h3>
//                     <button className={"btn btn-success"} type={'submit'}>{props.getTranslate('crm_form_add','form_add_button_create')}</button>
//                 </div>
//                 <form onSubmit={(event) => {
//                     event.preventDefault();
//                     props.createAttribute({...props.form});
//                 }}>
//                     <div className="card-body">
//                         <div className="row">
//                             {props.languages && props.languages.map(e => {
//                                 return <>
//                                     <div className="col-sm-3">
//                                         <div className="form-group">
//                                             <div className="input-group mb-3">
//                                                 <label>{e.code}:</label>
//                                                 <input className={'form-control'}
//                                                        value={props.form && props.form[e.code] && props.form[e.code].name}
//                                                        onChange={(event) => props.changeValue('name', event.target.value, e.code)}
//                                                        required
//                                                        placeholder={props.getTranslate('crm_form_add','form_add_title')}
//                                                 />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </>
//                             })}
//                         </div>
//                     </div>
//                     <div className="card-body">
//                         <div className="row">
//                             {props.languages && props.languages.map(e => {
//                                 return <div className="col-md-3">
//                                     {props.form && props.form[e.code] && props.form[e.code].values && props.form[e.code].values.map(v => {
//                                         return  <>
//                                             <div className="col-sm-12">
//                                                 <div className="form-group">
//                                                     <div className="input-group mb-3">
//                                                         <label>{e.code}:</label>
//                                                         <input className={'form-control'}
//                                                                value={v.name}
//                                                                onChange={(event) => {
//                                                                    let values = [...props.form[e.code].values];
//                                                                    values = values.map(val => {
//                                                                        if(Number(val.id) === Number(v.id)) {
//                                                                            val.name = event.target.value;
//                                                                        }
//                                                                        return val;
//                                                                    })
//                                                                    props.changeValue('values', values, e.code)
//                                                                }}
//                                                                required
//                                                                placeholder={props.getTranslate('crm_form_add','form_add_title')}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </>
//
//                                     })}
//                                     <div className="col-sm-3">
//                                         <button className={'btn btn-danger'}>-</button>
//                                     </div>
//                                 </div>
//                             })}
//                         </div>
//                         <div className="row">
//                             <div className="col-sm-3">
//                                 <div className="form-group">
//                                     <button className={'btn btn-success'}
//                                             onClick={() => {
//                                                 props.languages && props.languages.forEach(e => {
//                                                     let add_value = props.form[e.code] && props.form[e.code].values ? [...props.form[e.code].values] : [];
//                                                     let id = null;
//                                                     if(add_value.length === 0) {
//                                                         id = 1;
//                                                     } else {
//                                                         id = Math.max.apply(null, [...add_value].map( (value) => value.id )) + 1;
//                                                     }
//                                                     add_value.push({
//                                                         id: id,
//                                                         name: ''
//                                                     });
//                                                     props.changeValue('values', add_value, e.code)
//                                                 })
//                                             }}
//                                             type={'button'}
//                                     >+</button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </form>
//             </div>
//         </div>
//     </section>
// </div>;