import React from 'react';
import {Link} from "react-router-dom";
import ImagePDF from './../../../image/pdf.png'
import {formatSum} from "../../../store/reducers/MainReducer";

const CustomerDetail = (props) => {
    return (
        <div className="content-wrapper">
            <section className="content">
                <div className="container-fluid">
                    {props.customer &&
                    <div className={"product_container"}>
                        <h2 className={"main_page_title"}>
                            {props.getTranslate('crm_customer', 'customer_title')}: {props.customer.surname} {props.customer.name} {props.customer.middle_name}
                        </h2>
                        <div className="card">
                            <div className="card-header account_table_head">
                                <h3 className="card-title">{props.getTranslate('crm_main', 'table_main_info_title')}</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">ID</span>
                                                <span
                                                    className="info-box-number text-center text-muted mb-0">{props.customer.id}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span
                                                    className="info-box-text text-center text-muted">{props.getTranslate('crm_main', 'email')}</span>
                                                <span
                                                    className="info-box-number text-center text-muted mb-0">{props.customer.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span
                                                    className="info-box-text text-center text-muted">{props.getTranslate('crm_main', 'phone')}</span>
                                                <span
                                                    className="info-box-number text-center text-muted mb-0">{props.customer.phone}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span
                                                    className="info-box-text text-center text-muted">{props.getTranslate('crm_main', 'date')}</span>
                                                <span
                                                    className="info-box-number text-center text-muted mb-0">{props.customer.date}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {props.customer.partner && Number(props.customer.partner) === 1 && props.customer.partner_info && props.customer.partner_info[0] ?
                                    <div className="row">
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span className="info-box-text text-center text-muted">{props.getTranslate('crm_main', 'bonus')}</span>
                                                <span
                                                    className="info-box-number text-center text-muted mb-0">{props.customer.partner_info[0].bonus ? props.customer.partner_info[0].bonus : 0}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span
                                                    className="info-box-text text-center text-muted">{props.getTranslate('crm_main', 'partner_product_sale')}</span>
                                                <span
                                                    className="info-box-number text-center text-muted mb-0">{props.customer.partner_info[0].product_sale}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span
                                                    className="info-box-text text-center text-muted">{props.getTranslate('crm_main', 'partner_rang')}</span>
                                                <span className="info-box-number text-center text-muted mb-0">
                                                    {props.directoryData && props.directoryData.customer_partner_rang && props.customer.partner_info && props.directoryData.customer_partner_rang.filter(c => {
                                                        return Number(c.id) === Number(props.customer.partner_info[0].rang)
                                                    })[0] && props.directoryData.customer_partner_rang.filter(c => {
                                                        return Number(c.id) === Number(props.customer.partner_info[0].rang)
                                                    })[0].name }</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className="info-box bg-light">
                                            <div className="info-box-content">
                                                <span
                                                    className="info-box-text text-center text-muted">{props.getTranslate('crm_main', 'partner_specialty')}</span>
                                                <span className="info-box-number text-center text-muted mb-0">
                                                    {props.directoryData && props.directoryData.specialty && props.customer.partner_info && props.directoryData.specialty.filter(c => {
                                                        return Number(c.id) === Number(props.customer.partner_info[0].specialty)
                                                    })[0].name }</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    : ""}
                                {props.customer && props.customer.address && props.customer.address[0] && props.customer.address[0].region > 0 ?
                                    <div className="row">
                                        <div className="col-12 customer_sub_title">{props.getTranslate('crm_customer', 'customer_address_title')}</div>
                                        {props.customer && props.customer.address && props.customer.address.map(e => {
                                            return <div className="col-4">
                                                <div className="info-box bg-light">
                                                    <div className="info-box-content">
                                            <span className="info-box-number text-center text-muted mb-0">
                                                {props.directoryData && props.directoryData.regions && props.directoryData.regions.filter(c => {
                                                    return Number(c.id) === Number(e.region)
                                                })[0].name } обл.,
                                                м. {props.directoryData && props.directoryData.cities && props.directoryData.cities.filter(c => {
                                                return Number(c.id) === Number(e.city)
                                            })[0].name }, вул. {e.street}, № {e.number_build}
                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                : ""}
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header account_table_head">
                            <h3 className="card-title">{props.getTranslate('crm_main', 'partner_orders')}</h3>
                        </div>
                        <table className="table table-striped text-center">
                            <thead>
                            <th>ID</th>
                            <th>Сума</th>
                            <th>Статус</th>
                            <th>Дата</th>
                            <th></th>
                            </thead>
                            <tbody>
                            {props.customer && props.customer.orders && props.customer.orders.map(e => {
                                    return <>
                                        <tr onClick={() => props.toggleActive(e.id)}>
                                            <td>{e.id}</td>
                                            <td>₴ {formatSum(e.total)}</td>
                                            <td>
                                                {props.directoryData && props.directoryData.status && props.directoryData.status.filter(c => {
                                                    return Number(c.id) === Number(e.status)
                                                })[0].value}
                                            </td>
                                            <td>{e.date}</td>
                                            <td>
                                                <i className="fas fa-angle-down">
                                                </i>
                                            </td>
                                        </tr>
                                        {Number(props.orderPartnerOpen) === Number(e.id) ?
                                            <tr>
                                                <td colSpan={5}>
                                                    <table className={'table table-bordered text-center'}>
                                                        <thead className={"account_table_head"}>
                                                        <tr>
                                                            <th>Назва</th>
                                                            <th>Артикул</th>
                                                            <th>Виробник</th>
                                                            <th>Кількість</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {e.products && e.products.map(p => {
                                                            return <tr>
                                                                <td><Link to={"/products/" + p.alias}>{p.name}</Link></td>
                                                                <td>{p.model}</td>
                                                                <td>
                                                                    {props.manufacturers && props.manufacturers.filter(c => {
                                                                        return Number(c.id) === Number(p.manufacturer)
                                                                    })[0].name}
                                                                </td>
                                                                <td>{p.count}</td>
                                                            </tr>
                                                        })}
                                                        {props.user && props.user.orders
                                                        && Number(e.region) > 0
                                                        && Number(e.city) > 0 ?
                                                            <tr>
                                                                <td colSpan={"4"}>
                                                                    <div className="info-box bg-light">
                                                                        <div className="info-box-content">
                                                                <span
                                                                    className="info-box-number text-center text-muted mb-0">
                                                                    {props.directoryData && props.directoryData.regions && props.directoryData.regions.filter(c => {
                                                                        return Number(c.id) === Number(e.region)
                                                                    })[0].name} обл.,
                                                                    {/*    {props.directoryData && props.directoryData.districts && props.directoryData.districts.filter(b => {*/}
                                                                    {/*    return Number(b.id) === Number(e.district)*/}
                                                                    {/*})[0] && props.directoryData.districts.filter(b => {*/}
                                                                    {/*    return Number(b.id) === Number(e.district)*/}
                                                                    {/*})[0].name} р., */}
                                                                    м. {props.directoryData && props.directoryData.cities && props.directoryData.cities.filter(c => {
                                                                    return Number(c.id) === Number(e.city)
                                                                })[0] && props.directoryData.cities.filter(c => {
                                                                    return Number(c.id) === Number(e.city)
                                                                })[0].name},
                                                                    {e.id_delivery_type && Number(e.id_delivery_type) === 1 ?
                                                                        <>
                                                                            вул. {e.street} № {e.street_house}
                                                                        </>
                                                                        : e.id_delivery_type && Number(e.id_delivery_type) === 2 ?
                                                                            <>
                                                                                {props.directoryData && props.directoryData.np_warehouse && props.directoryData.np_warehouse.filter(c => {
                                                                                    return Number(c.id) === Number(e.np_warehouse)
                                                                                })[0] && props.directoryData.np_warehouse.filter(c => {
                                                                                    return Number(c.id) === Number(e.np_warehouse)
                                                                                })[0].name}
                                                                            </>
                                                                            : ""}
                                                                </span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            : ""}
                                                        {e.documents && e.documents.map(doc => {
                                                            return <>
                                                                {doc.type && doc.type === "receipt" ?
                                                                    <tr>
                                                                        <td colSpan={"4"}>
                                                                            <a className={"account_order_invoice_link"} href={doc.link} target={"_blank"}>Завантажити рахунок <img alt={""} src={ImagePDF} /></a>
                                                                        </td>
                                                                    </tr>
                                                                    : ""
                                                                }
                                                            </>
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            :
                                            ''
                                        }
                                    </>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {props.customer.partner && Number(props.customer.partner) === 1 ?
                    <>
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header account_table_head">
                                    <h3 className="card-title">{props.getTranslate('crm_main', 'partner_product')}</h3>
                                </div>
                                <table className={'table table-bordered text-center'}>
                                    <thead>
                                    <tr>
                                        <th>Назва</th>
                                        <th>Артикул</th>
                                        <th>Виробник</th>
                                        {/*<th>Ціна</th>*/}
                                        <th>Ціна за 1</th>
                                        <th>Кількість</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.customer && props.customer.partner_products && props.customer.partner_products.map(p => {
                                        return <tr>
                                            <td><Link to={"/products/" + p.alias}>{p.name}</Link></td>
                                            <td>{p.model}</td>
                                            <td>
                                                {props.manufacturers && props.manufacturers.filter(c => {
                                                    return Number(c.id) === Number(p.manufacturer)
                                                })[0] && props.manufacturers.filter(c => {
                                                    return Number(c.id) === Number(p.manufacturer)
                                                })[0].name}
                                            </td>
                                            {/*<td>₴ {p.price}</td>*/}
                                            <td>₴ {p.price_one}</td>
                                            <td>{p.count}</td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header account_table_head">
                                    <h3 className="card-title">{props.getTranslate('crm_main', 'partner_testing')}</h3>
                                </div>
                                <table className="table table-striped">
                                    <thead>
                                    <th>{props.getTranslate('crm_main', 'product')}</th>
                                    <th>{props.getTranslate('crm_main', 'price')}</th>
                                    <th>{props.getTranslate('crm_main', 'count')}</th>
                                    <th>{props.getTranslate('crm_main', 'bonus')}</th>
                                    <th>{props.getTranslate('crm_main', 'date')}</th>
                                    </thead>
                                    <tbody>
                                    {props.customer && props.customer.partner_products_sale && props.customer.partner_products_sale.map(p => {
                                        return <tr>
                                            <td><Link to={"/products/" + p.alias}>{p.name}</Link></td>
                                            <td>₴ {formatSum(p.price)}</td>
                                            <td>{p.count}</td>
                                            <td>₴ {p.bonus}</td>
                                            <td>{p.date}</td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {props.customer.partner_info && props.customer.partner_info[0] ?
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header account_table_head">
                                    <h3 className="card-title">{props.getTranslate('crm_main', 'partner_info_payment_data')}</h3>
                                </div>
                                <div className="row customer_row_margin">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Серія та номер паспорта</label>
                                            <input type="text" name="pasport_name" className="form-control"
                                                   value={props.customer.partner_info[0].pasport_series} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label>Назва органу, що видав паспорт</label>
                                            <input type="text" name="pasport_organization" className="form-control"
                                                   value={props.customer.partner_info[0].pasport_organization} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label>Дата видачі паспорта</label>
                                            <input type="text" name="pasport_data" className="form-control"
                                                   value={props.customer.partner_info[0].pasport_data} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Реєстраційний номер облікової картки платника податків (ІПН)</label>
                                            <input type="text" name="ipn" className="form-control" value={props.customer.partner_info[0].ipn}
                                                   disabled />
                                        </div>
                                        <div className="form-group">
                                            <label>Назва Банку</label>
                                            <input type="text" name="bank_name" className="form-control"
                                                   value={props.customer.partner_info[0].bank_name} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label>Код МФО банку</label>
                                            <input type="text" name="bank_code" className="form-control" value={props.customer.partner_info[0].bank_code}
                                                   disabled />
                                        </div>
                                        <div className="form-group">
                                            <label>Номер банківського рахунку в форматі IBAN</label>
                                            <input type="text" name="bank_iban" className="form-control"
                                                   value={props.customer.partner_info[0].bank_iban} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            : ""}
                    </>
                    : " "}
            </section>
        </div>
    );
}

export default CustomerDetail;