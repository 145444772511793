import React from 'react';
import {connect} from "react-redux";
import ApiService from "../../api/api";
import {
    changeRelatedProductsValueAC,
    changeWarehousesFormValueAC,
    getAllProductLimitedThunk, getAllProductsThunk,
    getRelatedProductsThunk
} from "../../store/reducers/MainReducer";
import {withLang} from "../HOC/withLang/withLang";
import {compose} from "redux";
import Sliders from "./Sliders";



class SlidersContainer extends React.Component {

    apiService = new ApiService();

    constructor(props){
        super(props);
        this.saveRelatedProducts = this.saveRelatedProducts.bind(this)
    }

    saveRelatedProducts(relatedProducts)  {
        this.apiService.saveRelatedProducts(relatedProducts)
            .then(res => {
                this.props.getRelatedProductsThunk()
            })
    }

    componentDidMount() {
        this.props.getRelatedProductsThunk()
        this.props.getAllProductsThunk()
    }

    render() {
        return <Sliders
            relatedProducts={this.props.relatedProducts}
            getTranslate={this.props.getTranslate}
            products={this.props.products}
            changeValue={this.props.changeValue}
            saveRelatedProducts={this.saveRelatedProducts}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getRelatedProductsThunk: () => dispatch(getRelatedProductsThunk()),
        getAllProductsThunk: () => dispatch(getAllProductLimitedThunk()),
        changeValue: (value) => dispatch(changeRelatedProductsValueAC(value)),
    }
}

let mapStateToProps = (state) => {
    return {
        relatedProducts: state.main.related_products,
        products: state.main.products,
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(SlidersContainer);