import ApiService from "../../api/api";
import {changeLoaderStatusAC} from './MainReducer';

const SET_USER_AUTH = 'SET_USER_AUTH';
const CHANGE_AUTH_FORM_VALUE = 'CHANGE_AUTH_FORM_VALUE';
const LOGOUT = 'LOGOUT';

const apiService = new ApiService();

let initialState = {
    user: {
        id: localStorage.getItem('medbuy_crm_auth_token'),
        name: null,
        firm: null,
        email: null,
        phone: null,
        role_id: null,
        role_name: null,
    },
    authForm: {
        login: null,
        pass:  null,
        isFailure: false
    },
    isAuth: false
};

const AuthReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_USER_AUTH:
            return {
                ...state,
                user: {
                    ...state.user,
                    name: action.user.name,
                    id: action.user.id,
                    email: action.user.email,
                    firm: action.user.firm,
                    phone: action.user.phone,
                    role_id: action.user.role.id,
                    role_name: action.user.role.name,

                },
                isAuth: true
            };
        case CHANGE_AUTH_FORM_VALUE:
            let authForm = {...state.authForm};
            authForm[action.input] = action.value;
            return {
                ...state,
                authForm: authForm
            };
        case LOGOUT:
            localStorage.removeItem('medbuy_crm_user_token');
            return {
                ...state,
                user: null,
                isAuth: false
            };
        default:
            return state
    }
};

export const setUserAuthAC = (user) => {
    return {
        type: SET_USER_AUTH,
        user: user
    }
};
export const changeAuthFormValueAC = (input, value) => {
    return {
        type: CHANGE_AUTH_FORM_VALUE,
        input: input,
        value: value
    }
};
export const logoutAC = () => {
    return {
        type: LOGOUT
    }
};
export const getAuthDataThunk = (login, pass) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getAuth(login, pass)
        .then(token => {
            if(token) {
                localStorage.setItem('medbuy_crm_user_token', token);
                apiService.getAuthData(token)
                    .then(user => {
                        user && dispatch(setUserAuthAC(user));
                        dispatch(changeLoaderStatusAC(false));
                    })
            } else {
                dispatch(changeAuthFormValueAC('isFailure', true));
                dispatch(changeLoaderStatusAC(false));
            }
        })
}

export const checkAuthDataThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getAuthData()
        .then(user => {
            dispatch(changeLoaderStatusAC(false));
            user && dispatch(setUserAuthAC(user));
        })
}

export default AuthReducer;