import React from 'react';
import {connect} from "react-redux";
import './WarehouseActions.css';
import {withLang} from "../../HOC/withLang/withLang";
import {compose} from "redux";
import WarehouseActions from "./WarehouseActions";

class WarehouseActionsContainer extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {}

    render() {
        return <WarehouseActions
            type={this.props.type}
            getTranslate={this.props.getTranslate}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {}
}

let mapStateToProps = (state) => {
    return {}
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(WarehouseActionsContainer);