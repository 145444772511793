import React from 'react';

export const FilterItemInput = ({ title, value, index, onChangeHandler, type }) => {
    return <>
        <div className="col-md-4">
            <div className="form-group">
                <div className="input-group mb-3 form_block_input">
                    <label>
                        {title}
                    </label>
                    <input className={'form-control'}
                           value={value}
                           type={type}
                           onChange={(event) => onChangeHandler(index, event.target.value)}
                           required
                    />
                </div>
            </div>
        </div>
    </>;
}