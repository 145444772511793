import React from 'react';
import PageTitle from "../PageTitle/PageTitle";
import Select from "react-select";

const Sliders = ({relatedProducts, products, changeValue, saveRelatedProducts, getTranslate}) => {

    const changeRelatedProducts = (selectedOption, relatedProductId) => {
      changeValue(relatedProducts.map((e) => {
          if (Number(e?.id) === Number(relatedProductId)) {
              e.id_product = selectedOption?.value;
          }
          return e;
      }));
    };

    return (
        <div className="content-wrapper">
            <PageTitle title={'sliders'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card p-3">
                        <div className="row">
                            {relatedProducts && relatedProducts?.map((e) => {
                                return (
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="input-group col-md-3">
                                                <Select value={{
                                                    value: e?.id_product,
                                                    label: products?.find(product => Number(e.id_product) === Number(product?.id
                                                    ))?.name
                                                }}
                                                        onChange={(selectedOption) => changeRelatedProducts(selectedOption, e?.id)}
                                                        placeholder={'Оберіть товар для слайдеру'}
                                                        className={'form-select-container'}
                                                        options={products?.map(e => {
                                                            return {
                                                                value: e.id,
                                                                label: e.name
                                                            }
                                                        })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="row">
                            <div className="form-group">
                                <button
                                    className={"btn btn-success"}
                                    type={'submit'}
                                    onClick={() => saveRelatedProducts(relatedProducts)}
                                >
                                    {getTranslate('crm_form_add', 'form_add_button_create')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Sliders;