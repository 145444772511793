import React from 'react';
import {Link} from "react-router-dom";
import ImageLogo from "../../image/logo.png"

const AsideMenu = (props) => {
    return(
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="/" className="brand-link">
                {/*<span className="brand-text font-weight-light"><span className={"name_logo_1"}>MedBuy</span><span className={"name_logo_2"}>Online</span></span>*/}
                <img src={ImageLogo} />
            </a>
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <span className="fas fa-user"></span>
                    </div>
                    <div className="info">
                        <a href="#" className="d-block">{props.user.name}</a>
                    </div>
                </div>
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <span className="fas fa-user-tag"></span>
                    </div>
                    <div className="info">
                        <a href="/customers" className="d-block">{props.user.role_name}</a>
                    </div>
                </div>
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li className="nav-item menu-open">
                            <Link to={'/dashboard'}
                               className={'nav-link' + (props.menu === 'dashboard' ? ' active' : '')}
                               onClick={() => props.toggleMenu('dashboard')}>
                                   <i className="nav-icon fas fa-tachometer-alt"></i>
                                   <p>{props.getTranslate('crm_menu','main')}</p>
                            </Link>
                        </li>
                        <li className="nav-item menu-open">
                            <a href={'/products'}
                               className={'nav-link' + (props.menu === 'products' ? ' active' : '')}
                               onClick={() => props.toggleMenu('products')}>
                                    <i className="nav-icon fas fa-parachute-box"></i>
                                   <p>{props.getTranslate('crm_menu','products')}</p>
                            </a>
                        </li>
                        <li className="nav-item menu-open">
                            <Link to={'/warehouses/2'}
                               className={'nav-link' + (props.menu === 'in_stock' ? ' active' : '')}
                               onClick={() => props.toggleMenu('in_stock')}>
                                    <i className="nav-icon fas fa-layer-group"></i>
                                   <p>{props.getTranslate('crm_menu','in_stock')}</p>
                            </Link>
                        </li>
                        <li className="nav-item menu-open">
                            <Link to={'/orders'}
                               className={'nav-link' + (props.menu === 'orders' ? ' active' : '')}
                               onClick={() => props.toggleMenu('orders')}>
                                    <i className="nav-icon fas fa-clipboard-list"></i>
                                   <p>{props.getTranslate('crm_menu','orders')}</p>
                            </Link>
                        </li>
                        <li className={'dropdown_custom_item nav-item' + (props.menu === 'accounting' ? ' menu-open' : '')}>
                            <a
                                className={'nav-link' + (props.menu === 'accounting' ? ' active' : '')}
                                onClick={() => props.toggleMenu('accounting')}>
                                <i className="fas fa-calculator"></i>
                                <p>
                                    {props.getTranslate('crm_menu','accounting')}
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link to={'/customers_bonus'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','customer_bonus')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/partners_tests'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','partners_tests')}</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className={'dropdown_custom_item nav-item' + (props.menu === 'warehouse_list' ? ' menu-open' : '')}>
                            <a
                                className={'nav-link' + (props.menu === 'warehouse_list' ? ' active' : '')}
                                onClick={() => props.toggleMenu('warehouse_list')}>
                                <i className="fas fa-warehouse"></i>
                                <p>
                                    {props.getTranslate('crm_menu','warehouses')}
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link to={'/warehouses'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','list_warehouses')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/warehouses/history'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','history_warehouses')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/warehouses/reserved'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','warehouses_reserved')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/warehouses/receiving'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','moving_warehouses')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/warehouses/write_off'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','write_off_warehouses')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/warehouses/moving'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','receiving_warehouses')}</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item menu-open">
                            <a href={'/analytics'}
                               className={'nav-link' + (props.menu === 'analytics' ? ' active' : '')}
                               onClick={() => props.toggleMenu('analytics')}>
                                <i className="nav-icon fas fa-chart-line"></i>
                                <p>{props.getTranslate('crm_menu','analytics')}</p>
                            </a>
                        </li>
                        <li className={'dropdown_custom_item nav-item' + (props.menu === 'store' ? ' menu-open' : '')}>
                            <a
                                className={'nav-link' + (props.menu === 'store' ? ' active' : '')}
                                onClick={() => props.toggleMenu('store')}>
                                <i className="fas fa-cogs"> </i>
                                <p>
                                    {props.getTranslate('crm_menu','store')}
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                {/*<li className="nav-item">*/}
                                {/*    <Link to={'/warehouses'} className="nav-link">*/}
                                {/*        <i className="far fa-circle nav-icon text-info"></i>*/}
                                {/*        <p>{props.getTranslate('crm_menu','warehouses')}</p>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li className="nav-item">
                                    <Link to={'/manufacturers'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','manufacturer')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/customers'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','customer')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/categories'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','categories')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/sell_managers'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','sell_managers')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/sliders'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','sliders')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/tags'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','tags')}</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/attributes'} className="nav-link">
                                        <i className="far fa-circle nav-icon text-info"></i>
                                        <p>{props.getTranslate('crm_menu','attributes')}</p>
                                    </Link>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*    <Link to={'/customers'} className="nav-link">*/}
                                {/*        <i className="far fa-circle nav-icon text-danger"></i>*/}
                                {/*        <p>{props.getTranslate('crm_menu','customer')}</p>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    )
}

export default AsideMenu;
