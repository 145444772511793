import { CheckOutlined } from '@ant-design/icons';

export const ANALYTICS_TABLES_COLUMNS = {
    sales: [
        {
            title: 'Дата',
            dataIndex: 'date',
            width: 150,
        },
        {
            title: 'Замовлень',
            dataIndex: 'count',
            width: 150,
        },
        {
            title: 'Товарів',
            dataIndex: 'product_count',
        },
        {
            title: 'Сумма',
            dataIndex: 'total',
        },
    ],
    top_products: [
        {
            title: 'Назва',
            dataIndex: 'name'
        },
        {
            title: 'Модель товару',
            dataIndex: 'model'
        },
        {
            title: 'Всього продано',
            dataIndex: 'count'
        },
        {
            title: 'Акційна ціна',
            dataIndex: 'discount'
        }
    ],
    customers: [
        {
            title: 'ПІБ',
            dataIndex: 'full_name'
        },
        {
            title: 'Партнер',
            dataIndex: 'partner',
            render: partner => Number(partner) ? <CheckOutlined /> : '',
        },
        {
            title: 'Франчезі',
            dataIndex: 'franchisees',
            render: franchisees => Number(franchisees) ? <CheckOutlined /> : '',
        },
        {
            title: 'Кількість замовлень',
            dataIndex: 'orders_count'
        },
        {
            title: 'Замовлень на сумму',
            dataIndex: 'orders_total'
        }
    ],
    movement_goods: [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: 'Назва',
            dataIndex: 'name',
        },
        {
            title: 'Артикул',
            dataIndex: 'model',
        },
        {
            title: 'Ковід',
            dataIndex: 'covid',
            render: (covid) => Boolean(covid) ? <CheckOutlined /> : '',
        },
        {
            title: 'Зараховано',
            dataIndex: 'count_come_stock'
        },
        {
            title: 'Списано з складу',
            dataIndex: 'count_out_stock_warehouse'
        },
        {
            title: 'Магазин',
            dataIndex: 'count_out_stock_store'
        },
        {
            title: 'Партнери',
            dataIndex: 'count_out_stock_partners'
        },
        {
            title: 'Франчезі',
            dataIndex: 'count_out_stock_franchisees'
        },
    ]
};
export const ANALYTICS_LINE_CHART_SETTINGS = {
    sales: {
        x: 'date',
        y: 'count'
    }
}
export const ANALYTICS_FILTERS = {
    sales: [
        {
            filterType: 'input',
            title: 'Дата (з)',
            type: 'date',
            index: 'date_from'
        },
        {
            filterType: 'input',
            title: 'Дата (до)',
            type: 'date',
            index: 'date_to'
        },
        {
            filterType: 'select',
            title: 'Статус',
            index: 'status',
            getData: (state) => state.main.directoryData.status.map(e => {
                return { id: e.id, label: e.value};
            })
        },
        // {
        //     filterType: 'select',
        //     title: 'Статус (Ручний)',
        //     index: 'status',
        //     data: [
        //         { id: 1, label: 'Так'},
        //         { id: 1, label: 'Так'},
        //         { id: 1, label: 'Так'},
        //     ]
        // },
    ],
    movement_goods: [
        {
            filterType: 'input',
            title: 'Дата (з)',
            type: 'date',
            index: 'date_from'
        },
        {
            filterType: 'input',
            title: 'Дата (до)',
            type: 'date',
            index: 'date_to'
        }
    ]
};