import React from 'react';
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './Manufacturers.css';

const Manufacturers = (props) => {

    return(
        <div className="content-wrapper">
            <PageTitle title={'manufacturers'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-12 container_button">
                        <Link className={"btn btn-success"} to={"/manufacturer/add"}><i className="fas fa-plus-circle"></i></Link>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card">
                        <table className="table table-striped">
                            <thead>
                            <th>{props.getTranslate('crm_main','ID')}</th>
                            <th>{props.getTranslate('crm_main','name')}</th>
                            <th>{props.getTranslate('crm_main','date')}</th>
                            <th>{props.getTranslate('crm_main','action')}</th>
                            </thead>
                            <tbody>
                            {props.manufacturers && props.manufacturers.map(e => {
                                return <>
                                    <tr>
                                        <td>{e.id}</td>
                                        <td>{e.name}</td>
                                        <td>{e.date}</td>
                                        <td className={"action_button_td"}>
                                            <Link className={'btn btn-info'} to={'/manufacturer/edit/' + e.id}>
                                                <i className="fas fa-pen"></i>
                                            </Link>
                                            <button className={'btn btn-danger'} onClick={() => props.deleteManufacturer(e.id)}>
                                                <i className="fas fa-minus-circle"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Manufacturers;