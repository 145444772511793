import React from 'react';
import { Table } from "antd";
import {ANALYTICS_TABLES_COLUMNS} from "../constants";

const TableAnalytics = ({ type, data }) => {
    return <div>
        <Table columns={ANALYTICS_TABLES_COLUMNS[type]} dataSource={data} scroll={{ y: '65vh' }} />
    </div>;
}

export default TableAnalytics;