import React from 'react';
import {connect} from "react-redux";
import './WarehouseDetail.css';
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import {
    getDirectoryDataThunk,
    getWarehouseThunk,
    setWarehouseActiveTabAC
} from "../../../store/reducers/MainReducer";
import WarehouseDetail from "./WarehouseDetail";
import Tags from "../../Tags/Tags";




class WarehouseDetailContainer extends React.Component {

    constructor(props){
        super(props);
        this.getWarehouseSearch = this.getWarehouseSearch.bind(this);
        this.state = {
            search: null
        }
    }

    componentDidMount() {
        this.props.directory();
        this.props.getWarehouseThunk(this.props.uri.match.params.id)
    }
    getWarehouseSearch() {
        const products = this.props.warehouse && this.props.warehouse.products ? (this.state.search ? this.props.warehouse.products.filter(e => e.name.toLowerCase().includes(this.state.search.toLowerCase()) || e.model.toLowerCase().includes(this.state.search.toLowerCase())) : this.props.warehouse.products) : [];
        return {
            ...this.props.warehouse,
            products: products
        };
    }

    render() {
        return <WarehouseDetail
            warehouse={this.getWarehouseSearch()}
            getTranslate={this.props.getTranslate}
            directoryData={this.props.directoryData}
            activeTab={this.props.activeTab}
            setActiveTab={this.props.setActiveTab}
            searchInput={this.state.search}
            searchInputChange={(value) => this.setState({search: value})}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
        getWarehouseThunk: (id) => dispatch(getWarehouseThunk(id)),
        setActiveTab: (tab) => dispatch(setWarehouseActiveTabAC(tab)),
    }
}

let mapStateToProps = (state) => {
    return {
        directoryData: state.main.directoryData,
        warehouse: state.main.warehouse,
        activeTab: state.main.warehouseTab,
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(WarehouseDetailContainer);