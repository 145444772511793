import React from 'react';
import PageTitle from "../../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import UploadFileContainer from "../../UploadFile/UploadFileContainer";
import Select from "react-select";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ProductsAdd = (props) => {

    const validateForm = (form) => {

        let errors = [];

        form.name.forEach(e => {
           if(Number(e.lang) === 1 && e.name.length < 1) {
               errors.push(<p className={'text-danger'}>Назва товару UA - не вказана</p>);
           }
        });

        return {
            isCorrect: errors.length > 0 ? false : true,
            errors: errors
        }
    }

    return (
        <div className="content-wrapper">
            <PageTitle title={ props.isEdit
                ? 'products_edit'
                : 'products_add'
            }/>
            <section className="content">
                <div className="container-fluid">
                    <form>
                        <div className="card card-primary card-outline card-tabs">
                            <div className="card-header p-0 pt-1 border-bottom-0 main_style_tabs_container">
                                <ul className="nav nav-tabs main_style_tabs" role="tablist">
                                    <li className="nav-item" onClick={() => props.setActiveTab(1)}>
                                        <a className={'nav-link' + (props.activeTab === 1 ? ' active' : '')}>{props.getTranslate('crm_product_add', 'language_data')}</a>
                                    </li>
                                    <li className="nav-item" onClick={() => props.setActiveTab(2)}>
                                        <a className={'nav-link' + (props.activeTab === 2 ? ' active' : '')}>{props.getTranslate('crm_product_add', 'basic_data')}</a>
                                    </li>
                                    <li className="nav-item" onClick={() => props.setActiveTab(3)}>
                                        <a className={'nav-link' + (props.activeTab === 3 ? ' active' : '')}>{props.getTranslate('crm_product_add', 'product_characteristic')}</a>
                                    </li>
                                    <li className="nav-item" onClick={() => props.setActiveTab(4)}>
                                        <a className={'nav-link' + (props.activeTab === 4 ? ' active' : '')}>{props.getTranslate('crm_product_add', 'product_image')}</a>
                                    </li>
                                    <li className="nav-item" onClick={() => props.setActiveTab(5)}>
                                        <a className={'nav-link' + (props.activeTab === 5 ? ' active' : '')}>{props.getTranslate('crm_product_add', 'product_file')}</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content" id="custom-tabs-three-tabContent">
                                    <div className={'tab-pane fade' + (props.activeTab === 1 ? ' show active' : '')}>
                                        <ProductsBasicAdd setActiveTab={props.setActiveTab}
                                                          activeTab={props.activeTab}
                                                          form={props.form}
                                                          changeValue={props.changeValue}
                                                          getTranslate={props.getTranslate}
                                                          languages={props.languages}
                                                          setActiveTabLang={props.setActiveTabLang}
                                                          activeTabLang={props.activeTabLang}
                                        />
                                    </div>
                                </div>
                                <div className="tab-content" id="custom-tabs-three-tabContent">
                                    <div className={'tab-pane fade' + (props.activeTab === 2 ? ' show active' : '')}>
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <div className="card-body product_basic_section">
                                                    <div>
                                                        <h3 className={"product_add_title_in_tab"}>Основні дані</h3>
                                                    </div>
                                                    <div className={"row"}>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="FormModel">{props.getTranslate('crm_form_add', 'form_add_product_model')}</label>
                                                                <input className={'form-control'}
                                                                       value={props.form && props.form.model}
                                                                       onChange={(event) => props.changeValue('model', event.target.value)}
                                                                       required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label>{props.getTranslate('crm_form_add', 'form_add_product_manufacturer')}</label>
                                                                <Select value={props.form && props.form.manufacturer && props.manufacturers ? props.manufacturers
                                                                            .filter(e => Number(e.id) === Number(props.form.manufacturer))
                                                                            .map(e => {
                                                                                return {
                                                                                    value: e.id,
                                                                                    label: e.name
                                                                                }
                                                                            })[0]
                                                                            : ''
                                                                        }
                                                                        onChange={(event) => props.changeValue('manufacturer', event.value)}
                                                                        placeholder={props.getTranslate('crm_form_add', 'form_add_product_manufacturer')}
                                                                        defaultValue={props.form && props.form.manufacturer ? props.form.manufacturer.label : ''}
                                                                        className={'form-select-container'}
                                                                        required={'required'}
                                                                        options={props.manufacturers && props.manufacturers.map(e => {
                                                                            return {
                                                                                value: e.id,
                                                                                label: e.name
                                                                            }
                                                                        })
                                                                        }/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="FormInPack">{props.getTranslate('crm_form_add', 'form_add_product_in_pack')}</label>
                                                                <input className={'form-control'}
                                                                       id={"FormInPack"}
                                                                       value={props.form && props.form.in_pack}
                                                                       onChange={(event) => props.changeValue('in_pack', event.target.value)}
                                                                       required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="FormAlias">{props.getTranslate('crm_form_add', 'form_add_product_alias')}</label>
                                                                <input className={'form-control'}
                                                                       id={"FormAlias"}
                                                                       value={props.form && props.form.alias}
                                                                       onChange={(event) => props.changeValue('alias', event.target.value)}
                                                                       required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h3 className={"product_add_title_in_tab"}>Вартість</h3>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-1">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="FormCurrency">{props.getTranslate('crm_form_add', 'form_add_product_currency')}</label>


                                                                <Select value={props.form && props.form.currency && props.directoryData.currencies ? props.directoryData.currencies
                                                                        .filter(e => Number(e.id) === Number(props.form.currency))
                                                                        .map(e => {
                                                                            return {
                                                                                value: e.id,
                                                                                label: e.name
                                                                            }
                                                                        })[0]
                                                                    : ''
                                                                }
                                                                        onChange={(event) => props.changeValue('currency', event.value)}
                                                                        placeholder={props.getTranslate('crm_form_add', 'form_add_product_currency')}
                                                                        className={'form-select-container'}
                                                                        required={'required'}
                                                                        options={props.directoryData.currencies && props.directoryData.currencies.map(e => {
                                                                            return {
                                                                                value: e.id,
                                                                                label: e.name+" "+e.text
                                                                            }
                                                                        })
                                                                        }/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="FormPrice">{props.getTranslate('crm_form_add', 'form_add_product_price')}</label>
                                                                <input className={'form-control'}
                                                                       id={"FormPrice"}
                                                                       value={props.form && props.form.price}
                                                                       onChange={(event) => props.changeValue('price', event.target.value)}
                                                                       required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="FormPrice">{props.getTranslate('crm_form_add', 'form_add_product_price_one')}</label>
                                                                <input className={'form-control'}
                                                                       id={"FormPrice"}
                                                                       value={props.form && props.form.price_one}
                                                                       onChange={(event) => props.changeValue('price_one', event.target.value)}
                                                                       required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="FormPDV">{props.getTranslate('crm_form_add', 'form_add_product_pdv')}</label>
                                                                <input className={'form-control'}
                                                                       id={"FormPDV"}
                                                                       value={props.form && props.form.pdv}
                                                                       onChange={(event) => props.changeValue('pdv', event.target.value)}
                                                                       required
                                                                       placeholder={"%"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h3 className={"product_add_title_in_tab"}>Особливості</h3>
                                                    </div>
                                                    <div className={"row"}>
                                                        <div className="col-md-2">
                                                            <div className="form-group custom_form_checkbox">
                                                                <label
                                                                    htmlFor="FormName">{props.getTranslate('crm_form_add', 'form_add_product_active')}</label>
                                                                <input className={'form-control'}
                                                                       value={props.form && props.form.active}
                                                                       checked={Number(props.form.active) ? 'checked' : ''}
                                                                       onChange={(event) => props.changeValue('active', event.target.checked ? 1 : 0)}
                                                                       type={'checkbox'}
                                                                       required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group custom_form_checkbox">
                                                                <label
                                                                    htmlFor="FormName">{props.getTranslate('crm_form_add', 'form_add_product_preorder')}</label>
                                                                <input className={'form-control'}
                                                                       value={props.form && props.form.preorder}
                                                                       onChange={(event) => props.changeValue('preorder', event.target.checked ? 1 : 0)}
                                                                       type={'checkbox'}
                                                                       checked={Number(props.form.preorder) ? 'checked' : ''}
                                                                       required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group custom_form_checkbox">
                                                                <label
                                                                    htmlFor="FormName">{props.getTranslate('crm_form_add', 'form_add_product_partner_access')}</label>
                                                                <input className={'form-control'}
                                                                       value={props.form && props.form.partner_access}
                                                                       onChange={(event) => props.changeValue('partner_access', event.target.checked ? 1 : 0)}
                                                                       type={'checkbox'}
                                                                       checked={Number(props.form.partner_access) ? 'checked' : ''}
                                                                       required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group custom_form_checkbox">
                                                                <label
                                                                    htmlFor="FormName">{props.getTranslate('crm_form_add', 'form_add_product_new_product')}</label>
                                                                <input className={'form-control'}
                                                                       value={props.form && props.form.new_product}
                                                                       onChange={(event) => props.changeValue('new_product', event.target.checked ? 1 : 0)}
                                                                       type={'checkbox'}
                                                                       checked={Number(props.form.new_product) ? 'checked' : ''}
                                                                       required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="FormProductDiscount">{props.getTranslate('crm_form_add', 'form_add_product_discount')}</label>
                                                                <input className={'form-control'}
                                                                       id={"FormProductDiscount"}
                                                                       value={props.form && props.form.discount}
                                                                       onChange={(event) => props.changeValue('discount', event.target.value)}
                                                                       required
                                                                       placeholder={props.getTranslate('crm_form_add', 'form_add_product_discount_placeholder')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content" id="custom-tabs-three-tabContent">
                                    <div className={'tab-pane fade' + (props.activeTab === 3 ? ' show active' : '')}>
                                        <div className="card-body p-0 product_add_category_container">
                                            {props.form && props.form.name && props.form.name[0] && props.form.name[0]['name'] ?
                                                <>
                                                    <h3 className={"product_add_characters_title"}>{props.form.name[0]['name']} {props.form && props.form.model ? <>/ {props.form.model}</> : ""}</h3>
                                                </>
                                            :
                                                ""}
                                            <div className="row">
                                                <div className={"col-md-2"}>
                                                    <h4>Категорії</h4>
                                                </div>
                                                <div className={'form-group col-md-3 '}>
                                                    <Select value={{
                                                        value: props.form.categorySelector,
                                                        label: props.form && props.form.categorySelector && props.categories && props.categories.filter(c => {
                                                            return Number(c.id) === Number(props.form.categorySelector)
                                                        })[0].name
                                                    }}
                                                            onChange={(selectedOption) => props.changeValue('categorySelector', selectedOption.value)}
                                                            placeholder={'Категорія'}
                                                            className={'form-select-container select2_max-width'}
                                                            required={'required'}
                                                            options={props.categories && props.categories
                                                                .filter(e => !props.form.category.includes(e.id))
                                                                .map(e => {
                                                                    return {
                                                                        value: e.id,
                                                                        label: e.name
                                                                    }
                                                                })
                                                            }/>
                                                </div>
                                                <div className={"form-group col-md-1"}>
                                                    <button className="btn btn-success"
                                                            onClick={() => {
                                                                let add_category = [
                                                                    ...props.form.category,
                                                                    props.form.categorySelector
                                                                ];
                                                                props.changeValue('category', add_category);
                                                                props.changeValue('categorySelector', null);
                                                            }}
                                                            type={'button'}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                            {props.form && props.form.category && props.form.category.map(e => {
                                                return <div className="row">
                                                    <div
                                                        className="col-md-3 product_add_category_item">{props.categories && props.categories.filter(c => {
                                                        return Number(c.id) === Number(e);
                                                    })[0] && props.categories.filter(c => Number(c.id) === Number(e))[0].name}</div>
                                                    <div className="col-md-1">
                                                        <button className={'btn btn-danger delete_product_property'}
                                                                onClick={() => {
                                                                    let del_category = [...props.form.category].filter(i => Number(i) !== Number(e));
                                                                    props.changeValue('category', del_category);
                                                                }}
                                                                type={'button'}
                                                        >x
                                                        </button>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div className="card-body p-0 product_add_category_container">
                                            <div className="row">
                                                <div className={"col-md-2"}>
                                                    <h4>Теги</h4>
                                                </div>
                                                <div className={'form-group col-md-3 '}>
                                                    <Select value={{
                                                        value: props.form.tagSelector,
                                                        label: props.form && props.form.tagSelector && props.tags && props.tags.filter(c => {
                                                            return Number(c.id) === Number(props.form.tagSelector)
                                                        })[0].name
                                                    }}
                                                            onChange={(selectedOption) => props.changeValue('tagSelector', selectedOption.value)}
                                                            placeholder={'Тег'}
                                                            className={'form-select-container select2_max-width'}
                                                            required={'required'}
                                                            options={props.tags && props.tags
                                                                .filter(e => !props.form.tags.includes(e.id))
                                                                .map(e => {
                                                                    return {
                                                                        value: e.id,
                                                                        label: e.name
                                                                    }
                                                                })
                                                            }/>
                                                </div>
                                                <div className={"form-group col-md-1"}>
                                                    <button className="btn btn-success"
                                                            onClick={() => {
                                                                let add_category = [
                                                                    ...props.form.tags,
                                                                    props.form.tagSelector
                                                                ];
                                                                props.changeValue('tags', add_category);
                                                                props.changeValue('tagSelector', null);
                                                            }}
                                                            type={'button'}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                            {props.form && props.form.tags && props.form.tags.map(e => {
                                                return <div className="row">
                                                    <div
                                                        className="col-md-3 product_add_category_item">{props.tags && props.tags.filter(c => {
                                                        return Number(c.id) === Number(e);
                                                    })[0] && props.tags.filter(c => Number(c.id) === Number(e))[0].name}</div>
                                                    <div className="col-md-1">
                                                        <button className={'btn btn-danger delete_product_property'}
                                                                onClick={() => {
                                                                    let del_category = [...props.form.tags].filter(i => Number(i) !== Number(e));
                                                                    props.changeValue('tags', del_category);
                                                                }}
                                                                type={'button'}
                                                        >x
                                                        </button>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className={"col-md-2"}>
                                                    <h4>Атрибути</h4>
                                                </div>
                                                <div className={'form-group col-md-3'}>
                                                    <Select value={{
                                                        value: props.form.attributeSelector,
                                                        label: props.form && props.form.attributeSelector && props.attributes && props.attributes.filter(c => {
                                                            return Number(c.id) === Number(props.form.attributeSelector)
                                                        })[0].name
                                                    }}
                                                            onChange={(selectedOption) => props.changeValue('attributeSelector', selectedOption.value)}
                                                            placeholder={'Атрибут'}
                                                            className={'form-select-container select2_max-width'}
                                                            required={'required'}
                                                            options={props.attributes && props.attributes
                                                                .filter(e => {
                                                                    if(Number(e.id) > 0){
                                                                        let check = props.form.attribute && props.form.attribute.filter(atr => Number(e.id) === Number(atr.id));
                                                                        if(check) {
                                                                            return check.length > 0 ? false : true;
                                                                        } else {
                                                                            return true;
                                                                        }
                                                                    }
                                                                })
                                                                .map(e => {
                                                                return {
                                                                    value: e.id,
                                                                    label: e.name
                                                                }
                                                            })
                                                            }/>
                                                    <div className={'form-group'}>
                                                        {props.attributes && props.attributes.filter(c => {
                                                            return Number(c.id) === Number(props.form.attributeSelector)
                                                        })[0] && Number(props.attributes.filter(c => Number(c.id) === Number(props.form.attributeSelector))[0].type) === 1 ?
                                                            <>
                                                                <Select value={{
                                                                            value: props.form.attributeValue,
                                                                            label: props.form && props.form.attributeValue && props.attributes && props.attributes
                                                                                .filter(c => {
                                                                                    return Number(c.id) === Number(props.form.attributeSelector)
                                                                                })[0].values
                                                                                .filter(c => {
                                                                                    return Number(c.id) === Number(props.form.attributeValue)
                                                                                })[0]
                                                                                .name
                                                                        }}
                                                                        onChange={(selectedOption) => props.changeValue('attributeValue', selectedOption.value)}
                                                                        placeholder={'Значення атрибуту'}
                                                                        className={'form-select-container select2_max-width'}
                                                                        required={'required'}
                                                                        options={props.attributes && props.attributes.filter(c => {
                                                                            return Number(c.id) === Number(props.form.attributeSelector)
                                                                        })[0] && props.attributes.filter(c => Number(c.id) === Number(props.form.attributeSelector))[0].values.map(e => {
                                                                            return {
                                                                                value: e.id,
                                                                                label: e.name
                                                                            }
                                                                        })
                                                                        }/>
                                                            </>
                                                            :
                                                            <>
                                                                <input type={'text'}
                                                                       value={props.form && props.form.attributeValue}
                                                                       onChange={(event) => props.changeValue('attributeValue', event.target.value)}
                                                                       placeholder={'Значення атрибуту'}
                                                                       className={'form-control'}
                                                                />
                                                            </>
                                                        }

                                                    </div>
                                                </div>
                                                <div className={"form-group col-md-1"}>
                                                    <button className="btn btn-success"
                                                            onClick={() => {
                                                                let add_attribute = [
                                                                    ...props.form.attribute,
                                                                    {
                                                                        id: props.form.attributeSelector,
                                                                        value: props.form.attributeValue,
                                                                    }
                                                                ];
                                                                props.changeValue('attribute', add_attribute);
                                                                props.changeValue('attributeSelector', null);
                                                                props.changeValue('attributeValue', null);
                                                            }}
                                                            type={'button'}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                            {props.form && props.form.attribute && props.form.attribute.map(e => {
                                                return <div className="row">
                                                    <div
                                                        className="col-md-2 product_add_category_item">{props.attributes && props.attributes.filter(c => {
                                                        return Number(c.id) === Number(e.id);
                                                    })[0] && props.attributes.filter(c => Number(c.id) === Number(e.id))[0].name}
                                                    </div>
                                                    <div className="col-md-3 product_add_category_item">
                                                        {
                                                            props.attributes &&
                                                            props.attributes.filter(c => Number(c.id) === Number(e.id))[0] &&
                                                            (Number(props.attributes.filter(c => Number(c.id) === Number(e.id))[0].type) === 1 ?
                                                                    props.attributes.filter(c => Number(c.id) === Number(e.id))[0].values.filter(val => Number(val.id) === Number(e.value))[0].name
                                                                    :
                                                                    e.value
                                                            )
                                                        }
                                                    </div>
                                                    <div className="col-md-1">
                                                        <button className={'btn btn-danger delete_product_property'}
                                                                onClick={() => {
                                                                    let del_attribute = [...props.form.attribute].filter(i => Number(i.id) !== Number(e.id));
                                                                    props.changeValue('attribute', del_attribute);
                                                                }}
                                                                type={'button'}
                                                        >x
                                                        </button>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div className="card-body p-0 product_add_recommend_products_container">
                                            <div className="row">
                                                <div className={"col-md-2"}>
                                                    <h4>Рекомендовані продукти</h4>
                                                </div>
                                                <div className={'form-group col-md-3 '}>
                                                    <Select value={{
                                                        value: props.form.recommendProductsSelector,
                                                        label: props.form && props.form.recommendProductsSelector && props.products && props.products.filter(c => {
                                                            return Number(c.id) === Number(props.form.recommendProductsSelector)
                                                        })[0].name
                                                    }}
                                                            onChange={(selectedOption) => props.changeValue('recommendProductsSelector', selectedOption.value)}
                                                            placeholder={'Рекомендований продукт'}
                                                            className={'form-select-container select2_max-width'}
                                                            required={'required'}
                                                            options={props.products && props.products
                                                                .filter(e => !props.form.recommend_products?.includes(e.id))
                                                                .map(e => {
                                                                    return {
                                                                        value: e.id,
                                                                        label: e.name
                                                                    }
                                                                })
                                                            }/>
                                                </div>
                                                <div className={"form-group col-md-1"}>
                                                    <button className="btn btn-success"
                                                            onClick={() => {
                                                                let add_category = [
                                                                    ...props.form.recommend_products,
                                                                    props.form.recommendProductsSelector
                                                                ];
                                                                props.changeValue('recommend_products', add_category);
                                                                props.changeValue('recommendProductsSelector', null);
                                                            }}
                                                            type={'button'}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                            {props.form && props.form.recommend_products && props.form.recommend_products.map(e => {
                                                debugger
                                                return <div className="row">
                                                    <div
                                                        className="col-md-3 product_add_category_item">{props.products && props.products.filter(c => {
                                                        return Number(c.id) === Number(e);
                                                    })[0] && props.products.filter(c => Number(c.id) === Number(e))[0].name}</div>
                                                    <div className="col-md-1">
                                                        <button className={'btn btn-danger delete_product_property'}
                                                                onClick={() => {
                                                                    let del_category = [...props.form.recommend_products].filter(i => Number(i) !== Number(e));
                                                                    props.changeValue('recommend_products', del_category);
                                                                }}
                                                                type={'button'}
                                                        >x
                                                        </button>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content" id="custom-tabs-three-tabContent">
                                    <div className={'tab-pane fade' + (props.activeTab === 4 ? ' show active' : '')}>
                                        <div className="card-body p-0">
                                            <div className={"row"}>
                                                {props.form && props.form.image && props.form.image.map(e => {
                                                    return <>
                                                        <div className={"col-md-8"}>
                                                            <img className={"product_add_img_style"} src={e.link}
                                                                 alt=""/>
                                                        </div>
                                                        <div className={"col-md-4"}>
                                                            <button className={'btn btn-danger'}
                                                                    onClick={() => {
                                                                        let del_images = [...props.form.image].filter(i => Number(i.id) !== Number(e.id));
                                                                        props.changeValue('image', del_images);
                                                                    }}
                                                                    type={'button'}
                                                            >x
                                                            </button>
                                                        </div>
                                                    </>
                                                })}
                                            </div>
                                            <div className="table-responsive">
                                                <button className={"btn btn-info"} onClick={() => props.toggleModalUpload(true)} type={'button'}> Додати картинку
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content" id="custom-tabs-three-tabContent">
                                    <div className={'tab-pane fade' + (props.activeTab === 5 ? ' show active' : '')}>
                                        <div className="card-body p-0">
                                            <div>
                                                {props.form && props.form.documents && props.form.documents.map(e => {
                                                    return <>
                                                        <div className={"col-md-8"}>
                                                            <a href={e.link} target={'_blank'}> {e.link} </a>
                                                        </div>
                                                        <div className={"col-md-4"}>
                                                            <button className={'btn btn-danger'}
                                                                    onClick={() => {
                                                                        let del_documents = [...props.form.documents].filter(i => Number(i.id) !== Number(e.id));
                                                                        props.changeValue('documents', del_documents);
                                                                    }}
                                                                    type={'button'}
                                                            >x
                                                            </button>
                                                        </div>
                                                    </>
                                                })}
                                            </div>
                                            <div className="table-responsive">
                                                <button className={"btn btn-info"} onClick={() => props.toggleModalDocumentUpload(true)} type={'button'}> Додати
                                                    документ
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="card-body">
                        <div className="form-group">
                            <button className="btn btn-success" onClick={() => {
                                const validation = validateForm(props.form);
                                if(validation.isCorrect) {
                                    props.changeValue('errors', null);
                                    if(props.isEdit) {
                                        props.editProduct(props.form)
                                    } else {
                                        props.createProduct(props.form)
                                    }
                                } else {
                                    props.changeValue('errors', validation.errors);
                                }
                            }}>
                                { props.isEdit
                                    ? props.getTranslate('crm_form_add', 'form_add_product_button_edit')
                                    : props.getTranslate('crm_form_add', 'form_add_product_button_create')
                                }

                            </button>
                        </div>
                        <div className="form-group">
                            {props.form && props.form.errors}
                        </div>
                    </div>
                </div>
            </section>
            <UploadFileContainer uploadHandler={props.addImage} isOpen={props.modalImageIsOpen}
                                 toggleModal={props.toggleModalUpload} types={'product_image'}/>
            <UploadFileContainer uploadHandler={props.addDocument} isOpen={props.modalDocumentIsOpen}
                                 toggleModal={props.toggleModalDocumentUpload} types={'product_document'}/>
        </div>

    );
}

export default ProductsAdd;

const ProductsBasicAdd = (props) => {

    const getValue = (lang, data) => {
        let value = data.filter(e => Number(e.lang) === Number(lang))[0];
        return value ? value.name : '';
    }

    const changeLangValue = (lang, data, value) => {
        let array = [...data];
        return array.map(e => {
            if (Number(e.lang) === Number(lang)) {
                e.name = value;
            }
            return e;
        })
    }

    return (
        <>
            <div className="card card-primary card-outline card-tabs">
                <div className="card-header p-0 pt-1 border-bottom-0 main_style_tabs_container">
                    <ul className="nav nav-tabs main_style_tabs" role="tablist">
                        {props.languages && props.languages.map(e => {
                            return <>
                                <li className="nav-item" onClick={() => props.setActiveTabLang(e.code)}>
                                    <a className={'nav-link' + (props.activeTabLang === e.code ? ' active' : '')}>{e.name} ({e.code})</a>
                                </li>
                            </>
                        })}
                    </ul>
                </div>
                <div className="card-body">
                    <div className="tab-content" id="custom-tabs-three-tabContent">
                        {props.languages && props.languages.map(o => {
                            return <div
                                className={'tab-pane fade' + (props.activeTabLang === o.code ? ' show active' : '')}>
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <div className="form-group">
                                            <label
                                                htmlFor="FormName">{props.getTranslate('crm_form_add', 'form_add_title')}</label>
                                            <input className={'form-control'}
                                                   value={props.form && props.form.name && getValue(o.id, props.form.name)}
                                                   onChange={(event) => props.changeValue('name', changeLangValue(o.id, props.form.name, event.target.value))}
                                                   required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="FormName">{props.getTranslate('crm_form_add', 'form_add_long_title')}</label>
                                            <input className={'form-control'}
                                                   value={props.form && props.form.long_name && getValue(o.id, props.form.long_name)}
                                                   onChange={(event) => props.changeValue('long_name', changeLangValue(o.id, props.form.long_name, event.target.value))}
                                                   required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="FormName">{props.getTranslate('crm_form_add', 'form_add_analyte_title')}</label>
                                            <input className={'form-control'}
                                                   value={props.form && props.form.analyte && getValue(o.id, props.form.analyte)}
                                                   onChange={(event) => props.changeValue('analyte', changeLangValue(o.id, props.form.analyte, event.target.value))}
                                                   required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="FormDecription">{props.getTranslate('crm_form_add', 'form_add_description')}</label>
                                            {/*<textarea className={'form-control'}*/}
                                            {/*          value={props.form && props.form.description && getValue(o.id, props.form.description)}*/}
                                            {/*          onChange={(event) => props.changeValue('description', changeLangValue(o.id, props.form.description, event.target.value))}*/}
                                            {/*/>*/}
                                            <CKEditor
                                                editor={ ClassicEditor }
                                                data={props.form && props.form.description && getValue(o.id, props.form.description)}
                                                // onReady={ editor => {
                                                //     // You can store the "editor" and use when it is needed.
                                                //     console.log( 'Editor is ready to use!', editor );
                                                // } }
                                                onChange={ ( event, editor ) => {
                                                    const data = editor.getData();
                                                    props.changeValue('description', changeLangValue(o.id, props.form.description, data))
                                                } }
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="FormMetaTitle">{props.getTranslate('crm_form_add', 'form_add_meta_title')}</label>
                                            <input className={'form-control'}
                                                   value={props.form && props.form.seo_name && getValue(o.id, props.form.seo_name)}
                                                   onChange={(event) => props.changeValue('seo_name', changeLangValue(o.id, props.form.seo_name, event.target.value))}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="FormMetaDecription">{props.getTranslate('crm_form_add', 'form_add_meta_description')}</label>
                                            {/*<textarea className={'form-control'}*/}
                                            {/*          value={props.form && props.form.seo_description && getValue(o.id, props.form.seo_description)}*/}
                                            {/*          onChange={(event) => props.changeValue('seo_description', changeLangValue(o.id, props.form.seo_description, event.target.value))}*/}
                                            {/*/>*/}
                                            <CKEditor
                                                editor={ ClassicEditor }
                                                data={props.form && props.form.seo_description && getValue(o.id, props.form.seo_description)}
                                                // onReady={ editor => {
                                                //     // You can store the "editor" and use when it is needed.
                                                //     console.log( 'Editor is ready to use!', editor );
                                                // } }
                                                onChange={ ( event, editor ) => {
                                                    const data = editor.getData();
                                                    props.changeValue('seo_description', changeLangValue(o.id, props.form.seo_description, data))
                                                } }
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="FormMetaTag">{props.getTranslate('crm_form_add', 'form_add_meta_tag')}</label>
                                            <input className={'form-control'}
                                                   value={props.form && props.form.seo_keywords && getValue(o.id, props.form.seo_keywords)}
                                                   onChange={(event) => props.changeValue('seo_keywords', changeLangValue(o.id, props.form.seo_keywords, event.target.value))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                        {/*    <div className={'tab-pane fade' + (props.activeTab === 1 ? ' show active' : '')}>*/}
                        {/*    <div className="card-body p-0">*/}
                        {/*        <div className="table-responsive">*/}
                        {/*            <div className="form-group">*/}
                        {/*                <label htmlFor="inputName">Project Name</label>*/}
                        {/*                <input type="text" id="inputName" className="form-control"/>*/}
                        {/*            </div>*/}
                        {/*            <div className="form-group">*/}
                        {/*                <label htmlFor="inputDescription">Project Description</label>*/}
                        {/*                <textarea id="inputDescription" className="form-control" rows="4"></textarea>*/}
                        {/*            </div>*/}
                        {/*            <div className="form-group">*/}
                        {/*                <label htmlFor="inputStatus">Status</label>*/}
                        {/*                <select id="inputStatus" className="form-control custom-select">*/}
                        {/*                    <option selected="" disabled="">Select one</option>*/}
                        {/*                    <option>On Hold</option>*/}
                        {/*                    <option>Canceled</option>*/}
                        {/*                    <option>Success</option>*/}
                        {/*                </select>*/}
                        {/*            </div>*/}
                        {/*            <div className="form-group">*/}
                        {/*                <label htmlFor="inputClientCompany">Client Company</label>*/}
                        {/*                <input type="text" id="inputClientCompany" className="form-control"/>*/}
                        {/*            </div>*/}
                        {/*            <div className="form-group">*/}
                        {/*                <label htmlFor="inputProjectLeader">Project Leader</label>*/}
                        {/*                <input type="text" id="inputProjectLeader" className="form-control"/>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={'tab-pane fade' + (props.activeTab === 2 ? ' show active' : '')}>*/}
                        {/*    <div className="card-body p-0">*/}
                        {/*        <div className="table-responsive">*/}
                        {/*            tab2*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </>
    );
}