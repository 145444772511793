import React from 'react';
import ProductDetail from "./ProductDetail";
import {connect} from "react-redux";
import './ProductDetail.css';
import {getProductThunk} from "../../../store/reducers/MainReducer";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";




class ProductDetailContainer extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.props.getProduct(this.props.uri.match.params.id);
    }

    render() {
        return <ProductDetail product={this.props.product} getTranslate={this.props.getTranslate}/>
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getProduct: (id) => dispatch(getProductThunk(id))
    }
}

let mapStateToProps = (state) => {
    return {
        product: state.main.productDetail
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(ProductDetailContainer);
// export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailContainer);