import React from 'react';
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './Warehouses.css';

const Warehouses = (props) => {

    return(
        <div className="content-wrapper">
            <PageTitle title={'warehouses'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-12 container_button">
                        {props.user && props.user.role_id && Number(props.user.role_id) !== 3 ?
                            <Link className={"btn btn-success"} to={"/warehouses/invoice"}>
                                <i className="fas fa-plus-circle"></i> {props.getTranslate('crm_menu', 'moving_warehouses')}
                            </Link> : ""
                        }
                        <Link className={"btn btn-danger"} to={"/warehouses/write_off_form"}><i className="fas fa-plus-circle"></i> {props.getTranslate('crm_menu','write_off_warehouses')}</Link>
                        <Link className={"btn btn-warning"} to={"/warehouses/moving_form"}><i className="fas fa-plus-circle"></i> {props.getTranslate('crm_menu','receiving_warehouses')}</Link>
                        <Link className={"btn btn-success"} to={"/warehouses/add"}><i className="fas fa-plus-circle"></i></Link>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card">
                        <table className="table table-striped">
                            <thead>
                            <th>{props.getTranslate('crm_main','ID')}</th>
                            <th>{props.getTranslate('crm_main','address')}</th>
                            <th>{props.getTranslate('crm_main','name')}</th>
                            <th>{props.getTranslate('crm_main','phone')}</th>
                            <th>{props.getTranslate('crm_main','date')}</th>
                            <th>{props.getTranslate('crm_main','action')}</th>
                            </thead>
                            <tbody>
                            {props.warehouses && props.warehouses.map(e => {
                                return <>
                                    <tr>
                                        <td>{e.id}</td>
                                        <td>{e.address}</td>
                                        <td>{e.name}</td>
                                        <td>{e.phone}</td>
                                        <td>{e.date}</td>
                                        <td className={"action_button_td"}>
                                            <Link className={"btn btn-info"} to={'/warehouses/edit/' + e.id}>
                                                <i className="far fa-edit"></i>
                                            </Link>
                                            <Link className={"btn btn-info"} to={'/warehouses/' + e.id}>
                                                <i className="far fa-eye"></i>
                                            </Link>
                                        </td>
                                    </tr>
                                </>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Warehouses;