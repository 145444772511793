import React from 'react';
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './Categories.css';

const Categories = (props) => {

    return (
        <div className="content-wrapper">
            <PageTitle title={'categories'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-12 container_button">
                        <Link className={"btn btn-success"} to={"/categories/add"}><i
                            className="fas fa-plus-circle"></i></Link>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card">
                        <table className="table table-striped text-center">
                            <thead>
                            <th>{props.getTranslate('crm_main', 'ID')}</th>
                            <th>{props.getTranslate('crm_main', 'name')}</th>
                            <th>{props.getTranslate('crm_main', 'date')}</th>
                            <th>{props.getTranslate('crm_main', 'action')}</th>
                            </thead>
                            <tbody>
                            {props.categories && props.categories.map(e => {
                                return <>
                                    <tr>
                                        <td>{e.id}</td>
                                        <td>{e.name}</td>
                                        <td>{e.date}</td>
                                        <td className={"action_button_td"}>
                                            <Link className={'btn btn-info'} to={'/categories/edit/' + e.id}>
                                                <i className="fas fa-pen"></i>
                                            </Link>
                                            <button className={'btn btn-danger'}
                                                    onClick={() => props.deleteCategory(e.id)}>
                                                <i className="fas fa-minus-circle"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*<div className="container-fluid">*/}
                {/*    <div className="card">*/}
                {/*        <table className="table table-striped text-center">*/}
                {/*            <thead>*/}
                {/*            <th>{props.getTranslate('crm_main', 'ID')}</th>*/}
                {/*            <th>{props.getTranslate('crm_main', 'name')}</th>*/}
                {/*            <th>{props.getTranslate('crm_main', 'show_menu')}</th>*/}
                {/*            <th>{props.getTranslate('crm_main', 'date')}</th>*/}
                {/*            <th>{props.getTranslate('crm_main','action')}</th>*/}
                {/*            <th></th>*/}
                {/*            </thead>*/}
                {/*            <tbody>*/}
                {/*            {props.categories && props.categories.map(e => {*/}

                {/*                return <>*/}
                {/*                    {e.parent && e.parent < 1 ?*/}
                {/*                        <tr>*/}
                {/*                            <td>{e.id}</td>*/}
                {/*                            <td>{e.name}</td>*/}
                {/*                            <td>{e.show_menu && e.show_menu == 1 ?*/}
                {/*                                <i className="fas fa-check text-green"></i> : " "}</td>*/}
                {/*                            <td>{e.date}</td>*/}
                {/*                            <td className={"action_button_td"}>*/}
                {/*                                <Link className={'btn btn-info'} to={'/categories/edit/' + e.id}>*/}
                {/*                                    <i className="fas fa-pen"></i>*/}
                {/*                                </Link>*/}
                {/*                                <button className={'btn btn-danger'} onClick={() => props.deleteCategory(e.id)}>*/}
                {/*                                <i className="fas fa-minus-circle"></i>*/}
                {/*                            </button>*/}
                {/*                            </td>*/}
                {/*                            <td>*/}
                {/*                                {props.categories && props.categories.filter(p => Number(e.id) === Number(p.parent)).length > 0 &&*/}
                {/*                                <i className="fas fa-angle-down"*/}
                {/*                                   onClick={() => props.toggleActive(e.id)}>*/}
                {/*                                </i>*/}
                {/*                                }*/}
                {/*                            </td>*/}
                {/*                        </tr>*/}
                {/*                        : " "}*/}
                {/*                    {Number(props.categoryOpen) === Number(e.id) ?*/}
                {/*                        <tr>*/}
                {/*                            <td colSpan={5}>*/}
                {/*                                <table className={'table table-bordered text-center'}>*/}
                {/*                                    <thead>*/}
                {/*                                    <tr>*/}
                {/*                                        <th>{props.getTranslate('crm_main', 'ID')}</th>*/}
                {/*                                        <th>{props.getTranslate('crm_main', 'name')}</th>*/}
                {/*                                        <th>{props.getTranslate('crm_main', 'show_menu')}</th>*/}
                {/*                                        <th>{props.getTranslate('crm_main', 'date')}</th>*/}
                {/*                                        <th></th>*/}
                {/*                                    </tr>*/}
                {/*                                    </thead>*/}
                {/*                                    <tbody>*/}
                {/*                                    {props.categories && props.categories.map(sb => {*/}
                {/*                                        return sb.parent && sb.parent === e.id &&*/}

                {/*                                            <tr>*/}
                {/*                                                <td>{sb.id}</td>*/}
                {/*                                                <td>{sb.name}</td>*/}
                {/*                                                <td>{sb.show_menu && e.show_menu == 1 ?*/}
                {/*                                                    <i className="fas fa-check text-green"></i> : " "}</td>*/}
                {/*                                                <td>{sb.date}</td>*/}
                {/*                                                <td>*/}
                {/*                                                    <button className={'btn btn-danger'} onClick={() => props.deleteCategory(sb.id)}>*/}
                {/*                                                        <i className="fas fa-minus-circle"></i>*/}
                {/*                                                    </button>*/}
                {/*                                                    <Link className={'btn btn-info'} to={'/categories/edit/' + sb.id}>*/}
                {/*                                                        <i className="fas fa-pen"></i>*/}
                {/*                                                    </Link>*/}
                {/*                                                </td>*/}
                {/*                                            </tr>*/}

                {/*                                    })}*/}
                {/*                                    </tbody>*/}
                {/*                                </table>*/}
                {/*                            </td>*/}
                {/*                        </tr>*/}
                {/*                        :*/}
                {/*                        ''*/}
                {/*                    }*/}
                {/*                </>*/}
                {/*            })}*/}
                {/*            </tbody>*/}
                {/*        </table>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </section>
        </div>

    );
}

export default Categories;