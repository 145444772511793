import React from 'react';

export const withPagination = (Component) => {

    class PaginationLocal extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                page: 1,
                pageSize: 10,
                pageStart: 1,
                pageFinish: 10
            }
            this.renderPagination = this.renderPagination.bind(this);
        }

        componentDidMount() {

        }

        renderPagination(count) {
            if(!count || !this.state) return '';
            const pages = [];
            let countPage = Math.floor( count / this.state.pageSize);
            if(count % this.state.pageSize !== 0) {
                countPage++;
            }
            pages.push(<a className={'page-link ' + (Number(this.state.page) === Number(1) ? 'active' : '')} onClick={() => this.setPage(1, count)}> {1} </a>);
            pages.push(<b> -- </b>);
            for(let i = 1; i < Number(countPage); i++) {
                if(Number(this.state.page) === 1 && Number(i) === 1) {
                    pages.push(<a className={'page-link ' + (Number(this.state.page) === Number(2) ? 'active' : '')} onClick={() => this.setPage(2, count)}> {2} </a>);
                    pages.push(<a className={'page-link ' + (Number(this.state.page) === Number(3) ? 'active' : '')} onClick={() => this.setPage(3, count)}> {3} </a>);
                    pages.push(<a className={'page-link ' + (Number(this.state.page) === Number(4) ? 'active' : '')} onClick={() => this.setPage(4, count)}> {4} </a>);
                    break;
                } else {
                    if(( Number(i) === Number(this.state.page) - 1 ) && (Number(this.state.page) - 1 > 1) ||  Number(this.state.page) === Number(i) || Number(i) === Number(this.state.page) + 1) {
                        pages.push(<a className={'page-link ' + (Number(this.state.page) === Number(i) ? 'active' : '')} onClick={() => this.setPage(i, count)}> {i} </a>)
                    }
                }
            }
            pages.push(<b> -- </b>);
            pages.push(<a className={'page-link ' + (Number(this.state.page) === Number(countPage) ? 'active' : '')} onClick={() => this.setPage(Number(countPage), count)}> {Number(countPage)} </a>);

            return pages;
        }

        getPages() {
            return {
                pageStart: this.state.pageStart,
                pageFinish: this.state.pageFinish,
                page: this.state.page
            }
        }

        setPage(page, count) {
            let finishPage = this.state.pageSize * page;
            let startPage = this.state.pageSize * (page -1);
            this.setState({
                page: page,
                pageStart: startPage,
                pageFinish: finishPage,
            });
        }

        renderPaginationBlock(count) {
            return <div className={'pagination_main_container'}>
                <ul className="pagination">
                    <li className="page-item">
                        {this.renderPagination(count)}
                    </li>
                </ul>
            </div>
        }

        render() {
            return <Component {...this.props}
                              paginationHTML={this.renderPaginationBlock(this.props.paginationDataCount)}
                              paginationPageStart={this.state.pageStart}
                              paginationChangePage={(page) => this.setPage(page, 0)}
                              paginationPages={this.getPages()}
            />
        }
    }
    return PaginationLocal;
}



