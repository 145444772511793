import React from 'react';
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './SellManagers.css';

const SellManagers = (props) => {

    return(
        <div className="content-wrapper">
            <PageTitle title={'sell_managers'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-12 container_button">
                        <a className={"btn btn-success"} href={"/sell_managers/add"}><i className="fas fa-plus-circle"></i></a>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card">
                        <table className="table table-striped">
                            <thead>
                            <th>{props.getTranslate('crm_main','ID')}</th>
                            <th>{props.getTranslate('crm_main','name')}</th>
                            <th>{props.getTranslate('crm_main','description')}</th>
                            <th>{props.getTranslate('crm_main','phone')}</th>
                            <th>{props.getTranslate('crm_main','promo_code')}</th>
                            <th>{props.getTranslate('crm_main','discount')}</th>
                            <th>{props.getTranslate('crm_main','date')}</th>
                            <th>{props.getTranslate('crm_main','action')}</th>
                            </thead>
                            <tbody>
                            {props.sell_managers && props.sell_managers.map(e => {
                                return <>
                                    <tr>
                                        <td>{e.id}</td>
                                        <td>{e.name}</td>
                                        <td>{e.description}</td>
                                        <td>{e.phone}</td>
                                        <td>{e.promo_code}</td>
                                        <td>{(e.discount * 100).toFixed(4)}%</td>
                                        {/*<td>{(e.discount)}%</td>*/}
                                        <td>{e.date}</td>
                                        <td className={"action_button_td"}>
                                            <button className={"btn btn-danger"} onClick={() => props.deleteSellManager(e.id)}>
                                                <i className="far fa-trash-alt"></i>
                                            </button>
                                            <a className={"btn btn-info"} href={'/sell_managers/edit/' + e.id}>
                                                <i className="far fa-edit"></i>
                                            </a>
                                            {/*<button className={"btn btn-info"}>*/}
                                            {/*    <i className="fas fa-pen"></i>*/}
                                            {/*</button>*/}
                                        </td>
                                    </tr>
                                </>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default SellManagers;