import React from 'react';

class ApiService extends React.Component {

    //TODO: ссылка под замену (АПИ)

    _baseUrl = 'https://api.medbuy.online/api';
    // _baseUrl = 'http://test.api.medbuy.online/api';

    async _getData(url, method, data, headers = {}, resType = 'json') {

        let body = null;
        let options = {
            method: method,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': '12345',
                'User-Token': localStorage.getItem(`medbuy_crm_user_token`),
                "Language": localStorage.getItem('medbuy_store_main_config_lang') ? localStorage.getItem('medbuy_store_main_config_lang') : 1,
                ...headers
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        }
        if (method === 'POST' || method === 'PUT') {
            options.body = JSON.stringify(data);
        }
        const res = await fetch(`${this._baseUrl}${url}`, options);

        if (resType === 'json') {
            body = await res.json();
        }
        if (resType === 'text') {
            body = await res.text();
        }

        if (!res.count > 0) {

        }

        return body;

    }

    async _sendFile(url, data, headers, resType = 'json') {

        const file = data;
        const formData = new FormData();
        let body = null;

        formData.append('file', file)


        let options = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'X-Api-Key': '12345',
                "Language": localStorage.getItem('medbuy_store_main_config_lang') ? localStorage.getItem('medbuy_store_main_config_lang') : 1,
                ...headers
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: formData
        }

        const res = await fetch(`${this._baseUrl}${url}`, options);

        if (resType === 'json') {
            body = await res.json();
        }
        if (resType === 'text') {
            body = await res.text();
        }

        if (!res.count > 0) {

        }

        return body;

    }

    sendProductImage = async (file) => {
        const res = await this._sendFile(
            "/upload",
            file,
            {"Point-Action": "product_image"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    sendProductDocument = async (file) => {
        const res = await this._sendFile(
            "/upload",
            file,
            {"Point-Action": "product_document"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getTranslates = async () => {
        const res = await this._getData(
            "/translates",
            'POST',
            {
                lang: localStorage.getItem('medbuy_store_main_config_lang') ? localStorage.getItem('medbuy_store_main_config_lang') : 1,
            },
            {"Point-Action": "front"},
            'json'
            )
        ;
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    createTranslate = async (translate) => {
        const res = await this._getData(
            "/translates",
            'POST',
            {
                ...translate
            },
            {"Point-Action": "create"},
            'json'
            )
        ;
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    updateOrder = async (id, order) => {
        const res = await this._getData(
            "/orders",
            'POST',
            {
                id: id,
                ...order
            },
            {"Point-Action": "update"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getEditOrderData = async (id) => {
        const res = await this._getData(
            "/orders",
            'POST',
            {
                id: id
            },
            {"Point-Action": "get_order_data"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    sendEditOrderData = async (id, data) => {
        const res = await this._getData(
            "/orders",
            'POST',
            {
                id: id,
                data: data
            },
            {"Point-Action": "edit"},
            'json'
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    }

    copyOrder = async (id) => {
        const res = await this._getData(
            "/orders",
            'POST',
            {
                id: id,
            },
            {"Point-Action": "copy"},
            'json'
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    }

    checkOrderProductsCount = async (id) => {

        if(!id) return false;

        const res = await this._getData(
            "/orders",
            'POST',
            {
                id: id
            },
            {"Point-Action": "check_order_products_count"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    checkNPTTN = async (ttn) => {

        if(!ttn) return false;

        const res = await this._getData(
            "/orders",
            'POST',
            {
                ttn: ttn,
            },
            {"Point-Action": "np_check_order"},
            'json'
        );

        if (res && res.data && res.data.data && res.data.data[0]) {
            return {
                statusName: res.data.data[0].Status,
                statusCode: res.data.data[0].StatusCode,
            };
        } else {
            return false;
        }
    };

    checkPortmone = async (order_id) => {

        if(!order_id) return false;

        const res = await this._getData(
            "/orders",
            'POST',
            {
                id: order_id,
            },
            {"Point-Action": "portmone_check_order_pay"},
            'json'
        );

        debugger

        if (res && res.status) {
            return res;
        } else {
            return false;
        }
    };

    getLanguages = async () => {
        const res = await this._getData(
            "/languages",
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    getMenuItems = async () => {
        return [
            {
                id: 1,
                name: 'Інфекційні захворювання',
                alias: 'infections',
                parents: []
            },
            {
                id: 2,
                name: 'Біохімія',
                alias: 'biohimic',
                parents: [
                    {
                        id: 3,
                        name: 'Біохімія крові',
                        alias: 'biohimic_blood',
                        parents: []
                    },
                    {
                        id: 4,
                        name: 'Біохімія сечі',
                        alias: 'biohimic_secha',
                        parents: []
                    }
                ]
            },
            {
                id: 5,
                name: 'Гематологія',
                alias: 'gematology',
                parents: []
            },
        ];
    }

    getAttribute = async (id, lang = localStorage.getItem('medbuy_store_main_config_lang')) => {
        if (!id) {
            return false;
        }
        const res = await this._getData(
            `/attributes/${id}`,
            'GET',
            {},
            {
                "Language": lang
            },
            'json'
        );
        if (res && res.data && res.data.id) {
            return this._transformAttributes([{...res.data}])[0];
        } else {
            return false;
        }
    }
    getTag = async (id, lang = localStorage.getItem('medbuy_store_main_config_lang')) => {
        if (!id) {
            return false;
        }
        const res = await this._getData(
            `/tags/${id}`,
            'GET',
            {},
            {
                "Language": lang
            },
            'json'
        );
        if (res && res.data && res.data.id) {
            return this._transformTags([{...res.data}])[0];
        } else {
            return false;
        }
    }
    getCategory = async (id, lang = localStorage.getItem('medbuy_store_main_config_lang')) => {
        if (!id) {
            return false;
        }
        const res = await this._getData(
            `/categories/${id}`,
            'GET',
            {},
            {
                "Language": lang
            },
            'json'
        );
        if (res && res.data && res.data.id) {
            return this._transformCategories([{...res.data}])[0];
        } else {
            return false;
        }
    }
    getManufacturer = async (id, lang = localStorage.getItem('medbuy_store_main_config_lang')) => {
        if (!id) {
            return false;
        }
        const res = await this._getData(
            `/manufacturers/${id}`,
            'GET',
            {},
            {
                "Language": lang
            },
            'json'
        );
        if (res && res.data && res.data.id) {
            return this._transformManufacturers([{...res.data}])[0];
        } else {
            return false;
        }
    }

    getProduct = async (id, lang = localStorage.getItem('medbuy_store_main_config_lang')) => {
        if (!id) {
            return false;
        }
        if (!lang) {
            lang = 1;
        }
        const res = await this._getData(
            `/products/${id}`,
            'GET',
            {},
            {
                "Language": lang
            },
            'json'
        );
        if (res && res.data && res.data.id) {
            return this._transformProduct(res.data);
        } else {
            return false;
        }
    }
    _transformProduct = (data) => {
        let price_view = 0;
        if(Number(data.old_price) > 0) {
            price_view = data.old_price
        } else  {
            price_view = data.price
        }
        let count_one = 0;
        if(Number(data.old_price) > 0) {
            count_one = data.old_price/data.in_pack;
        } else  {
            count_one = data.price/data.in_pack;
        }
        return {
            id: data.id,
            name: data.name,
            long_name: data.long_name,
            analyte: data.analyte,
            alias: data.alias,
            description: data.description,
            model: data.model,
            rates: data.rate,
            pdv: data.pdv,
            firm: data.firm,
            currency: data.currency,
            manufacturer: data.manufacturer,
            in_pack: data.in_pack,
            category: data.category,
            preorder: data.preorder,
            active: data.active,
            new_product: data.new_product,
            discount: data.discount ? data.discount : null,
            partner_access: data.partner_access,
            count: data.count.count_all,
            price: price_view,
            seo: data.seo,
            price_one: count_one,
            documents: data.documents ? data.documents.map(d => {
                return {
                    id: d.id,
                    link: d.link,
                    name: d.name
                }
            }) : [],
            images: data.images ? data.images.map(e => {
                return {
                    id: e.id,
                    link: e.src,
                    isMain: Number(e.in_main) > 0
                }
            }) : [],
            in_stock: data.count.warehouses ? data.count.warehouses.map(w => {
                return {
                    count: w.count,
                    id_warehouse: w.id_warehouse,
                    name_warehouse: w.name_warehouse
                }
            }) : [],
            tags: data.tags,
            recommend_products: data.recommend_products,
            options: data.attributes ? data.attributes.map(ppp => {
                if(ppp) {
                    return {
                        id: ppp.id,
                        name: ppp.name,
                        value: ppp.value,
                        value_id: ppp.value_id
                    }
                }
            }) : []
        }
    }

    getProducts = async (filters, pagination) => {
        const res = await this._getData(
            `/products`,
            'POST',
            {
                limit: 999,
                page: 1,
                activity: 0,
                ...filters,
                ...pagination
            },
            {"Point-Action": "filter"},
            'json'
        );
        if (res && res.data) {
            return {
                count: res.data.count,
                data: this._transformProducts(res.data.data)
            };
        } else {
            return false;
        }
    }

    getProductsCategory = async (category) => {
        const res = await this._getData(
            `/products`,
            'POST',
            {"category": category},
            {'Point-Action': 'category'},
            'json'
        );
        if (res && res.data) {
            return this._transformProducts(res.data);
        } else {
            return false;
        }
    }

    getProductsSearch = async (key, filters = {}, page = 1) => {
        const res = await this._getData(
            `/products`,
            'POST',
            {
                page: page,
                key: key,
                ...filters
            },
            {"Point-Action": "search"},
            'json'
        );
        if (res && res.data) {
            return {
                data: this._transformSearchProducts(res.data.data),
                count: res.data.count
            };
        } else {
            return false;
        }
    }

    _transformSearchProducts = (data) => {
        return data && data.filter(e => Number(e.active)).map(e => {
            let image = e.images && e.images.filter(i => Number(i.in_main))[0];
            return {
                id: e.id,
                name: e.name,
                price: e.price,
                image: image && image.src,
                model: e.model,
                in_pack: e.in_pack,
                alias: e.alias,
                new_product: e.new_product,
                price_one: e.price_one,
                old_price_one: e.old_price_one ? e.old_price_one : null,
                old_price: e.old_price ? e.old_price : null,
                discount_percent: e.discount_percent ? e.discount_percent : null,
                count: e.count.count_all,
                options: e.attributes ? e.attributes.map(pr => {
                    if(pr) {
                        return {
                            id: pr.id,
                            name: pr.name,
                            value: pr.value
                        }
                    }
                }) : [],
            }
        })
    }

    _transformProducts = (data) => {
        return data && data.map(e => {
            let image = e.images && e.images.filter(i => Number(i.in_main))[0];
            let price_view = 0;
            if(Number(e.old_price) > 0) {
                 price_view = e.old_price
            } else  {
                 price_view = e.price
            }
            let count_one = 0;
            if(Number(e.old_price) > 0) {
                count_one = e.old_price/e.in_pack;
            } else  {
                count_one = e.price/e.in_pack;
            }
            return {
                id: e.id,
                name: e.name,
                in_pack: e.in_pack,
                price: price_view,
                alias: e.alias,
                image: image && image.src,
                model: e.model,
                price_one: count_one,
                count: e.count.count_all,
                date: e.date,
                date_update: e.date_update,
                options: e.attributes ? e.attributes.map(pr => {
                    if(pr) {
                        return {
                            id: pr.id,
                            name: pr.name,
                            value: pr.value
                        }
                    }
                }) : [],
            }
        })
    }

    getCategoryTree = async () => {
        const res = await this._getData(
            `/categories`,
            'POST',
            {},
            {"Point-Action": "tree"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getAuth = async (login, pass) => {
        if (!login || !pass) {
            return false;
        }
        const res = await this._getData(
            `/auth`,
            'POST',
            {login: login, password: pass},
            {'Point-Action': 'manager'},
            'json'
        );
        if (res && res.data && res.data.status && res.data.status === 'success') {
            return res.data.token;
        } else {
            return false;
        }
    };

    getAuthData = async (token = localStorage.getItem('medbuy_crm_user_token')) => {
        if (!token) {
            return false;
        }
        const res = await this._getData(
            `/auth`,
            'POST',
            {token: token},
            {'Point-Action': 'token'},
            'json'
        );
        if (res && res.data && res.data.id) {
            return this._transformAuth(res.data);
        } else {
            return false;
        }
    };

    _transformAuth = (data) => {
        return {
            id: data.id,
            name: data.name,
            email: data.email,
            phone: data.phone,
            token: data.token,
            firm: data.firm,
            role: data.role

        }
    }

    getUsers = async () => {
        const res = await this._getData(
            `/auth/users`,
            'GET',
            {},
            {'Point-Action': 'users'},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    };

    registrationCustomer = async (form) => {
        if (!form) {
            return false;
        }
        const res = await this._getData(
            `/customers`,
            'POST',
            {...form},
            {'Point-Action': 'registration'},
            'json'
        );
        if (res && res.data && res.data.id && res.data.token) {
            return res.data.token;
        } else {
            return false;
        }
    };

    setOrderManager = async (manager_id, order_id) => {
        if (!manager_id || !order_id) {
            return false;
        }
        const res = await this._getData(
            `/orders`,
            'POST',
            { order_id, manager_id },
            {'Point-Action': 'manager_order'},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    };

    // _transformCustomer = (data) => {
    //     debugger
    //     return {
    //         id: data.id,
    //         name: data.name,
    //         email: data.email,
    //         phone: data.phone,
    //         token: data.token,
    //         isPartner: data.partner
    //     }
    // }

    getOrderReceipt = async (id, form) => {
        const res = await this._getData(
            `/orders`,
            'POST',
            {
                order: id,
                ...form
            },
            {"Point-Action": "create_receipt"},
            'json'
        );
        if (res && res.data) {
            return true;
        } else {
            return false;
        }
    }
    getDashboardData = async () => {
        const res = await this._getData(
            `/analytics`,
            'POST',
            {},
            {"Point-Action": "dashboard"},
            'json'
        );
        if (res && res.data) {
            return this._transformDashboard(res.data);
        } else {
            return false;
        }
    }
    _transformDashboard = (data) => {
        return {
                total: data.total,
                total_franchisees: data.total_franchisees,
                order_count: data.order_count,
                order_franchisees_count: data.order_franchisees_count,
                customer_count: data.customer_count,
                partner_count: data.partner_count,
                franchisees_count: data.franchisees_count,
            }
    }
    getOrders = async () => {
        const res = await this._getData(
            `/orders`,
            'GET',
            {},
            {"Point-Action": "related"},
            'json'
        );
        if (res && res.data) {
            return this._transformOrders(res.data);
        } else {
            return false;
        }
    }
    getFilterOrders = async (type, filters, pagination) => {
        const res = await this._getData(
            `/orders`,
            'POST',
            {
                page: 1,
                type: type,
                ...filters,
                ...pagination
            },
            {"Point-Action": "filter"},
            'json'
        );
        if (res && res.data) {
            return {
                data: this._transformOrders(res.data.data),
                count: res.data.count
            };
        } else {
            return false;
        }
    }
    _transformOrders = (data) => {
        return data && data.map(e => {
            return {
                id: e.id,
                id_customer: e.id_customer,
                type: e.type,
                status: e.status,
                region: e.region,
                district: e.district,
                street: e.street,
                street_house: e.street_house,
                payment: e.payment,
                payment_object: e.payment_object,
                company_name: e.company_name,
                customer_name: e.customer_name,
                customer_surname: e.customer_surname,
                customer_middle_name: e.customer_middle_name,
                comment: e.comment,
                manager_id: e.manager_id,
                ttn: e.ttn,
                discount: e.discount,
                total: e.total,
                date: e.date
            }
        })
    }
    getOrder = async (id) => {
        if (!id) {
            return false;
        }
        const res = await this._getData(
            `/orders/${id}`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data && res.data.id) {
            return this._transformOrder(res.data);
        } else {
            return false;
        }
    }
    _transformOrder = (data) => {
        return {
            id: data.id,
            id_customer: data.id_customer,
            customer_phone: data.customer_phone,
            customer_email: data.customer_email,
            type: data.type,
            status: data.status,
            region: data.region,
            district: data.district,
            city: data.city,
            street: data.street,
            street_house: data.street_house,
            np_warehouse: data.np_warehouse,
            payment: data.payment,
            customer_name: data.customer_name,
            customer_surname: data.customer_surname,
            customer_middle_name: data.customer_middle_name,
            card_type: data.card_type,
            comment: data.comment,
            ttn: data.ttn,
            discount: data.discount,
            total: data.total,
            date: data.date,
            documents: data.documents ? data.documents.map(doc => {
                return {
                    id: doc.id,
                    id_order: doc.id_order,
                    type: doc.type,
                    link: doc.link
                }
            }) : [],
            customer: data.customer ? data.customer.map(cus => {
                return {
                    id: cus.id_customer,
                    email: cus.email,
                    phone: cus.phone,
                    discount: cus.discount,
                }
            }) : [],
            products: data.products ? data.products.map(e => {
                return {
                    id: e.id,
                    name: e.info.name,
                    description: e.info.description,
                    model: e.info.model,
                    rates: e.info.rate,
                    count: e.count,
                    price: e.price,
                    price_one: e.info.price_one,
                    documents: [
                        {
                            id: 1,
                            name: 'Doc $ 1',
                            type: 'pdf',
                            link: ''
                        }
                    ],
                    images: e.images ? e.images.map(i => {
                        return {
                            id: i.id,
                            link: i.src,
                            isMain: Number(i.in_main) > 0
                        }
                    }) : [],
                    tags: e.tags,
                    options: e.attributes ? e.attributes.map(o => {
                        return {
                            id: o.id,
                            name: o.name,
                            value: o.value
                        }
                    }) : []
                }
            }) : [],
        }
    }
    getWarehouseDetail = async (id) => {
        if (!id) {
            return false;
        }
        const res = await this._getData(
            `/warehouses/${id}`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data && res.data.id) {
            return this._transformWarehouse(res.data);
        } else {
            return false;
        }
    }
    _transformWarehouse = (data) => {
        return {
            id: data.id,
            firm: data.firm,
            name: data.name,
            address: data.address,
            person: data.person,
            phone: data.phone,
            date: data.date,
            self_pickup: data.self_pickup,
            products: data.products ? data.products.map(p => {
                return {
                    id: p.id,
                    count: p.count,
                    alias: p.alias,
                    name: p.name,
                    model: p.model
                }
            }) : [],
            products_retail: data.products_retail ? data.products_retail.map(p => {
                return {
                    id: p.id,
                    count: p.count,
                    name: p.name,
                    model: p.model
                }
            }) : [],
            products_reserved: data.products_reserved ? data.products_reserved.map(p => {
                return {
                    id: p.id_product,
                    count: p.count
                }
            }) : [],
        }
    }

    getWarehouseHistory = async (type, page, pageSize) => {
        if (!type) {
            return false;
        }
        const res = await this._getData(
            `/warehouses`,
            'POST',
            {
                type: type,
                page: page,
                perPage: pageSize
            },
            {"Point-Action": "actions_data"},
            'json'
        );
        if (res && res.data && res.data.data) {
            return {
                data: res.data.data,
                count: res.data.count
            };
        } else {
            return false;
        }
    }


    getProductsHistory = async (params) => {
        if (!params) {
            return false;
        }
        const res = await this._getData(
            `/warehouses`,
            'POST',
            {...params},
            {"Point-Action": "history"},
            'json'
        );
        if (res && res.data && res.data.data) {
            return {
                data: res.data.data,
                count: res.data.count
            };
        } else {
            return false;
        }
    }

    getWarehouseHistoryExcel = async (params) => {
        if (!params) {
            return false;
        }
        const res = await this._getData(
            `/warehouses`,
            'POST',
            {...params},
            {"Point-Action": "history_excel"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    deleteOrder = async (id) => {
        if (!id) {
            return false;
        }
        const res = await this._getData(
            `/orders/${id}`,
            'DELETE',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return true;
        } else {
            return false;
        }
    }

    getManufacturers = async () => {
        const res = await this._getData(
            `/manufacturers`,
            'GET',
            {},
            {"Point-Action": "related"},
            'json'
        );
        if (res && res.data) {
            return this._transformManufacturers(res.data);
        } else {
            return false;
        }
    }
    _transformManufacturers = (data) => {
        return data && data.map(e => {
            return {
                id: e.id,
                name: e.name,
                date: e.date,
            }
        })
    }
    getProducers = async () => {
        const res = await this._getData(
            `/warehouses`,
            'POST',
            {},
            {"Point-Action": "producers"},
            'json'
        );
        if (res && res.data) {
            return this._transformProducers(res.data);
        } else {
            return false;
        }
    }
    _transformProducers = (data) => {
        return data && data.map(e => {
            return {
                id: e.id,
                name: e.name,
            }
        })
    }
    getCustomerBonus = async () => {
        const res = await this._getData(
            `/partners`,
            'POST',
            {},
            {"Point-Action": "get_bonus_all"},
            'json'
        );
        if (res && res.data) {
            return this._transformCustomerBonus(res.data);
        } else {
            return false;
        }
    }

    getPartnersTests = async (filters, pagination) => {
        const res = await this._getData(
            `/partners`,
            'POST',
            {
                page: 1,
                ...filters,
                ...pagination
            },
            {"Point-Action": "order_list"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    getChangePartnerBonus = async (status, id) => {
        const res = await this._getData(
            `/partners`,
            'POST',
            {
                id_bonus: id,
                status: status
            },
            {"Point-Action": "change_bonus_status"},
            'json'
        );
        if (res && res.data && res.data.status === 'success') {
            return true;
        } else {
            return false;
        }
    }
    _transformCustomerBonus = (data) => {
        return data && data.map(e => {
            return {
                id: e.id,
                name: e.surname_customer + ' ' + e.name_customer + ' ' + e.middle_name_customer,
                bonus: e.bonus,
                status: e.status,
                date_update: e.date,
                link: e.link,
                date: e.date_create,
            }
        })
    }
    getCustomers = async () => {
        const res = await this._getData(
            `/customers`,
            'GET',
            {},
            {"Point-Action": "related"},
            'json'
        );
        if (res && res.data) {
            return this._transformCustomers(res.data.data);
        } else {
            return false;
        }
    }
    getFilteredCustomers = async (filters = {}) => {
        const res = await this._getData(
            `/customers`,
            'POST',
            {...filters},
            {"Point-Action": "filter"},
            'json'
        );
        if (res && res.data) {
            return {
                data: this._transformCustomers(res.data.data),
                count: res.data.count,
            };
        } else {
            return false;
        }
    }
    _transformCustomers = (data) => {
        return data && data.map(e => {
            return {
                id: e.id,
                login: e.login,
                password: e.password,
                partner: e.partner,
                token: e.token,
                date: e.date,
                id_customer: e.id_customer,
                name: e.name,
                surname: e.surname,
                middle_name: e.middle_name,
                email: e.email,
                phone: e.phone,
                address: e.address
            }
        })
    }
    getCustomer = async (id) => {
        if (!id) {
            return false;
        }
        const res = await this._getData(
            `/customers/${id}/1`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data && res.data.id) {
            return this._transformCustomer(res.data);
        } else {
            return false;
        }
    }
    _transformCustomer = (data) => {
        return {
            id: data.id,
            login: data.login,
            password: data.password,
            partner: data.partner,
            token: data.token,
            date: data.date,
            id_customer: data.id_customer,
            name: data.name,
            surname: data.surname,
            middle_name: data.middle_name,
            email: data.email,
            phone: data.phone,
            partner_info: data.partner_info ? data.partner_info.map(pi => {
                return {
                    rang: pi.rang,
                    ipn: pi.ipn,
                    bonus: pi.bonus,
                    product_sale: pi.product_sale,
                    specialty: pi.specialty,
                    address_register: pi.address_register,
                    pasport_series: pi.pasport_series,
                    pasport_organization: pi.pasport_organization,
                    pasport_data: pi.pasport_data,
                    bank_name: pi.bank_name,
                    bank_code: pi.bank_code,
                    bank_iban: pi.bank_iban
                }
            }) : [],
            address: data.address ? data.address.map(ad => {
                return {
                    id: ad.id,
                    id_customer: ad.id_customer,
                    region: ad.region,
                    city: ad.city,
                    district: ad.district,
                    street: ad.street,
                    number_build: ad.number_build,
                    date: ad.date,
                }
            }) : [],
            partner_products: data.partner_products ? data.partner_products.map(pp => {
                return {
                    name: pp.name,
                    model: pp.model,
                    count: pp.count,
                    manufacturer: pp.manufacturer,
                    alias: pp.alias,
                    price: pp.price,
                    price_one: pp.price_one,
                }
            }) : [],
            partner_products_sale: data.partner_products_sale ? data.partner_products_sale.map(pps => {
                return {
                    alias: pps.product_alias,
                    name: pps.product_name,
                    count: pps.count,
                    price: pps.price,
                    price_one: pps.price_one,
                    bonus: pps.bonus,
                    date: pps.date,
                }
            }) : [],
            orders: data.orders ? data.orders.map(o => {
                return {
                    comment: o.comment,
                    customer_email: o.customer_email,
                    customer_middle_name: o.customer_middle_name,
                    customer_name: o.customer_name,
                    customer_surname: o.customer_surname,
                    date: o.date,
                    discount: o.discount,
                    district: o.district,
                    city: o.city,
                    np_warehouse: o.np_warehouse,
                    id: o.id,
                    id_customer: o.id_customer,
                    id_delivery_type: o.id_delivery_type,
                    id_sell_manager: o.id_sell_manager,
                    parent: o.parent,
                    payment: o.payment,
                    products: o.products ? o.products.map(p => {
                        return {
                            count: p.count,
                            id: p.id,
                            manufacturer: p.info.manufacturer,
                            model: p.info.model,
                            name: p.info.name,
                            alias: p.info.alias,
                            price: p.price
                        }
                    }) : [],
                    payments: o.payments ? o.payments.map(pa => {
                        return {
                            invoice_number: pa.invoice_number,
                            payment_code: pa.payment_code,
                            status: pa.status,
                            date: pa.date,
                        }
                    }) : [],
                    region: o.region,
                    status: o.status,
                    street: o.street,
                    street_house: o.street_house,
                    total: o.total,
                    type: o.type,
                    documents: o.documents ? o.documents.map(doc => {
                        return {
                            id: doc.id,
                            id_order: doc.id_order,
                            type: doc.type,
                            link: doc.link
                        }
                    }) : [],
                }
            }) : [],
        }
    }
    getCategories = async () => {
        const res = await this._getData(
            `/categories`,
            'GET',
            {},
            {"Point-Action": "related"},
            'json'
        );
        if (res && res.data) {
            return this._transformCategories(res.data);
        } else {
            return false;
        }
    }
    _transformCategories = (data) => {
        return data && data.map(e => {
            return {
                id: e.id,
                name: e.name,
                show_menu: e.show_menu,
                parent: e.parent,
                alias: e.alias,
                date: e.date,
                attributes: e.attributes ? e.attributes.map(o => {
                    return {
                        id: o.id,
                        name: o.name,
                        value: o.values ? o.values.map(p => {
                            return {
                                id: p.id,
                                value: p.value
                            }
                        }) : [],
                    }
                }) : [],
            }
        })
    }

    createManufacturer = async (manufacturer) => {
        const res = await this._getData(
            `/manufacturers`,
            'POST',
            {
                name: manufacturer.name
            },
            {"Point-Action": "create"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    createCategory = async (category) => {
        const res = await this._getData(
            `/categories`,
            'POST',
            {
                name: category.name,
                parent: category.parent,
            },
            {"Point-Action": "create"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getSellManagers = async () => {
        const res = await this._getData(
            `/sell-managers`,
            'GET',
            {},
            {"Point-Action": "related"},
            'json'
        );
        if (res && res.data) {
            return this._transformSellManagers(res.data.data);
        } else {
            return false;
        }
    }
    saveRelatedProducts = async (related_products) => {
        debugger;
        const res = await this._getData(
            `/products`,
            'POST',
            { related_products },
            {"Point-Action": "edit_related"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    getRelatedProducts = async () => {
        const res = await this._getData(
            `/products`,
            'POST',
            {},
            {"Point-Action": "get_related"},
            'json'
        );
        if (res && res.data) {
            return this._transformRelatedProducts(res.data.data);
        } else {
            return false;
        }
    }
    _transformSellManagers = (data) => {
        return data && data.map(e => {
            return {
                id: e.id,
                name: e.name,
                description: e.description,
                phone: e.phone,
                promo_code: e.promo_code,
                discount: e.discount,
                client: e.id_customer,
                city: e.city,
                date: e.date,
            }
        })
    }
    _transformRelatedProducts = (data) => {
        return data && data.map(e => {
            return {
                ...e
            }
        })
    }
    getWarehouseReserved = async () => {
        const res = await this._getData(
            `/warehouses`,
            'POST',
            {},
            {"Point-Action": "reserved_product"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    getWarehouses = async () => {
        const res = await this._getData(
            `/warehouses`,
            'GET',
            {},
            {"Point-Action": "related"},
            'json'
        );
        if (res && res.data) {
            return this._transformWarehouses(res.data.data);
        } else {
            return false;
        }
    }
    _transformWarehouses = (data) => {
        return data && data.map(e => {
            return {
                id: e.id,
                name: e.name,
                address: e.address,
                phone: e.phone,
                person: e.person,
                self_pickup: e.self_pickup,
                date: e.date,
            }
        })
    }
    createSellManagers = async (sell_managers) => {
        const res = await this._getData(
            `/sell-managers`,
            'POST',
            // {
            //     name: sell_managers.name,
            //     description: sell_managers.description,
            //     phone: sell_managers.phone,
            //     promo_code: sell_managers.promo_code,
            //     discount: sell_managers.discount,
            //     city: sell_managers.city,
            // },
            sell_managers,
            {"Point-Action": "create"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    getSellManager = async (id) => {
        const res = await this._getData(
            `/sell-managers/`+id,
            'GET',
            {},
            {"Point-Action": "create"},
            'json'
        );

        if (res && res.data) {
            debugger
            return res.data;
        } else {
            return false;
        }
    }

    editSellManagers = async (sell_managers) => {
        const res = await this._getData(
            `/sell-managers`,
            'POST',
            sell_managers,
            {"Point-Action": "edit"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    createWarehouses = async (warehouses) => {
        const res = await this._getData(
            `/warehouses`,
            'POST',
            warehouses,
            {"Point-Action": "create"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    editWarehouses = async (warehouses) => {
        const res = await this._getData(
            `/warehouses`,
            'PUT',
            warehouses,
            {"Point-Action": "create"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    getWarehouse = async (id) => {
        const res = await this._getData(
            `/warehouses/` + id,
            'GET',
            {},
            {"Point-Action": "create"},
            'json'
        );
        if (res && res.data) {
            return this._transformWarehouses([res.data])[0];
        } else {
            return false;
        }
    }
    createWarehousesInvoice = async (warehouses) => {
        const res = await this._getData(
            `/warehouses`,
            'POST',
            {
                ...warehouses,
                type: 1
            },
            {"Point-Action": "create_action"},
            'json'
        );
        if (res && res.data.id) {
            return res.data.id;
        } else {
            return false;
        }
    }
    createWarehousesWriteOff = async (warehouses) => {
        const res = await this._getData(
            `/warehouses`,
            'POST',
            {
                ...warehouses,
                type: 2
            },
            {"Point-Action": "create_action"},
            'json'
        );
        if (res && res.data.id) {
            return res.data.id;
        } else {
            return false;
        }
    }
    createWarehousesMoving = async (warehouses) => {
        const res = await this._getData(
            `/warehouses`,
            'POST',
            {
                ...warehouses,
                warehouse: warehouses.warehouse_from,
                warehouse_move: warehouses.warehouse_to,
                type: 3
            },
            {"Point-Action": "create_action"},
            'json'
        );
        if (res && res.data.id) {
            return res.data.id;
        } else {
            return false;
        }
    }

    deleteSellManager = async (id) => {
        if (!id) {
            return false;
        }
        const res = await this._getData(
            `/sell-managers/${id}`,
            'DELETE',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return true;
        } else {
            return false;
        }
    }
    createProduct = async (product) => {
        const res = await this._getData(
            `/products`,
            'POST',
            {
                ...product
            },
            {"Point-Action": "create"},
            'json'
        );
        if (res && res.data) {
            return res;
        } else if (res && res.error) {
            return res;
        } else {
            return false;
        }
    }

    editProduct = async (product) => {
        const res = await this._getData(
            `/products`,
            'POST',
            {
                ...product
            },
            {"Point-Action": "edit"},
            'json'
        );
        if (res && res.data) {
            return res;
        } else if(res && res.error) {
            return res;
        } else {
            return false;
        }
    }

    editAttribute = async (attribute) => {
        const res = await this._getData(
            `/attributes`,
            'POST',
            {
                ...attribute
            },
            {"Point-Action": "edit"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    editTag = async (tag) => {
        const res = await this._getData(
            `/tags`,
            'POST',
            {
                ...tag
            },
            {"Point-Action": "edit"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    editCategory = async (category) => {
        const res = await this._getData(
            `/categories`,
            'POST',
            {
                ...category
            },
            {"Point-Action": "edit"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    editManufacturer = async (manufacturer) => {
        const res = await this._getData(
            `/manufacturers`,
            'POST',
            {
                ...manufacturer
            },
            {"Point-Action": "edit"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    copyProduct = async (product) => {
        const res = await this._getData(
            `/products`,
            'POST',
            {
                ...product
            },
            {"Point-Action": "copy"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getTags = async () => {
        const res = await this._getData(
            `/tags`,
            'GET',
            {},
            {"Point-Action": "related"},
            'json'
        );
        if (res && res.data) {
            return this._transformTags(res.data.data);
        } else {
            return false;
        }
    }
    deleteTag = async (id) => {
        const res = await this._getData(
            `/tags/${id}`,
            'DELETE',
            {},
            {"Point-Action": "delete"},
            'json'
        );
        if (res && res.data) {
            return true;
        } else {
            return false;
        }
    }
    deleteCategory = async (id) => {
        const res = await this._getData(
            `/categories/${id}`,
            'DELETE',
            {},
            {"Point-Action": "delete"},
            'json'
        );
        if (res && res.data) {
            return true;
        } else {
            return false;
        }
    }
    deleteManufacturer = async (id) => {
        const res = await this._getData(
            `/manufacturers/${id}`,
            'DELETE',
            {},
            {"Point-Action": "delete"},
            'json'
        );
        if (res && res.data) {
            return true;
        } else {
            return false;
        }
    }
    deleteAttribute = async (id) => {
        const res = await this._getData(
            `/attributes/${id}`,
            'DELETE',
            {},
            {"Point-Action": "delete"},
            'json'
        );
        if (res && res.data) {
            return true;
        } else {
            return false;
        }
    }
    _transformTags = (data) => {
        return data && data.map(e => {
            return {
                id: e.id,
                name: e.name,
                alias: e.alias,
                date: e.date,
            }
        })
    }
    createTags = async (tags) => {
        const res = await this._getData(
            `/tags`,
            'POST',
            {
                name: tags.name,
                alias: tags.alias,
            },
            {"Point-Action": "create"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getAttributes = async () => {
        const res = await this._getData(
            `/attributes`,
            'GET',
            {},
            {"Point-Action": "related"},
            'json'
        );
        if (res && res.data) {
            return this._transformAttributes(res.data.data);
        } else {
            return false;
        }
    }
    _transformAttributes = (data) => {
        return data && data.map(e => {
            return {
                id: e.id,
                name: e.name,
                type: e.type,
                values: e.values ? e.values.map(o => {
                    return {
                        id: o.id,
                        name: o.name
                    }
                }) : [],
                date: e.date,
            }
        })
    }
    createAttribute = async (attribute) => {
        const res = await this._getData(
            `/attributes`,
            'POST',
            attribute,
            {"Point-Action": "create"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    getDirectory = async () => {
        const res = await this._getData(
            `/directory`,
            'GET',
            {},
            {"Point-Action": "related"},
            'json'
        );
        if (res && res.data) {
            return this._transformDirectory(res.data);
        } else {
            return false;
        }
    }
    _transformDirectory = (data) => {
        return {
            regions: data.regions ? data.regions.map(o => {
                return {
                    id: o.id,
                    name: o.name,
                    date: o.date
                }
            }) : [],
            districts: data.districts ? data.districts.map(d => {
                return {
                    id: d.id,
                    id_region: d.id_region,
                    name: d.name,
                    date: d.date
                }
            }) : [],
            cities: data.cities ? data.cities.map(c => {
                return {
                    id: c.id,
                    id_district: c.id_district,
                    id_region: c.id_region,
                    name: c.name,
                    date: c.date
                }
            }) : [],
            np_warehouse: data.warehouses ? data.warehouses.map(c => {
                return {
                    id: c.id,
                    id_city: c.id_city,
                    name: c.name,
                    number: c.number,
                    address: c.address,
                    date: c.date
                }
            }) : [],
            status: data.status ? data.status.map(s => {
                return {
                    id: s.id,
                    id_order_status: s.id_order_status,
                    lang: s.lang,
                    value: s.value,
                    date: s.date
                }
            }) : [],
            currencies: data.currencies ? data.currencies.map(cr => {
                return {
                    id: cr.id,
                    name: cr.name,
                    amount: cr.amount,
                    code: cr.code,
                    text: cr.text,
                    date: cr.date
                }
            }) : [],
            categories: data.categories ? data.categories.map(cr => {
                return {
                    id: cr.id,
                    name: cr.value,
                }
            }) : [],
            specialty: data.specialty ? data.specialty.map(sp => {
                return {
                    id: sp.id,
                    name: sp.name,
                    date: sp.date
                }
            }) : [],
            customer_partner_rang: data.customer_partner_rang ? data.customer_partner_rang.map(cpr => {
                return {
                    id: cpr.id,
                    name: cpr.name,
                    bonus_rate: cpr.bonus_rate,
                    must_sale: cpr.must_sale,
                    date: cpr.date
                }
            }) : [],
        }
    }

    createExcelAnalytics = async (filters) => {
        const res = await this._getData(
            `/analytics`,
            'POST',
            {...filters},
            {"Point-Action": "movement_goods_excel"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    createExcelProductsInStock = async () => {
        const res = await this._getData(
            `/products`,
            'POST',
            {
                lang: 1
            },
            {"Point-Action": "product-in-stock-excel"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    getOrderCounterForStatus = async (status) => {
        const res = await this._getData(
            `/analytics`,
            'POST',
            {status: status},
            {"Point-Action": "new_order_count"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

}

export default ApiService;
