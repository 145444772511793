import React from 'react';
import Tags from "./Tags";
import {connect} from "react-redux";
import './Tags.css';
import {deleteTagThunk, getTagsThunk} from "../../store/reducers/MainReducer";
import {withLang} from "../HOC/withLang/withLang";
import {compose} from "redux";



class TagsContainer extends React.Component {

    constructor(props){
        super(props);
        this.getTags = this.getTags.bind(this);
        this.state = {
            search: null
        }
    }

    componentDidMount() {
        this.props.getTagsThunk()
    }

    getTags() {
        return this.props.tags ? (this.state.search ? this.props.tags.filter(e => e.name.toLowerCase().includes(this.state.search.toLowerCase())) : this.props.tags) : [];
    }

    render() {
        return <Tags tags={this.getTags(this.props.tags)}
                     getTranslate={this.props.getTranslate}
                     paginationDataCount={this.props.tags && this.getTags(this.props.tags).length}
                     deleteTag={this.props.deleteTag}
                     searchInput={this.state.search}
                     searchInputChange={(value) => this.setState({search: value})}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getTagsThunk: () => dispatch(getTagsThunk()),
        deleteTag: (id) => dispatch(deleteTagThunk(id))
    }
}

let mapStateToProps = (state) => {
    return {
        tags: state.main.tags
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(TagsContainer);