import React from 'react';

const UploadFile = (props) => {
    return (
        <div className={'modal fade ' + (props.isOpen ? 'show' : '')}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <form action="">
                        <div className="modal-header">
                            <h5 className="modal-title">Завантаження файлів</h5>
                            <button type="button" className="close" onClick={() => props.toggleModal(false)}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">

                            <div className="input__wrapper">
                                <input type="file"
                                       name={'file_' + props.types}
                                       className="input input__file"
                                       onChange={(event) => props.getFiles(event.target.files)}
                                       id={'input__file_' + props.types}
                                />
                                <label htmlFor={'input__file_' + props.types} className="input__file-button">
                                    <span className="input__file-icon-wrapper">
                                        <i className="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    {props.file ?
                                        <span className="input__file-button-text">{props.file.name}</span>
                                        :
                                        <span className="input__file-button-text">Оберіть файл</span>
                                    }
                                </label>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => props.sendFileData()}>Завантажити</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UploadFile;