import React from 'react';
import PageTitle from "../PageTitle/PageTitle";
import {compose} from "redux";
import {withPagination} from "../HOC/withPagination/withPaginationSQL";
import PartnersTestsFilters from "./PartnersTestsFilters";

const PartnersTests = (props) => {
    return <div className="content-wrapper">
        <PageTitle title={'partners_tests'}/>
        <section className="content">
            <div className="container-fluid">
                <PartnersTestsFilters />
                <div className="card card-primary card-outline card-tabs">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <th>{props.getTranslate('crm_main','partners_tests_id')}</th>
                                    <th>{props.getTranslate('crm_main','partners_tests_customer')}</th>
                                    <th>{props.getTranslate('crm_main','partners_tests_order')}</th>
                                    <th>{props.getTranslate('crm_main','partners_tests_product')}</th>
                                    <th>{props.getTranslate('crm_main','partners_tests_count')}</th>
                                    <th>{props.getTranslate('crm_main','partners_tests_price')}</th>
                                    <th>{props.getTranslate('crm_main','partners_tests_bonus')}</th>
                                    <th>{props.getTranslate('crm_main','partners_tests_date')}</th>
                                </thead>
                                <tbody>
                                {props.partnersTests && props.partnersTests.map(e => {
                                    return <React.Fragment key={e.id}>
                                        <tr key={e.id}>
                                            <td>{e.id}</td>
                                            <td>{`${e.info[0]?.surname} ${e.info[0]?.name}`}</td>
                                            <td>{e.id_order}</td>
                                            <td>{e.product_info[0]?.model}</td>
                                            <td>{e.count}</td>
                                            <td>{e.price}</td>
                                            <td>{e.bonus}</td>
                                            <td>{e.date}</td>
                                        </tr>
                                    </React.Fragment>
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {props.paginationHTML}
        </section>
    </div>;
};

export default compose(
    withPagination
)(PartnersTests);
