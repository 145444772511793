import React, {useEffect, useState} from 'react';
import OrderChangeStatusContainer from "./OrderChangeStatusContainer";
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    alertShowAC,
    changeLoaderStatusAC,
    changeOrdersFilterFormValueAC, changeProductsPaginationsFormValueDataAC,
    getAllOrdersFilterThunk
} from "../../store/reducers/MainReducer";
import {compose} from "redux";
import {withPagination} from "../HOC/withPagination/withPaginationSQL";
import OrdersFilters from "./OrdersFilters";
import ApiService from "../../api/api";
import {OrdersManagersToggler} from "./OrdersManagersToggler";
import {OrdersManagersSelect} from "./OrdersManagersSelect";

const api = new ApiService();

const Orders = (props) => {

    const dispatch = useDispatch();
    const [sort, changeSortValue] = useState(['', '']);
    const [toggleManagers, setToggleManagers] = useState(0);
    const [users, setUsers] = useState([]);
    const changeSort = async (value) => {
        const [column, type] = sort;
        const newSort = {
            type: '',
            column: column,
        }
        if(column === value) {
            newSort.type = type === 'ASC' ? 'DESC' : 'ASC';
        } else {
            newSort.type = 'ASC';
            newSort.column = value;
        }
        changeSortValue([newSort.column, newSort.type])
        await dispatch(changeOrdersFilterFormValueAC('sort', `${newSort.column} ${newSort.type}`));
        await dispatch(changeProductsPaginationsFormValueDataAC('orders', 'page', 1))
        await dispatch(getAllOrdersFilterThunk())
    };

    const renderCarretSort = (column) => {
        return sort[0] === column ? (sort[1] === 'ASC' ? '+' : '-') : '';
    }

    const renderStatusContainer = (order) => {
        return <OrderChangeStatusContainer status_id={order.status} order={order.id} order_data={order} />;
    }

    const copyOrder = (id) => {
        dispatch(changeLoaderStatusAC(true));
        api.copyOrder(id).then(res => {
            if(res && res.data && res.data.id) {
                window.location.href = '/orders/' + res.data.id;
            } else {
                dispatch(changeLoaderStatusAC(false));
                dispatch(alertShowAC("message", "Виникла помилка при копіюванні"));
                dispatch(alertShowAC("type_message", "error"));
                dispatch(alertShowAC("active", true));
            }
        })
    }

    useEffect(() => {
        api.getUsers().then(res => {
            setUsers(res?.data?.filter((e) => Number(e.role) === 4));
        });
    }, []);

    return <div className="content-wrapper">
        <PageTitle title={'orders'}/>
        <section className="content">
            <div className="container-fluid">
                <OrdersFilters users={users}/>
                <OrdersManagersToggler selected={toggleManagers} onChange={setToggleManagers} />
                <div className="card card-primary card-outline card-tabs">
                    <div className="card-header p-0 pt-1 border-bottom-0 main_style_tabs_container">
                        <ul className="nav nav-tabs main_style_tabs" role="tablist">
                            <li className="nav-item tab_relative" onClick={() => {
                                props.setActiveTab(1);
                                dispatch(getAllOrdersFilterThunk());
                            }}>
                                <a className={'nav-link' + (props.activeTab === 1 ? ' active' : '')}>{props.getTranslate('crm_orders','tab_to_payments')}</a>
                                {props.counter_status_data && props.counter_status_data.type_1 ?
                                    <span className={"order_status_counter_container badge badge-danger"}>{props.counter_status_data.type_1}</span>
                                    : ""
                                }
                            </li>
                            <li className="nav-item tab_relative" onClick={() => {
                                props.setActiveTab(2);
                                dispatch(getAllOrdersFilterThunk());
                            }}>
                                <a className={'nav-link' + (props.activeTab === 2 ? ' active' : '')}>{props.getTranslate('crm_orders','tab_to_preorder')}</a>
                                {props.counter_status_data && props.counter_status_data.type_2 ?
                                    <span className={"order_status_counter_container badge badge-danger"}>{props.counter_status_data.type_2}</span>
                                    : ""
                                }
                            </li>
                            <li className="nav-item tab_relative" onClick={() => {
                                props.setActiveTab(3);
                                dispatch(getAllOrdersFilterThunk());
                            }}>
                                <a className={'nav-link' + (props.activeTab === 3 || props.activeTab === 4  ? ' active' : '')}>{props.getTranslate('crm_orders','tab_to_partners_order')}</a>
                                {props.counter_status_data && props.counter_status_data.type_3_4_sum ?
                                    <span className={"order_status_counter_container badge badge-danger"}>{props.counter_status_data.type_3_4_sum}</span>
                                    : ""
                                }
                            </li>
                        </ul>
                    </div>
                    <div className="card-body">
                        <div className="tab-content" id="custom-tabs-three-tabContent">
                            <div className={'tab-pane fade show active'} >
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        {props.activeTab === 3 || props.activeTab === 4 ? <>
                                            <div className="card-header p-0 pt-1 border-bottom-0 main_style_tabs_container">
                                                <ul className="nav nav-tabs main_style_tabs" role="tablist">
                                                    <li className="nav-item tab_relative" onClick={() => {
                                                        props.setActiveTab(3);
                                                        dispatch(getAllOrdersFilterThunk());
                                                    }}>
                                                        <a className={'nav-link' + (props.activeTab === 3 ? ' active' : '')}>{props.getTranslate('crm_orders','tab_to_partner_order_in_stock')}</a>
                                                        {props.counter_status_data && props.counter_status_data.type_3 ?
                                                            <span className={"order_status_counter_container badge badge-danger"}>{props.counter_status_data.type_3}</span>
                                                            : ""
                                                        }
                                                    </li>
                                                    <li className="nav-item tab_relative" onClick={() => {
                                                        props.setActiveTab(4);
                                                        dispatch(getAllOrdersFilterThunk());
                                                    }}>
                                                        <a className={'nav-link' + (props.activeTab === 4 ? ' active' : '')}>{props.getTranslate('crm_orders','tab_to_partner_preorder')}</a>
                                                        {props.counter_status_data && props.counter_status_data.type_4 ?
                                                            <span className={"order_status_counter_container badge badge-danger"}>{props.counter_status_data.type_4}</span>
                                                            : ""
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        </> : ''}
                                        <table className="table table-striped">
                                            <thead>
                                            <th onClick={() => changeSort('id')}>{props.getTranslate('crm_main','ID')} {renderCarretSort('id')}</th>
                                            <th onClick={() => changeSort('total')}>{props.getTranslate('crm_main','sum')} {renderCarretSort('total')}</th>
                                            <th>{props.getTranslate('crm_main','status')}</th>
                                            <th>{props.getTranslate('crm_main','customer_order')}</th>
                                            <th>{props.getTranslate('crm_main','payer')}</th>
                                            <th onClick={() => changeSort('date')}>{props.getTranslate('crm_main','date')} {renderCarretSort('date')}</th>
                                            <th>{props.getTranslate('crm_main','action')}</th>
                                            </thead>
                                            <tbody>
                                            {props.orders && props.orders.map(e => {
                                                    return <React.Fragment key={e.id}>
                                                        <tr key={e.id}>
                                                            <td>{e.id}</td>
                                                            <td>{Number(e.total).toFixed(2)}</td>
                                                            <td>{renderStatusContainer(e)}</td>
                                                            <td>{e.customer_surname} {e.customer_name} {e.customer_middle_name}</td>
                                                            <td>{e.payment_object}</td>
                                                            <td>{e.date}</td>
                                                            <td className={"action_button_td"}>
                                                                {toggleManagers
                                                                    ? <OrdersManagersSelect manager_id={e.manager_id} order_id={e.id} managers={users} />
                                                                    : <>
                                                                        <Link className={"btn btn-info"} to={'/orders/' + e.id}>
                                                                            <i className="far fa-eye"></i>
                                                                        </Link>
                                                                        {props.user && Number(props.user.role_id) !== 3 && Number(props.user.role_id) !== 4 ?
                                                                            <Link className={"btn btn-info"} to={'/orders/edit/' + e.id}>
                                                                                <i className="far fa-edit"></i>
                                                                            </Link>
                                                                            : "" }
                                                                        {props.user && Number(props.user.role_id) !== 3  && Number(props.user.role_id) !== 4 ?
                                                                            <button className={"btn btn-info"} onClick={() => copyOrder(e.id)}>
                                                                                <i className="far fa-copy"></i>
                                                                            </button>
                                                                            : "" }
                                                                        {/*<button className={"btn btn-danger"} onClick={() => props.deleteOrder(e.id)}>*/}
                                                                        {/*    <i className="far fa-trash-alt"></i>*/}
                                                                        {/*</button>*/}
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {props.paginationHTML}
        </section>
    </div>;
};

export default compose(
    withPagination
)(Orders);
