import React from 'react';
import {connect} from "react-redux";
import './WarehousesInvoiceForm.css';
import {withLang} from "../../HOC/withLang/withLang";
import {compose} from "redux";
import WarehousesInvoiceForm from "./WarehousesInvoiceForm";
import {
    changeWarehousesInvoiceFormValueAC,
    createWarehousesInvoiceThunk, getAllProductLimitedThunk,
    getAllProductsThunk,
    getDirectoryDataThunk, getProducersThunk,
    getWarehousesThunk
} from "../../../store/reducers/MainReducer";



class WarehousesInvoiceFormContainer extends React.Component {

    constructor(props){
        super(props);

    }


    componentDidMount() {
        this.props.directory();
        this.props.getAllProductsThunk()
        this.props.getWarehousesThunk()
        this.props.getProducersThunk()
    }

    render() {
        return <WarehousesInvoiceForm getTranslate={this.props.getTranslate}
                               changeValue={this.props.changeValue}
                               createWarehousesInvoice={this.props.createWarehousesInvoice}
                               form={this.props.form}
                               directoryData={this.props.directoryData}
                               products={this.props.products}
                               warehouses={this.props.warehouses}
                               producers={this.props.producers}
                               products_type={this.props.products_type}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeWarehousesInvoiceFormValueAC(input, value)),
        createWarehousesInvoice: (warehouses) => dispatch(createWarehousesInvoiceThunk(warehouses)),
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
        getAllProductsThunk: () => dispatch(getAllProductLimitedThunk()),
        getWarehousesThunk: () => dispatch(getWarehousesThunk()),
        getProducersThunk: () => dispatch(getProducersThunk())
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.main.WarehousesInvoiceForm,
        directoryData: state.main.directoryData,
        products: state.main.products,
        warehouses: state.main.warehouses,
        producers: state.main.producers,
        products_type: state.main.products_type
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(WarehousesInvoiceFormContainer);
