import React from 'react';
import {connect} from "react-redux";
import './SellManagersForm.css';
import {withLang} from "../../HOC/withLang/withLang";
import {compose} from "redux";
import SellManagersForm from "./SellManagersForm";
import {
    changeLoaderStatusAC,
    changeSellManagersFormValueAC, cleanSellManagerAddForm,
    createSellManagersThunk, editSellManagerThunk,
    getAllCustomersThunk,
    getDirectoryDataThunk,
    getProductEditThunk, getSellManagerEditThunk
} from "../../../store/reducers/MainReducer";
import ApiService from "../../../api/api";

const apiService = new ApiService();

class SellManagersFormContainer extends React.Component {

    constructor(props){
        super(props);
        this.getEitData = this.getEitData.bind(this);
    }

    componentDidMount() {
        this.props.directory();
        this.props.getAllCustomersThunk()

        if(this.props.isEdit) {
            this.getEitData(this.props.uri && this.props.uri.match.params.id);
        }

    }

    componentWillUpdate(nextProps, nextState, nextContext) {

        if(nextProps.isEdit) {
            if(Number(nextProps.uri.match.params.id) !== Number(this.props.uri.match.params.id)) {
                this.getEitData(nextProps.uri.match.params.id);
            }
        } else {
            // nextProps.cleanSellManagerAddForm();
        }
    }

    async getEitData(id) {
        this.props.setLoaderActive(true);
        const res = await apiService.getSellManager(id);
        const sell_manager = {
            id: res.id,
            region: res.region,
            name: res.name,
            phone: res.phone,
            city: res.city,
            date_active: res.date_active,
            discount: res.discount,
            description: res.description,
            customer: res.id_customer,
        };
        this.props.setLoaderActive(false);
        this.props.getEitData(sell_manager)
    }

    render() {
        return <SellManagersForm getTranslate={this.props.getTranslate}
                                 changeValue={this.props.changeValue}
                                 createSellManagers={this.props.createSellManagers}
                                 form={this.props.form}
                                 directoryData={this.props.directoryData}
                                 customers={this.props.customers}
                                 isEdit={this.props.isEdit}
                                 editManager={this.props.editManager}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeSellManagersFormValueAC(input, value)),
        createSellManagers: (sell_managers) => dispatch(createSellManagersThunk(sell_managers)),
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
        getAllCustomersThunk: () => dispatch(getAllCustomersThunk()),
        getEitData: (sell_manager) => dispatch(getSellManagerEditThunk(sell_manager)),
        setLoaderActive: (status) => dispatch(changeLoaderStatusAC(status)),
        cleanSellManagerAddForm: () => dispatch(cleanSellManagerAddForm()),
        editManager:() => dispatch(editSellManagerThunk()),
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.main.SellManagersForm,
        directoryData: state.main.directoryData,
        customers: state.main.customers
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(SellManagersFormContainer);
