import React, {useEffect, useState} from "react";
import {ANALYTICS_LINE_CHART_SETTINGS} from "../../constants";
import {CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Line, Tooltip, LineChart} from "recharts";

export const LineChartModule = ({type, data = []}) => {

    const { x, y } = ANALYTICS_LINE_CHART_SETTINGS[type];

    return <ResponsiveContainer width="100%" height="100%">
        <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={x} />
            <YAxis />
            <Tooltip />
            {/*<Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />*/}
            <Line dataKey={y} stroke="#82ca9d" />
        </LineChart>
    </ResponsiveContainer>;
}