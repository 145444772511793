import React from 'react';
import {connect} from "react-redux";
import AsideMenu from "./AsideMenu";
import './AsideMenu.css';
import {toggleActicveMenuAC} from "../../store/reducers/MainReducer";
import {compose} from "redux";
import {withLang} from "../HOC/withLang/withLang";

class AsideMenuContainer extends React.Component {

    componentDidMount() {}

    render() {
        return <AsideMenu
            menu={this.props.menu}
            user={this.props.user}
            toggleMenu={this.props.toggleMenu}
            getTranslate={this.props.getTranslate}/>
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        toggleMenu: (menu) => dispatch(toggleActicveMenuAC(menu)),
    }
}

let mapStateToProps = (state) => {
    return {
        menu: state.main.menuActive,
        user: state.auth.user,
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(AsideMenuContainer);

// export default connect(mapStateToProps,mapDispatchToProps)(AsideMenuContainer);