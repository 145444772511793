import React, {useEffect, useState} from 'react';
import ApiService from "../../api/api";
import {changeLoaderStatusAC} from "../../store/reducers/MainReducer";
import {useDispatch} from "react-redux";

const api = new ApiService();

export const OrdersManagersSelect = ({ manager_id, order_id, managers }) => {
    const dispatch = useDispatch();
    const [managerId, setManagerId] = useState(null);

    const handleBlur = () => {
        dispatch(changeLoaderStatusAC(true));
        api.setOrderManager(managerId, order_id).then((res) => {
            console.log(res);
            dispatch(changeLoaderStatusAC(false));
        })
    }

    useEffect(() => {
        managerId === null && manager_id && setManagerId(manager_id);
    }, [manager_id]);

    return (
        <div className="form-group">
            <div className="input-group mb-3 form_block_input">
                <label>
                    Менеджер:
                </label>
                <select className={'form-control'}
                        value={managerId}
                        onBlur={handleBlur}
                        onChange={(event) => setManagerId(event.target.value)}
                        required
                >
                    <option key={0} value={0}> </option>
                    {managers && managers.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
                </select>
            </div>
        </div>
    );
}
