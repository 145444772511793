import React from 'react';
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {changeCustomersFilterFormValueAC, getAllCustomersThunk} from "../../store/reducers/MainReducer";

export const CustomerFilters = () => {
    const dispatch = useDispatch();
    const form = useSelector((state)  => state.main.customersFilters);
    const changeValue = (input, value) => dispatch(changeCustomersFilterFormValueAC(input, value));
    const onSubmit = (event) => {
        event.preventDefault();
        dispatch(getAllCustomersThunk());
    };
    return <>
        <div className="card card-primary card-outline card-tabs">
            <form onSubmit={onSubmit}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <div className="input-group mb-3 form_block_input">
                                    <label>
                                        П.І.Б:
                                    </label>
                                    <input className={'form-control'}
                                           value={form.id}
                                           type={'text'}
                                           onChange={(event) => changeValue('name', event.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <div className="form-group custom_form_checkbox">
                                    <label
                                        htmlFor="FormName">Франчезі</label>
                                    <input className={'form-control'}
                                           value={form.franchisees}
                                           checked={Number(form.franchisees) ? 'checked' : ''}
                                           onChange={(event) => changeValue('franchisees', event.target.checked ? 1 : 0)}
                                           type={'checkbox'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <div className="form-group custom_form_checkbox">
                                    <label
                                        htmlFor="FormName">Партнер</label>
                                    <input className={'form-control'}
                                           value={form.partner}
                                           checked={Number(form.partner) ? 'checked' : ''}
                                           onChange={(event) => changeValue('partner', event.target.checked ? 1 : 0)}
                                           type={'checkbox'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-success" type={'submit'}>
                                Фільтрувати
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </>;
}