import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import AuthReducer from "./reducers/AuthReducer";
import MainReducer from './reducers/MainReducer';

let reducers = combineReducers({
    main: MainReducer,
    auth: AuthReducer
});


/// ------------FOR REDUX TOOLS-------------- ///

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunk)
));

/// -----------OLD--------------- ///

// let store = createStore(reducers, applyMiddleware(thunk));

/// -------------------------- ///

window.store = store;

export default store;