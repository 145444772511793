import React, {useEffect, useState} from 'react';
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import {formatSum} from "../../store/reducers/MainReducer";
import {withPagination} from "../HOC/withPagination/withPaginationSQL";
import {compose} from "redux";
import {useSelector} from "react-redux";

const Products = (props) => {
    const [sortDate, setSortDate] = useState(null);
    const currentPage = useSelector((state) => state.main.pagination.products.page);

    useEffect(() => {
        const element = document.getElementById('products_main_container');
        element.scrollIntoView();
    }, [currentPage])

    const sortArray = [
        {id: 1, name: 'Дата >', column: 'date_update', type: 'ASC'},
        {id: 2, name: 'Дата <', column: 'date_update', type: 'DESC'},
        {id: 3, name: 'Ціна >', column: 'price', type: 'ASC'},
        {id: 4, name: 'Ціна <', column: 'price', type: 'DESC'},
        {id: 3, name: 'Дата створення >', column: 'date', type: 'ASC'},
        {id: 4, name: 'Дата створення <', column: 'date', type: 'DESC'},
    ];
    const setSortDataChange = (column) => {
        if(!sortDate) {
            setSortDate(getSortType(column, 'ASC'))
            props.changeFilterValue('sort', getSortType(column, 'ASC'));
        }
        if(sortDate && sortDate.column !== column) {
            setSortDate(getSortType(column, 'ASC'))
            props.changeFilterValue('sort', getSortType(column, 'ASC'));
        }
        if(sortDate && sortDate.column === column && sortDate.type === 'ASC') {
            setSortDate(getSortType(column, 'DESC'))
            props.changeFilterValue('sort', getSortType(column, 'DESC'));
        }
        if(sortDate && sortDate.column === column && sortDate.type === 'DESC') {
            setSortDate(null)
            props.changeFilterValue('sort', null);
        }
        props.getAllProductsThunk();
    }
    const getSortType = (column, type) => {
        return sortArray.filter(e => e.column === column && e.type === type)[0];
    }
    return(
        <div className="content-wrapper" id={'products_main_container'}>
            <PageTitle title={'products'}/>
            <section className="content">
                <div className={"container-fluid"}>
                    <form onSubmit={(event) => {
                        event.preventDefault();
                        props.getAllProductsThunk();
                    }}>
                        <div className={"row"}>
                            <div className={"col-md-3"}>
                                <div className={"form-group"}>
                                    <label>Пошук</label>
                                    <input className={'form-control'}
                                           type={'text'}
                                           value={props.formFilters.search}
                                           onChange={(event) => props.changeFilterValue('search', event.target.value )}

                                    />
                                </div>
                            </div>
                            {/*<div className={"col-md-3"}>*/}
                            {/*    <div className={"form-group"}>*/}
                            {/*        <label>Сортування</label>*/}
                            {/*        <select*/}
                            {/*            className={'form-control'}*/}
                            {/*            value={props.formFilters.sort && props.formFilters.sort.id}*/}
                            {/*            defaultValue={props.formFilters.sort && props.formFilters.sort.id}*/}
                            {/*            onChange={(event) => props.changeFilterValue('sort', getSortType(event.target.value) )}*/}
                            {/*            onBlur={() => props.getAllProductsThunk()}*/}
                            {/*        >*/}
                            {/*            <option key={0} value={0}>--Обрати---</option>*/}
                            {/*            {sortArray.map(e => {*/}
                            {/*                return <option key={e.id} value={e.id}>{e.name}</option>*/}
                            {/*            })}*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </form>
                    <span className={"btn btn-success button_search_product"} onClick={() => props.getAllProductsThunk()}>Пошук</span>
                    <a href={'/products'} className={'btn btn-info'}>Очистити фільтри</a>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-12 container_button">
                        <a className={"btn btn-success"} href={"/products/add"}><i className="fas fa-plus-circle"></i></a>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card">
                        <table className="table table-striped">
                            <thead>
                            <th>{props.getTranslate('crm_main','ID')}</th>
                            <th>{props.getTranslate('crm_main','image')}</th>
                            <th>{props.getTranslate('crm_main','name')}</th>
                            <th>{props.getTranslate('crm_main','model')}</th>
                            <th>{props.getTranslate('crm_main','in_pack')}</th>
                            <th className={"sort_th"} onClick={() => setSortDataChange('price')}>{props.getTranslate('crm_main','price')} {sortDate && (sortDate.type === 'ASC' ? '>' : '<')}</th>
                            <th>{props.getTranslate('crm_main','price_one')}</th>
                            <th>{props.getTranslate('crm_main','count_in_stock')}</th>
                            <th className={"sort_th"}  onClick={() => setSortDataChange('date')}>{props.getTranslate('crm_main','date_create')} {sortDate && (sortDate.type === 'ASC' ? '>' : '<')}</th>
                            <th className={"sort_th"}  onClick={() => setSortDataChange('date_update')}>{props.getTranslate('crm_main','date_update')} {sortDate && (sortDate.type === 'ASC' ? '>' : '<')}</th>
                            <th>{props.getTranslate('crm_main','action')}</th>
                            </thead>
                            <tbody>
                            {props.products && props.products.map(e => {
                                return <>
                                    <tr>
                                        <td>{e.id}</td>
                                        <td><img width={"100px"} src={e.image} /></td>
                                        <td>{e.name}</td>
                                        <td>{e.model}</td>
                                        <td>{e.in_pack}</td>
                                        <td>₴ {formatSum(e.price)}</td>
                                        <td>₴ {formatSum(e.price_one)}</td>
                                        <td>{e.count}</td>
                                        <td>{e.date}</td>
                                        <td>{e.date_update}</td>
                                        <td className={"action_button_td_product"}>
                                            <Link className={"btn btn-info"} to={'/products/' + e.alias}>
                                                <i className="far fa-eye"></i>
                                            </Link>
                                            {props.user && props.user.role_id && Number(props.user.role_id) === 1 ?
                                            <Link className={"btn btn-info"} to={'/products/edit/' + e.alias}>
                                                <i className="far fa-edit"></i>
                                            </Link>
                                                : []}
                                            <button className={"btn btn-info"} onClick={() => props.copyProduct(e.alias, props.languages)}>
                                                <i class="fas fa-copy"></i>
                                            </button>

                                        </td>
                                    </tr>
                                </>
                            })}
                            </tbody>
                        </table>
                    </div>
                    {props.paginationHTML}
                </div>
            </section>
        </div>

    );
}

export default compose(
    withPagination
)(Products);

