import React, {useEffect, useState} from 'react';
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import OrderChangeStatusContainer from "./OrderChangeStatusContainer";
import {compose} from "redux";
import {withPagination} from "../HOC/withPagination/withPagination";
import api from "../../api/api";




const Orders = (props) => {
    const [users, setUsers] = useState([]);
    const renderStatusContainer = (order) => {
        debugger
        return <OrderChangeStatusContainer status_id={order.status} order={order.id} order_data={order}/>;
    }

    useEffect(() => {
        api.getUsers().then(res => {
            setUsers(res.data);
        });
    }, []);

    return(
        <div className="content-wrapper">
            <PageTitle title={'orders'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-primary card-outline card-tabs">
                        <div className="card-header p-0 pt-1 border-bottom-0 main_style_tabs_container">
                            <ul className="nav nav-tabs main_style_tabs" role="tablist">
                                <li className="nav-item" onClick={() => {
                                    props.setActiveTab(1);
                                    props.paginationChangePage(1)
                                }}>
                                    <a className={'nav-link' + (props.activeTab === 1 ? ' active' : '')}>{props.getTranslate('crm_orders','tab_to_payments')}</a>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    props.setActiveTab(2);
                                    props.paginationChangePage(1);
                                }}>
                                    <a className={'nav-link' + (props.activeTab === 2 ? ' active' : '')}>{props.getTranslate('crm_orders','tab_to_preorder')}</a>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    props.setActiveTab(3);
                                    props.paginationChangePage(1);
                                }}>
                                    <a className={'nav-link' + (props.activeTab === 3 ? ' active' : '')}>{props.getTranslate('crm_orders','tab_to_partners_order')}</a>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <div className="tab-content" id="custom-tabs-three-tabContent">
                                <div className={'tab-pane fade' + (props.activeTab === 1 ? ' show active' : '')} >
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <th>{props.getTranslate('crm_main','ID')}</th>
                                                <th>{props.getTranslate('crm_main','sum')}</th>
                                                <th>{props.getTranslate('crm_main','status')}</th>
                                                <th>{props.getTranslate('crm_main','client')}</th>
                                                <th>{props.getTranslate('crm_main','date')}</th>
                                                <th>{props.getTranslate('crm_main','action')}</th>
                                                </thead>
                                                <tbody>
                                                {props.orders && props.orders
                                                    .filter(e => Number(e.type) === 1)
                                                    // .filter((e,i) => {
                                                    //     if(((i + 1) > props.paginationPages.pageStart || ((i + 1) === 1) && props.paginationPages.page === 1) && (i + 1) <= props.paginationPages.pageFinish) {
                                                    //         return true;
                                                    //     } else {
                                                    //         return false;
                                                    //     }
                                                    // })
                                                    .map(e => {
                                                    return <>
                                                        <tr>
                                                            <td>{e.id}</td>
                                                            <td>{e.total.toFixed(2)}</td>
                                                            <td>{renderStatusContainer(e)}</td>
                                                            <td>{e.customer_surname} {e.customer_name} {e.customer_middle_name}</td>
                                                            <td>{e.date}</td>
                                                            <td className={"action_button_td"}>
                                                                <Link className={"btn btn-info"} to={'/orders/' + e.id}>
                                                                    <i className="far fa-eye"></i>
                                                                </Link>
                                                                {props.user && Number(props.user.role_id) !== 3 ?
                                                                <Link className={"btn btn-info"} to={'/orders/edit/' + e.id}>
                                                                    <i className="far fa-edit"></i>
                                                                </Link>
                                                                   : "" }
                                                                {/*<button className={"btn btn-danger"} onClick={() => props.deleteOrder(e.id)}>*/}
                                                                {/*    <i className="far fa-trash-alt"></i>*/}
                                                                {/*</button>*/}
                                                            </td>
                                                        </tr>
                                                    </>
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className={'tab-pane fade' + (props.activeTab === 2 ? ' show active' : '')} >
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <th>{props.getTranslate('crm_main','ID')}</th>
                                                <th>{props.getTranslate('crm_main','sum')}</th>
                                                <th>{props.getTranslate('crm_main','status')}</th>
                                                <th>{props.getTranslate('crm_main','client')}</th>
                                                <th>{props.getTranslate('crm_main','date')}</th>
                                                <th>{props.getTranslate('crm_main','action')}</th>
                                                </thead>
                                                <tbody>
                                                {props.orders && props.orders
                                                    .filter(e => Number(e.type) === 2)
                                                    // .filter((e,i) => {
                                                    //     if(((i + 1) > props.paginationPages.pageStart || ((i + 1) === 1) && props.paginationPages.page === 1) && (i + 1) <= props.paginationPages.pageFinish) {
                                                    //         return true;
                                                    //     } else {
                                                    //         return false;
                                                    //     }
                                                    // })
                                                    .map(e => {
                                                        return <>
                                                            <tr>
                                                                <td>{e.id}</td>
                                                                <td>{e.total}</td>
                                                                <td>{renderStatusContainer(e.status, e.id)}</td>
                                                                <td>{e.customer_surname} {e.customer_name} {e.customer_middle_name}</td>
                                                                <td>{e.date}</td>
                                                                <td className={"action_button_td"}>
                                                                    <Link className={"btn btn-info"} to={'/orders/' + e.id}>
                                                                        <i className="far fa-eye"></i>
                                                                    </Link>
                                                                    <Link className={"btn btn-info"} to={'/orders/edit/' + e.id}>
                                                                        <i className="far fa-edit"></i>
                                                                    </Link>
                                                                    {/*<button className={"btn btn-danger"} onClick={() => props.deleteOrder(e.id)}>*/}
                                                                    {/*    <i className="far fa-trash-alt"></i>*/}
                                                                    {/*</button>*/}
                                                                </td>
                                                            </tr>
                                                        </>
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className={'tab-pane fade' + (props.activeTab === 3 ? ' show active' : '')} >
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            {/*<table className="table table-striped">*/}
                                            {/*    <thead>*/}
                                            {/*    <th>{props.getTranslate('crm_main','ID')}</th>*/}
                                            {/*    <th>{props.getTranslate('crm_main','sum')}</th>*/}
                                            {/*    <th>{props.getTranslate('crm_main','status')}</th>*/}
                                            {/*    <th>{props.getTranslate('crm_main','client')}</th>*/}
                                            {/*    <th>{props.getTranslate('crm_main','date')}</th>*/}
                                            {/*    <th>{props.getTranslate('crm_main','action')}</th>*/}
                                            {/*    </thead>*/}
                                            {/*    <tbody>*/}
                                            {/*    {props.orders && props.orders*/}
                                            {/*        .filter(e => Number(e.type) === 3 || Number(e.type) === 4)*/}
                                            {/*        .map(e => {*/}
                                            {/*        return <>*/}
                                            {/*            <tr>*/}
                                            {/*                <td>{e.id}</td>*/}
                                            {/*                <td>{e.total}</td>*/}
                                            {/*                <td>{renderStatusContainer(e.status, e.id)}</td>*/}
                                            {/*                <td>{e.customer_surname} {e.customer_name} {e.customer_middle_name}</td>*/}
                                            {/*                <td>{e.date}</td>*/}
                                            {/*                <td className={"action_button_td"}>*/}
                                            {/*                    <Link className={"btn btn-info"} to={'/orders/' + e.id}>*/}
                                            {/*                        <i className="far fa-eye"></i>*/}
                                            {/*                    </Link>*/}
                                            {/*                    <button className={"btn btn-danger"} onClick={() => props.deleteOrder(e.id)}>*/}
                                            {/*                        <i className="far fa-trash-alt"></i>*/}
                                            {/*                    </button>*/}
                                            {/*                </td>*/}
                                            {/*            </tr>*/}
                                            {/*        </>*/}
                                            {/*    })}*/}
                                            {/*    </tbody>*/}
                                            {/*</table>*/}
                                            <div className="card card-primary card-outline card-tabs">
                                                <div className="card-header p-0 pt-1 border-bottom-0 main_style_tabs_container">
                                                    <ul className="nav nav-tabs main_style_tabs" role="tablist">
                                                        <li className="nav-item" onClick={() => {
                                                            props.setActiveTabPartnerSale(1);
                                                            props.paginationChangePage(1);
                                                        }}>
                                                            <a className={'nav-link' + (props.activeTabPartnerSale === 1 ? ' active' : '')}>{props.getTranslate('crm_orders','tab_to_partner_order_in_stock')}</a>
                                                        </li>
                                                        <li className="nav-item" onClick={() => {
                                                            props.setActiveTabPartnerSale(2);
                                                            props.paginationChangePage(1)
                                                        }}>
                                                            <a className={'nav-link' + (props.activeTabPartnerSale === 2 ? ' active' : '')}>{props.getTranslate('crm_orders','tab_to_partner_preorder')}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="card-body">
                                                    <div className="tab-content" id="custom-tabs-three-tabContent">
                                                        <div className={'tab-pane fade' + (props.activeTabPartnerSale === 1 ? ' show active' : '')} >
                                                            <div className="card-body p-0">
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                        <th>{props.getTranslate('crm_main','ID')}</th>
                                                                        <th>{props.getTranslate('crm_main','sum')}</th>
                                                                        <th>{props.getTranslate('crm_main','status')}</th>
                                                                        <th>{props.getTranslate('crm_main','client')}</th>
                                                                        <th>{props.getTranslate('crm_main','date')}</th>
                                                                        <th>{props.getTranslate('crm_main','action')}</th>
                                                                        </thead>
                                                                        <tbody>
                                                                        {props.orders && props.orders
                                                                            .filter(e => Number(e.type) === 3)
                                                                            // .filter((e,i) => {
                                                                            //     if(((i + 1) > props.paginationPages.pageStart || ((i + 1) === 1) && props.paginationPages.page === 1) && (i + 1) <= props.paginationPages.pageFinish) {
                                                                            //         return true;
                                                                            //     } else {
                                                                            //         return false;
                                                                            //     }
                                                                            // })
                                                                            .map(e => {
                                                                                return <>
                                                                                    <tr>
                                                                                        <td>{e.id}</td>
                                                                                        <td>{e.total}</td>
                                                                                        <td>{renderStatusContainer(e.status, e.id)}</td>
                                                                                        <td>{e.customer_surname} {e.customer_name} {e.customer_middle_name}</td>
                                                                                        <td>{e.date}</td>
                                                                                        <td className={"action_button_td"}>
                                                                                            <Link className={"btn btn-info"} to={'/orders/' + e.id}>
                                                                                                <i className="far fa-eye"></i>
                                                                                            </Link>
                                                                                            <Link className={"btn btn-info"} to={'/orders/edit/' + e.id}>
                                                                                                <i className="far fa-edit"></i>
                                                                                            </Link>
                                                                                            {/*<button className={"btn btn-danger"} onClick={() => props.deleteOrder(e.id)}>*/}
                                                                                            {/*    <i className="far fa-trash-alt"></i>*/}
                                                                                            {/*</button>*/}
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'tab-pane fade' + (props.activeTabPartnerSale === 2 ? ' show active' : '')} >
                                                            <div className="card-body p-0">
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                        <th>{props.getTranslate('crm_main','ID')}</th>
                                                                        <th>{props.getTranslate('crm_main','sum')}</th>
                                                                        <th>{props.getTranslate('crm_main','status')}</th>
                                                                        <th>{props.getTranslate('crm_main','client')}</th>
                                                                        <th>{props.getTranslate('crm_main','date')}</th>
                                                                        <th>{props.getTranslate('crm_main','action')}</th>
                                                                        </thead>
                                                                        <tbody>
                                                                        {props.orders && props.orders
                                                                            .filter(e => Number(e.type) === 4)
                                                                            // .filter((e,i) => {
                                                                            //     if(((i + 1) > props.paginationPages.pageStart || ((i + 1) === 1) && props.paginationPages.page === 1) && (i + 1) <= props.paginationPages.pageFinish) {
                                                                            //         return true;
                                                                            //     } else {
                                                                            //         return false;
                                                                            //     }
                                                                            // })
                                                                            .map(e => {
                                                                                return <>
                                                                                    <tr>
                                                                                        <td>{e.id}</td>
                                                                                        <td>{e.total}</td>
                                                                                        <td>{renderStatusContainer(e.status, e.id)}</td>
                                                                                        <td>{e.customer_surname} {e.customer_name} {e.customer_middle_name}</td>
                                                                                        <td>{e.date}</td>
                                                                                        <td className={"action_button_td"}>
                                                                                            <Link className={"btn btn-info"} to={'/orders/' + e.id}>
                                                                                                <i className="far fa-eye"></i>
                                                                                            </Link>
                                                                                            <Link className={"btn btn-info"} to={'/orders/edit/' + e.id}>
                                                                                                <i className="far fa-edit"></i>
                                                                                            </Link>
                                                                                            {/*<button className={"btn btn-danger"} onClick={() => props.deleteOrder(e.id)}>*/}
                                                                                            {/*    <i className="far fa-trash-alt"></i>*/}
                                                                                            {/*</button>*/}
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                {/*{props.paginationHTML}*/}
            </section>
        </div>

    );
}

export default compose(
    withPagination
)(Orders);


