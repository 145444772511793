import React, {useState} from 'react';
import {ANALYTICS_FILTERS} from "../../constants";
import {FilterItemInput} from "./FilterItemInput";
import {FilterItemSelect} from "./FilterItemSelect";
import {useSelector, useDispatch} from "react-redux";
import { excelForAnalyticsThunk } from "../../../../store/reducers/MainReducer";

export const Filters = ({ type, onFilter }) => {

    const dispatch = useDispatch()
    const [form, changeForm] = useState({})
    const onChangeHandler = (index, value) => {
        const changesForm = {...form};
        changesForm[index] = value;
        changeForm(changesForm);
    }
    const state = useSelector((state) => state);

    return <>
        {type && ANALYTICS_FILTERS[type] ?
        <div className="card card-primary card-outline card-tabs">
            <div className="card-body">
                <div className="row">
                    {ANALYTICS_FILTERS[type].map((e) => {
                        switch (e.filterType) {
                            case 'input':
                                return <FilterItemInput title={e.title}
                                                        index={e.index}
                                                        type={e.type}
                                                        onChangeHandler={onChangeHandler}
                                                        value={form[e.index]}
                                />
                            case 'select':
                                const selectOptions = e.data
                                    ? e.data
                                    : e.getData(state);
                                return <FilterItemSelect title={e.title}
                                                         index={e.index}
                                                         onChangeHandler={onChangeHandler}
                                                         value={form[e.index]}
                                                         options={selectOptions}
                                />
                        }
                    })}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn btn-success" onClick={() => {
                            onFilter(form);
                        }}>
                            Фільтрувати
                        </button>
                        {type && type === "movement_goods" ?
                            <button className="btn btn-success excel_button_in_analytics"
                                    onClick={() => dispatch(excelForAnalyticsThunk(form))}
                            >
                                Excel
                            </button>
                            : ""}
                    </div>
                </div>
            </div>
        </div> : ""}
    </>;
};