import React from 'react';
import PageTitle from "../../PageTitle/PageTitle";
import {Link} from "react-router-dom";

const Warehouses = (props) => {

    return(
        <div className="content-wrapper">
            <PageTitle title={'warehouses_reserved'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Пошук</label>
                                <input type="text"
                                       className={'form-control'}
                                       value={props.searchInput}
                                       onChange={(event) => props.searchInputChange(event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <table className="table table-striped">
                            <thead>
                            <th>{props.getTranslate('crm_main','ID')}</th>
                            <th>{props.getTranslate('crm_main','products')}</th>
                            <th>{props.getTranslate('crm_main','orders')}</th>
                            <th>{props.getTranslate('crm_main','count')}</th>
                            <th>{props.getTranslate('crm_main','price')}</th>
                            <th>{props.getTranslate('crm_main','date')}</th>
                            </thead>
                            <tbody>
                            {props.warehouses_reserved && props.warehouses_reserved.map(e => {
                                return <>
                                    <tr>
                                        <td>{e.id}</td>
                                        <td><a href={"/products/"+e.product_alias} target={"_blank"}>{e.product_name} ({e.product_model})</a></td>
                                        <td><a href={"/orders/"+e.id_order} target={"_blank"}>№ {e.id_order}</a></td>
                                        <td>{e.count}</td>
                                        <td>{e.price}</td>
                                        <td>{e.date}</td>
                                    </tr>
                                </>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Warehouses;