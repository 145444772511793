import React from 'react';
import { Pagination } from "antd";

export const PaginationContainer = ({ total, onChange, page }) => {
    return(
        <Pagination defaultCurrent={1}
                    total={total}
                    current={page}
                    onChange={onChange}
        />
    );
}