import React from 'react';
import Categories from "./Categories";
import {connect} from "react-redux";
import './Categories.css';
import {
    deleteCategoryThunk,
    getCategoriesThunk,
    toggleCategoryActiveDataAC
} from "../../store/reducers/MainReducer";
import {withLang} from "../HOC/withLang/withLang";
import {compose} from "redux";



class CategoriesContainer extends React.Component {

    constructor(props){
        super(props);

    }


    componentDidMount() {
        this.props.getCategoriesThunk()
    }

    render() {
        return <Categories categories={this.props.categories}
                           getTranslate={this.props.getTranslate}
                           categoryOpen={this.props.categoryOpen}
                           toggleActive={this.props.toggleActive}
                           deleteCategory={this.props.deleteCategory}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        toggleActive: (id) =>  dispatch(toggleCategoryActiveDataAC(id)),
        getCategoriesThunk: () => dispatch(getCategoriesThunk()),
        deleteCategory: (id) => dispatch(deleteCategoryThunk(id))
    }
}

let mapStateToProps = (state) => {
    return {
       categoryOpen: state.main.categoryOpen,
       categories: state.main.categories
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(CategoriesContainer);