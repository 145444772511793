import React from 'react';
import {connect} from "react-redux";
import './WarehousesForm.css';
import {withLang} from "../../HOC/withLang/withLang";
import {compose} from "redux";
import WarehousesForm from "./WarehousesForm";
import {
    changeWarehousesFormValueAC,
    createWarehousesThunk,
    editWarehousesThunk,
    getDirectoryDataThunk,
    getWarehouseEditDataThunk
} from "../../../store/reducers/MainReducer";



class WarehousesFormContainer extends React.Component {

    constructor(props){
        super(props);

    }


    componentDidMount() {
        this.props.directory();
        if(this.props.isEdit) {
            this.props.getEdit(this.props.uri.match.params.id);
        }
    }

    render() {
        return <WarehousesForm getTranslate={this.props.getTranslate}
                                 changeValue={this.props.changeValue}
                                 createWarehouses={this.props.createWarehouses}
                                 form={this.props.form}
                                 directoryData={this.props.directoryData}
                                 isEdit={this.props.isEdit}
                               editWarehouse={this.props.editWarehouse}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeWarehousesFormValueAC(input, value)),
        createWarehouses: (warehouses) => dispatch(createWarehousesThunk(warehouses)),
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
        getEdit: (id) => dispatch(getWarehouseEditDataThunk(id)),
        editWarehouse: (warehouses) => dispatch(editWarehousesThunk(warehouses)),
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.main.WarehousesForm,
        directoryData: state.main.directoryData
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(WarehousesFormContainer);
