import React from 'react';
import PageTitle from "../../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './CategoryForm.css';
import Select from "react-select";

const CategoryForm = (props) => {

    const getCategoriesOptions = () => {
        let options = props.categories && props.categories
            .filter(e => Number(e.id) !== Number(props.categoryId))
            .map(e => {
            return {
                value: e.id,
                label: e.name
            }
        });

        options.push({
            value: 0,
            label: '--- не обрано ---'
        });

        return options;

    }

    const getCategoryLabel = (id) => {
        let options = props.categories && props.categories.map(e => {
            return {
                value: e.id,
                label: e.name
            }
        });
        let category = options.filter(e => Number(id) === Number(e.value))[0];

        return category ? category.label : ''
    }

    return (
        <div className="content-wrapper">
            <PageTitle title={'category_form'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header"><h3 className="card-title">{props.getTranslate('crm_form_add','form_add_main_info_title')}</h3>
                        </div>
                        <form onSubmit={(event) => {
                            event.preventDefault();
                            if(props.isEdit) {
                                props.editCategory({...props.form});
                            } else {
                                props.createCategory({...props.form});
                            }

                        }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            {props.languages && props.languages.map(e => {
                                                return <div className="input-group mb-3">
                                                    <label>{e.code}:</label>
                                                    <input className={'form-control'}
                                                           value={props.form && props.form.name && props.form.name.filter(name => Number(name.lang) === Number(e.id))[0] && props.form.name.filter(name => Number(name.lang) === Number(e.id))[0].name}
                                                           onChange={(event) => {
                                                               let form_name = [...props.form.name];
                                                               form_name = form_name.map(f => {
                                                                   if(Number(e.id) === Number(f.lang)) {
                                                                       f.name = event.target.value;
                                                                   }
                                                                   return f;
                                                               })
                                                               props.changeValue('name', form_name);
                                                           }}
                                                           required
                                                           placeholder={props.getTranslate('crm_form_add', 'form_add_title')}
                                                    />
                                                </div>
                                            })}
                                            {/*<div className="input-group mb-3">*/}
                                            {/*    <input className={'form-control'}*/}
                                            {/*           value={props.form && props.form.name}*/}
                                            {/*           onChange={(event) => props.changeValue('name', event.target.value)}*/}
                                            {/*           required*/}
                                            {/*           placeholder={props.getTranslate('crm_form_add','form_add_title')}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                            <div className="form-group">
                                                <div className="input-group mb-3">
                                                    <label>Батьківська:</label>
                                                    {/*<select className={'form-control'}*/}
                                                    {/*        value={props.form && props.form.parent}*/}
                                                    {/*        defaultValue={props.form && props.form.parent}*/}
                                                    {/*        onChange={(event) => props.changeValue('parent', event.target.value)}*/}
                                                    {/*        required>*/}
                                                    {/*    <option value="0">--- не обрано ---</option>*/}

                                                    {/*</select>*/}
                                                    <Select value={props.form && props.form.parent ? {label: getCategoryLabel(props.form.parent), value: props.form.parent} : ''}
                                                            onChange={(selectedOption) => props.changeValue('parent', selectedOption.value)}
                                                            placeholder={''}
                                                            className={'form-select-container select-plugin-container'}
                                                            required={'required'}
                                                            options={getCategoriesOptions()}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button className={"btn btn-success"} type={'submit'}>{props.getTranslate('crm_form_add','form_add_button_create')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default CategoryForm;