import React from 'react';
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import './Tags.css';
import {withPagination} from "../HOC/withPagination/withPagination";
import {compose} from "redux";

const Tags = (props) => {

    return(
        <div className="content-wrapper">
            <PageTitle title={'tags'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Пошук</label>
                                <input type="text"
                                       className={'form-control'}
                                       value={props.searchInput}
                                       onChange={(event) => props.searchInputChange(event.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 container_button">
                        <Link className={"btn btn-success"} to={"/tags/add"}><i className="fas fa-plus-circle"></i></Link>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card">
                        <table className="table table-striped">
                            <thead>
                            <th>{props.getTranslate('crm_main','ID')}</th>
                            <th>{props.getTranslate('crm_main','name')}</th>
                            <th>{props.getTranslate('crm_main','alias')}</th>
                            <th>{props.getTranslate('crm_main','date')}</th>
                            <th>{props.getTranslate('crm_main','action')}</th>
                            </thead>
                            <tbody>
                            {props.tags && props.tags
                                .filter((e,i) => {
                                    if(((i + 1) > props.paginationPages.pageStart || ((i + 1) === 1) && props.paginationPages.page === 1) && (i + 1) <= props.paginationPages.pageFinish) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                })
                                .map(e => {
                                return <>
                                    <tr>
                                        <td>{e.id}</td>
                                        <td>{e.name}</td>
                                        <td>{e.alias}</td>
                                        <td>{e.date}</td>
                                        <td className={"action_button_td"}>
                                            <Link className={'btn btn-info'} to={'/tags/edit/' + e.alias}>
                                                <i className="fas fa-pen"></i>
                                            </Link>
                                            <button className={'btn btn-danger'} onClick={() => props.deleteTag(e.id)}>
                                                <i className="fas fa-minus-circle"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {props.paginationHTML}
            </section>
        </div>

    );
}

export default compose(
    withPagination
)(Tags);